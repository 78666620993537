import { evaluateRelevance } from './utils/evaluateRelevance';
import { getRelevantContextObjects, updateOrCreateContextObject } from '../../services/contextService';
import { tokenize } from './utils/tokenize';

const MAX_TOKENS = 1024; // Change this value based on the model's token limit

class ContextFormatter {

  async format(userId, latestStudentResponse, lessonTitle) {
    const contextObjects = await getRelevantContextObjects(userId, lessonTitle, latestStudentResponse);
    //console.log("ContextFormatter.format(): ", contextObjects);
  
    if (!contextObjects || contextObjects.length === 0) {
      //console.log("No context objects for user: ", userId);
      const interactions = [{
        "role": "student",
        "content": latestStudentResponse
      }];
      
      await updateOrCreateContextObject(userId, interactions, lessonTitle);
      return [];
    }
  
    contextObjects.sort((a, b) => a.metadata.lastInteractionTimestamp - b.metadata.lastInteractionTimestamp );
  
    let messages = [];
    let tokens = 0;
  
    for (const contextObject of contextObjects) {
      const original_text = contextObject.original_text;
    
      if (!original_text) {
          continue;
      }
      const formattedText = original_text.replace(/(?!^)<(S|T)>/g, '\n<$1>');
      const lines = formattedText.split('\n').filter(line => line.trim() !== '');
    
      let newMessages = [];
      let currentMessage = { role: '', content: '' };
    
      lines.forEach(line => {
        const roleMarkerMatch = line.match(/^\s*<(S|T)>:\s*/);
    
        if (roleMarkerMatch) {
          if (currentMessage.content) {
            newMessages.push(currentMessage);
          }
    
          const roleMarker = roleMarkerMatch[0];
          const content = line.replace(roleMarker, '').trim();
          const role = roleMarker.startsWith('<S>') ? 'user' : 'assistant';
    
          currentMessage = { role, content };
        } else {
          currentMessage.content += '\n' + line.trim();
        }
      });
    
      if (currentMessage.content) {
        newMessages.push(currentMessage);
      }
    
      const newTokens = newMessages.reduce((acc, message) => acc + tokenize(message.content).length, 0);
    
      if (tokens + newTokens <= MAX_TOKENS) {
        messages.push(...newMessages);
        tokens += newTokens;
      } else {
        break;
      }
    }
  
    //console.log("ContextFormatter messages ", messages);
    return messages;
  }
}

export default ContextFormatter;