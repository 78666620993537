import axios from 'axios';

// Replace the following line with the URL of your backend server
const BACKEND_SERVER_URL = process.env.REACT_APP_BACKEND_SERVER_URL || 'https://api1.edu-ai.io';
const FLASK_SERVER_URL = process.env.REACT_APP_FLASK_SERVER_URL || 'http://localhost:5001';

/*async function storeContextObjects(userId, contextObjects) {
    try {
        const response = await axios.post(`${BACKEND_SERVER_URL}/api/store-context-objects`, { userId, contextObjects });
        //console.log(response.data.message);
    } catch (error) {
        console.error('Error storing context objects:', error);
    }
}*/

async function getRelevantContextObjects(userId, lessonTitle, queryText) {
    try {
        const response = await axios.post(`${FLASK_SERVER_URL}/api/get-relevant-context-objects`, { userId, lessonTitle, queryText });
        return response.data.contextObjects;
    } catch (error) {
        console.error('Error fetching relevant context objects:', error);
        return [];
    }
}

//We collect the student, teacher interaction and pass it
async function updateOrCreateContextObject(userId, interaction, topic) {
    try {
      //console.log('Sending data to server.js:', { userId, interaction, topic });
      const response = await axios.post(`${FLASK_SERVER_URL}/api/update-or-create-context-object`, { userId, interaction, topic });
      //console.log("updateOrCreateContextObject: " + response.data.message);
    } catch (error) {
      console.error('Error updating or creating context object:', error);
    }
}
  
export {
    //storeContextObjects,
    getRelevantContextObjects,
    updateOrCreateContextObject,
};

