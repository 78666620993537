import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button, FormControlLabel, Radio, RadioGroup, MenuItem, FormControl, InputLabel, Select, FormHelperText, ListItemText, Grid } from '@mui/material';
import { useState } from 'react';
import { addUserCourse } from '../../../services/courseService';
import { authInstance } from '../../../services/firebase';
import { createAndSaveGeneratedCourse } from '../../../services/lessonService';
import styles from './CourseCreationModal.module.css';

function getTeachingMethodDescription(teachingStyle) {
  let description;
  switch (teachingStyle) {
    case 'socratic':
      description = "Always respond in the Socratic style. You *never* give the student the answer, but always try to ask just the right question to help them learn to think for themselves.";
      break;
    case 'story-telling':
      description = "Please use the Story-telling method. This involves narrating educational stories and anecdotes to illustrate concepts and make learning more engaging and memorable.";
      break;
    case 'visual':
      description = "Please use the Visual teaching method. This involves using images, diagrams, charts, and other visual aids to help students understand concepts better.";
      break;
    default:
      description = "Socratic";
  }
  return description;
}

const CourseCreationModal = ({ open, onClose, onCreate, setCreationProgress, courseList}) => {
  const [courseName, setCourseName] = useState('');
  const [depthLevel, setDepthLevel] = useState('Undergraduate');
  const [courseDescription, setCourseDescription] = useState('');
  const [courseDuration, setCourseDuration] = useState('');
  const [teachingStyle, setTeachingStyle] = useState('default');
  const [courseLength, setCourseLength] = useState('A');
  const [error, setError] = useState('');
  const userId = authInstance.currentUser.uid;

  const depthLevels = [
    '1st Grade',
    '2nd Grade',
    '3rd Grade',
    '4th Grade',
    '5th Grade',
    '6th Grade',
    '7th Grade',
    '8th Grade',
    '9th Grade',
    '10th Grade',
    '11th Grade',
    '12th Grade',
    'Undergraduate',
    'Graduate',
    'Post-graduate'
  ];

  const handleCourseCreation = () => {
    //console.log('Course name: ', courseName);
    //console.log('Course description: ', courseDescription);
    //console.log('Course length: ', courseLength);
    //console.log('Course duration: ', courseDuration);
    //console.log('Course list: ', courseList);

     // Clear error message at the start of each submission
     setError('');

     // Check if the course name is "Assignments" or "assignments"
     if (courseName.toLowerCase() === 'assignments' 
            || courseName.toLowerCase() === 'new course' 
            || courseName.toLowerCase() === 'quizzes'
            || courseName.toLowerCase() === 'exams'
            || courseList.some(obj => obj.name === courseName)) {
       setError('Course name is too vague or a duplicate.');
       return; // Prevent from continuing with course creation
     }

    const newCourse = {
        name: courseName,
        depthLevel: "Please teach the content at a " + depthLevel + " level",
        speakingStyle: "Please speak as if you were teaching a " + depthLevel + " student.",
        description: courseDescription,
        duration: courseDuration,
        teachingStyle: getTeachingMethodDescription(teachingStyle),
        creating: true, // add this flag to show loading state
      };
    
      // pass new course up to parent component
    onCreate(newCourse);

    createAndSaveGeneratedCourse(userId, courseName, courseLength, setCreationProgress).then(() => {
        // Once the course has been saved, update the creating status to false
        newCourse.creating = false;
        onCreate(newCourse);
        addUserCourse(userId, courseName, newCourse.depthLevel, newCourse.teachingStyle, newCourse.speakingStyle);
    });

    // Clear input fields
    setCourseName('');
    setCourseDescription('');
    setCourseDuration('');

    // Close modal
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Course</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Course Name"
          type="text"
          fullWidth
          variant="standard"
          value={courseName}
          onChange={(e) => setCourseName(e.target.value)}
        />
        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <label id="depth-level-label" className={styles.inputLabel}>Depth Level</label>
          </Grid>
          <Grid item>
            <Select
              labelId="depth-level-label"
              id="depth-level"
              value={depthLevel}
              onChange={(e) => setDepthLevel(e.target.value)}
              className={styles.select}
            >
              {depthLevels.map((level) => (
                <MenuItem value={level} key={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
         <RadioGroup
          row
          aria-label="course-length"
          name="course-length"
          value={courseLength}
          onChange={(e) => setCourseLength(e.target.value)}
        >
          <FormControlLabel value="A" control={<Radio color="primary" />} label="Quick Study" />
          <FormControlLabel value="B" control={<Radio color="primary" />} label="Summer Course" />
          <FormControlLabel value="C" control={<Radio color="primary" />} label="Semester Course" />
        </RadioGroup>
        <FormControl fullWidth>
          <FormHelperText id="teaching-style-label">Teaching Style</FormHelperText>
          <Select
            labelId="teaching-style-label"
            id="teaching-style"
            value={teachingStyle}
            onChange={(e) => setTeachingStyle(e.target.value)}
          >
            <MenuItem value={'default'}>
              <ListItemText 
                primary="Default" 
                secondary="Our standard teacher." 
              />
            </MenuItem>
            <MenuItem value={'socratic'}>
              <ListItemText 
                primary="Socratic" 
                secondary="Encourages critical thinking via Q&A." 
              />
            </MenuItem>
            <MenuItem value={'story-telling'}>
              <ListItemText 
                primary="Story-telling" 
                secondary="Teaches by telling engaging stories." 
              />
            </MenuItem>
            <MenuItem value={'visual'}>
              <ListItemText 
                primary="Visual" 
                secondary="Relies on images, charts, and diagrams." 
              />
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          id="description"
          label="Course Description (Coming Soon)"
          type="text"
          fullWidth
          variant="standard"
          value={courseDescription}
          onChange={(e) => setCourseDescription(e.target.value)}
          disabled={true}
        />
        <TextField
          margin="dense"
          id="duration"
          label="Specific things to cover (Coming Soon)"
          type="text"
          fullWidth
          variant="standard"
          value={courseDuration}
          onChange={(e) => setCourseDuration(e.target.value)}
          disabled={true}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleCourseCreation}>Create</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CourseCreationModal;