import React, { useRef, useState, useEffect, useMemo } from 'react';
import { InlineMath, BlockMath } from 'react-katex';
import Plotly from 'plotly.js-dist';
import 'katex/dist/katex.min.css';
import ImageContent from './ImageContent';
import InLessonModalContent from './InLessonModalContent';
import { searchImage, fetchImageFromFirebaseStorage } from '../../services/imageSearch';
import { firestoreInstance } from '../../services/firebase';
import { collection, onSnapshot } from 'firebase/firestore';
import { fetchGradeableOfType } from '../../services/gradingService';
import { fetchImagesBySubtopic, fetchImagesByLesson } from '../../services/lessonService';
import styles from './LessonInformation.module.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Circles } from 'react-loader-spinner';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import 'katex/dist/katex.min.css'


function LessonInformation({ lesson, content, userId, subject, lessonId, lessonTopic, showToast, setShowToast, selectedCheckpoint }) {
  const [temporaryMessage, setTemporaryMessage] = useState(null);
  const [imageUrls, setImageUrls] = useState(() => {
    const storedImageUrls = localStorage.getItem("imageUrls");
    //console.log("Stored imageUrls", storedImageUrls);
    return storedImageUrls ? JSON.parse(storedImageUrls) : {};
  });
  const [diagramUrls, setDiagramUrls] = useState(() => {
    const storedDiagramUrls = localStorage.getItem("diagramUrls");
    //console.log("Stored diagramUrls", storedDiagramUrls);
    return storedDiagramUrls ? JSON.parse(storedDiagramUrls) : {};
  });
  const [quizzes, setQuizzes] = useState({});

  const [boxPlotsData, setBoxPlotsData] = useState({});
  const [scatterPlotsData, setScatterPlotsData] = useState({});
  const [linePlotsData, setLinePlotsData] = useState({});
  // State variables for modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImageUrl, setModalImageUrl] = useState(null);
  const endOfChatRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const modalRefs = useRef({}); // This will hold refs for each modal by name

  // Handle image click
  const handleImageClick = (imageUrl) => {
    setModalImageUrl(imageUrl);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleTemporaryMessage = (message) => {
    setTemporaryMessage(message);
  };

  const getRandomColor = () => {
    const getDarkChannel = () => {
      const min = 0;
      const max = 175; // Limit the maximum value for a color channel to 128 (half of the maximum value)
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    const r = getDarkChannel();
    const g = getDarkChannel();
    const b = getDarkChannel();

    return `rgb(${r}, ${g}, ${b})`;
  };

  /*const fetchImageUrls = async (content, userId, subject, lessonId, lessonTopic) => {
    const imgRegex = /\[Image:?(\s*\d+-\d+)]/gi;
    const imageIdentifiers = [...content.matchAll(imgRegex)].map(match => match[1]) || [];
  
    // Fetch the images from Firestore
    const imagesBySubtopic = await fetchImagesByLesson(userId, subject, lessonId, lessonTopic);
    //console.log("fetchImageUrls() ", imagesBySubtopic);
    const newImageUrls = {};
    for (const imageIdentifier of imageIdentifiers) {
      const [subtopicIndex, imageIndex] = imageIdentifier.split('-').map(Number);
  
      if (!newImageUrls[imageIdentifier]) {
        const imageObj = imagesBySubtopic[subtopicIndex] && imagesBySubtopic[subtopicIndex][imageIndex];
        const imageUrl = imageObj && imageObj.imageUrl;
        if (imageUrl) {
          newImageUrls[imageIdentifier.trim()] = imageUrl;
        }
      }
    }

    setImageUrls((prevImageUrls) => {
      const updatedImageUrls = { ...prevImageUrls, ...newImageUrls };
      localStorage.setItem("imageUrls" + lessonId, JSON.stringify(updatedImageUrls));
      //console.log("UPDATEFC ", updatedImageUrls);
      return updatedImageUrls;
    });
  };  */

  const fetchImageUrls = async (content) => {
    const imgRegex = /\[Image:\s*([^\]]+)\]/g;
    const descriptions = [...content.matchAll(imgRegex)].map(match => match[1]) || [];
    const newImageUrls = {};

    for (const description of descriptions) {
      if (!imageUrls[description]) {
        let imageUrl;

        // Check if the description is a URL
        if (/^(http|https):\/\/[^ "]+$/.test(description)) {
          imageUrl = description;
        } else if (description.startsWith('DOCID-')) {
          // Fetch the image from Firebase Storage
          const docId = description.substring(6);
          imageUrl = await fetchImageFromFirebaseStorage(docId);
        } else {
          // Search for the image
          imageUrl = await searchImage(description);
        }

        if (imageUrl === null) {
          imageUrl = 'fetchFailed'; // or some placeholder value
        }
        newImageUrls[description] = imageUrl;
      }
    }

    setImageUrls((prevImageUrls) => {
      const updatedImageUrls = { ...prevImageUrls, ...newImageUrls };
      localStorage.setItem("imageUrls", JSON.stringify(updatedImageUrls));
      return updatedImageUrls;
    });
  };

  const fetchDiagrams = async (content) => {
    const diagramRegex = /\[Diagram:\s*([^\]]+)\]/g;
    const descriptions = [...content.matchAll(diagramRegex)].map(match => match[1]) || [];
    const newDiagramUrls = {};

    for (const description of descriptions) {
      if (!diagramUrls[description]) {
        let diagramUrl;

        // Check if the description is a URL
        if (/^(http|https):\/\/[^ "]+$/.test(description)) {
          diagramUrl = description;
        } else if (description.startsWith('DOCID-')) {
          // Fetch the image from Firebase Storage
          const docId = description.substring(6);
          diagramUrl = await fetchImageFromFirebaseStorage(docId);
        }

        if (diagramUrl === null) {
          diagramUrl = 'fetchFailed'; // or some placeholder value
        }
        newDiagramUrls[description] = diagramUrl;
      }
    }

    setDiagramUrls((prevDiagramUrls) => {
      const updatedDiagramUrls = { ...prevDiagramUrls, ...newDiagramUrls };
      localStorage.setItem("diagramUrls", JSON.stringify(updatedDiagramUrls));
      return updatedDiagramUrls;
    });
  };



  const fetchQuizzes = () => {
    const quizzesRef = collection(firestoreInstance, `users/${userId}/Quizzes`);

    // Listen for real-time changes
    const unsubscribe = onSnapshot(quizzesRef, (snapshot) => {
      //console.log("QUIZ INFO CHANGED CALLED: ");
      const updatedQuizzes = {};
      snapshot.forEach((doc) => {
        const quizData = doc.data();
        if (!updatedQuizzes[quizData.lessonID]) {
          updatedQuizzes[quizData.lessonID] = [];
        }
        updatedQuizzes[quizData.lessonID].push(quizData);
      });
      setQuizzes(prevQuizzes => ({ ...prevQuizzes, ...updatedQuizzes }));
    });

    // Return the unsubscribe function
    return unsubscribe;
  };

  useEffect(() => {
    //console.log('quizzes state changed:', quizzes);
  }, [quizzes]);


  useEffect(() => {
    if (showToast) {
      toast.info(
        <>
          <span>{showToast}</span>
          <Circles type="TailSpin" color="#333" height={30} width={30} />
        </>,
        {
          position: "top-right",
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
        }
      );
      setShowToast(null);
    }
  }, [showToast, setShowToast]);


  useEffect(() => {
    if (content) {
      fetchImageUrls(content);
      fetchDiagrams(content);
      //fetchImageUrls(content, userId, subject, lessonId, lessonTopic);

      // Store the unsubscribe function and call it when the component unmounts
      const unsubscribeFromQuizzes = fetchQuizzes();

      const scrollContainer = scrollContainerRef.current;
      const endOfChat = endOfChatRef.current;

      if (scrollContainer && endOfChat) {
        const scrollHeight = endOfChat.offsetTop - scrollContainer.offsetTop;
        scrollContainer.scrollTop = scrollHeight;
      }

      // Clean up the listener when the component unmounts
      return () => {
        if (unsubscribeFromQuizzes) {
          unsubscribeFromQuizzes();
        }
      };
    }
  }, [content]);


  useEffect(() => {
    if (selectedCheckpoint && modalRefs.current[selectedCheckpoint.name] && modalRefs.current[selectedCheckpoint.name].current) {
      //console.log("Selected checkpoint: ", selectedCheckpoint.name);
      modalRefs.current[selectedCheckpoint.name].current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [selectedCheckpoint]);


  /*useEffect(() => {
    Object.entries(boxPlotsData).forEach(([containerId, data]) => {
      const processedData = data.split(',').map(Number);
  
      const trace1 = {
        x: processedData, // Changed from 'y'
        type: 'box',
        name: 'Sample Data',
        marker: {
          color: getRandomColor(),
        },
        //boxmean: 'sd',
      };
  
      const plotData = [trace1];
  
      const layout = {
        title: 'Box Plot',
        xaxis: {
          zeroline: false,
        },
      };
  
      Plotly.newPlot(containerId, plotData, layout);
    });
  }, [boxPlotsData]);
  

  useEffect(() => {
    Object.entries(scatterPlotsData).forEach(([containerId, data]) => {
      const processedData = data.split(';').map((point) => point.split(',').map(Number));
      const trace = {
        x: processedData.map((point) => point[0]),
        y: processedData.map((point) => point[1]),
        mode: 'markers',
        type: 'scatter',
        marker: {
          color: getRandomColor(),
        },
      };

      const layout = {
        title: 'Scatter Plot',
        xaxis: {
          title: 'X-axis',
        },
        yaxis: {
          title: 'Y-axis',
        },
      };

      Plotly.newPlot(containerId, [trace], layout);
    });
  }, [scatterPlotsData]);

  useEffect(() => {
    Object.entries(linePlotsData).forEach(([containerId, data]) => {
      const processedData = data.split(';').map((point) => point.split(',').map(Number));
      const trace = {
        x: processedData.map((point) => point[0]),
        y: processedData.map((point) => point[1]),
        mode: 'lines+markers',
        type: 'scatter',
        marker: {
          color: getRandomColor(),
        },
      };

      const layout = {
        title: 'Line Plot',
        xaxis: {
          title: 'X-axis',
        },
        yaxis: {
          title: 'Y-axis',
        },
      };

      Plotly.newPlot(containerId, [trace], layout);
    });
  }, [linePlotsData]);

  const renderEquation = (equation) => {
    if (equation.startsWith('@@') && equation.endsWith('@@')) {
      const blockEquation = equation.slice(2, -2);
      return <BlockMath>{blockEquation}</BlockMath>;
    } else if (equation.startsWith('@') && equation.endsWith('@')) {
      const inlineEquation = equation.slice(1, -1);
      return <InlineMath>{inlineEquation}</InlineMath>;
    } else {
      return <InlineMath>{equation}</InlineMath>;
    }
  };*/

  const preprocessContent = (content) => {
    const mcqRegex = /(\[MCQ\][\s\S]*?\[\/MCQ\])/g;
    const frqRegex = /(\[FRQ\][\s\S]*?\[\/FRQ\])/g;
    const mcqSections = [];
    const frqSections = [];
    let match;

    while ((match = mcqRegex.exec(content)) !== null) {
      mcqSections.push(match[1].trim());
    }

    while ((match = frqRegex.exec(content)) !== null) {
      frqSections.push(match[1].trim());
    }

    const contentWithPlaceholders = content
      .replace(mcqRegex, '\nMCQ_PLACEHOLDER')
      .replace(frqRegex, '\nFRQ_PLACEHOLDER');
    return { contentWithPlaceholders, mcqSections, frqSections };
  };

  const renderContent = (content) => {
    if (!content) {
      return null;
    }
    const blockMathRegex = /\$\$((?:[^\$]|\$(?!\$))*?)\$\$/g;

    function processLatex(match) {
      const lines = match.split('\n');
      let formattedExpression = '';

      for (const line of lines) {
        const trimmedLine = line.trim();
        if (trimmedLine.length > 0) {
          formattedExpression += trimmedLine;
        }
      }

      return formattedExpression;
    }

    const formattedString = content.replace(blockMathRegex, processLatex);

    const contentWithTemporaryMessage = temporaryMessage
      ? formattedString + '\n' + temporaryMessage
      : formattedString;

    const { contentWithPlaceholders, mcqSections, frqSections } = preprocessContent(contentWithTemporaryMessage);

    function groupCodeBlocks(lines) {
      const groupedLines = [];
      let insideCodeBlock = false;
      let codeBlockLines = [];

      for (const line of lines) {
        if (line.startsWith('```')) {
          insideCodeBlock = !insideCodeBlock;

          if (!insideCodeBlock) {
            groupedLines.push({ type: 'code', lines: codeBlockLines });
            codeBlockLines = [];
          }
        } else if (insideCodeBlock) {
          codeBlockLines.push(line);
        } else {
          groupedLines.push({ type: 'normal', line: line });
        }
      }

      return groupedLines;
    }

    const groupedLines = groupCodeBlocks(contentWithPlaceholders.split('\n'));

    return groupedLines.map((item, index) => {
      const equationRegex = /(\@{1,2}[^@]+\@{1,2})/g;

      if (item.type === 'code') {
        return (
          <React.Fragment key={index}>
            <div className={styles['code-block-container']}>
              <ReactMarkdown
                className={styles['code-block']}
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeKatex]}
              >
                {'```\n' + item.lines.join('\n') + '\n```'}
              </ReactMarkdown>
            </div>
          </React.Fragment>
        );
      } else {
        const line = item.line;

        if (line.startsWith('<S>:')) {
          let userMessage = line.replace(/^<S>:\s*/, '').trim();

          // Check if the message starts with the P.S. statement
          if (userMessage.startsWith("(P.S")) {
            userMessage = ""; // Or set it to whatever you want in this case
          } else {
            userMessage = userMessage.split(" (P.S")[0];
          }

          return (
            <React.Fragment key={index}>
              <div className={styles['message-container']}>
                <span className={styles['user-message']}>{userMessage}</span>
              </div>
            </React.Fragment>
          );
        }
        else {
          const assMessage = line.replace(/^<T>:\s*/, '');

          const regex = /\[Image:\s*(?<image>[^\]]+)\]|\[Modal:\s*\[(?<type>[^\]]+)\]\s*(?<info1>[^\|]+)\|\s*(?<info2>[^\]]+)\]|\[Diagram:\s*(?<diagramDesc>[^\]]+)\]/gs;

          const messageParts = [];
          let match;
          while ((match = regex.exec(assMessage)) !== null) {
            const { image, type, info1, info2, diagramDesc, diagramMeta } = match.groups;
            messageParts.push({ image, type, info1, info2, diagramDesc, diagramMeta });
          }

          return (
            <React.Fragment key={index}>
              {messageParts.map((part, i) => {
                if (part.image) {
                  const imageUrl = imageUrls[part.image.trim()];
                  return (
                    <ImageContent
                      key={i}
                      imageUrl={imageUrl}
                      onImageClick={handleImageClick}
                    />
                  );
                } else if (part.type) {
                  if (part.type === 'QUIZ') {
                    let quizObjects = quizzes[lessonId];
                    // Ensure quizObjects is an array before using .find()
                    if (Array.isArray(quizObjects)) {
                      let filteredQuiz = quizObjects.find(quiz => quiz.title.trim() === part.info1.trim());
                      ////console.log("QUIZ: ", quizObjects);
                      ////console.log("FILTERED QUIZ: ", filteredQuiz);
                      ////console.log("Part1 ", part.info1);
                      if (filteredQuiz) {
                        return (
                          <InLessonModalContent
                            key={i}
                            type={part.type}
                            info1={part.info1}
                            info2={part.info2}
                            questions={filteredQuiz.questions}
                            completed={filteredQuiz.completed}
                            quiz={filteredQuiz}
                            userID={userId}
                          />
                        );
                      } else {
                        //console.error("No quiz found with the title: ", part.info1);
                      }
                    }
                  }
                  else {
                    if (!modalRefs.current[part.type]) {
                      modalRefs.current[part.type] = React.createRef(); // Create a ref for this modal
                    }
                    return (
                      <InLessonModalContent
                        key={i}
                        type={part.type}
                        info1={part.info1}
                        info2={part.info2}
                        ref={modalRefs.current[part.type]}
                      />
                    );
                  }
                }
                else if (part.diagramDesc) {
                  const diagramUrl = diagramUrls[part.diagramDesc.trim()];
                  return (
                    <ImageContent
                      key={i}
                      imageUrl={diagramUrl}
                      onImageClick={handleImageClick}
                    />
                  );
                }
              })}
              <ReactMarkdown
                remarkPlugins={[remarkMath]}
                rehypePlugins={[rehypeKatex]}
              >
                {assMessage.replace(regex, '')}
              </ReactMarkdown>
            </React.Fragment>
          );
        }
      }
      /*else if (line.trim().includes('MCQ_PLACEHOLDER')) {
        const mcqContent = mcqSections[mcqIndex];
        mcqIndex++;
  
        if (mcqContent) {
          const cleanedMcqContent = mcqContent.replace(/\[\/MCQ\]/g, '');
          return (
            <span key={index} className={styles['mcq-container']}>
              {cleanedMcqContent.split(equationRegex).map((part, i) => {
                if (part.startsWith('@')) {
                  return <React.Fragment key={i}>{renderEquation(part)}</React.Fragment>;
                } else {
                  return <React.Fragment key={i}>{part}</React.Fragment>;
                }
              })}
            </span>
          );
        }
      } else if (line.trim().includes('FRQ_PLACEHOLDER')) {
        const frqContent = frqSections[frqIndex];
        frqIndex++;
  
        if (frqContent) {
          const cleanedFrqContent = frqContent.replace(/\[\/FRQ\]/g, '');
          return (
            <span key={index} className={styles['mcq-container']}>
              {cleanedFrqContent.split(equationRegex).map((part, i) => {
                if (part.startsWith('@')) {
                  return <React.Fragment key={i}>{renderEquation(part)}</React.Fragment>;
                } else {
                  return <React.Fragment key={i}>{part}</React.Fragment>;
                }
              })}
            </span>
          );
        }
    } else {*/
      //const assMessage = line.replace(/^<T>:\s*/, '').trim();
      /*const imgRegex = /\[Image:?(\s*[^\]]+)\]/;
      const equationRegex = /(\@{1,2}[^@]+\@{1,2})/g;
      const boxPlotRegex = /(\[BoxPlot:\s*([^\]]+)\])/;
      const scatterPlotRegex = /\[ScatterPlot:\s*([^\]]+)\]/;
      const linePlotRegex = /\[LinePlot:\s*([^\]]+)\]/;

      if (boxPlotRegex.test(assMessage)) {
        const boxPlotData = assMessage.match(boxPlotRegex)[2].trim();
        const containerId = `box-plot-${index}`;
      
        setBoxPlotsData((prevState) => ({
          ...prevState,
          [containerId]: boxPlotData,
        }));
      
        const textBeforeAndAfterBoxPlot = assMessage.replace(boxPlotRegex, 'SPLIT_HERE').split('SPLIT_HERE').filter((text) => text.trim() !== '');
        const textBeforeBoxPlot = textBeforeAndAfterBoxPlot[0].trim();
        const textAfterBoxPlot = textBeforeAndAfterBoxPlot[1].trim();
      
        return (
          <React.Fragment key={index}>
            {textBeforeBoxPlot && <div>{textBeforeBoxPlot}</div>}
            <div id={containerId} className={styles['plot-container']} style={{ width: '100%', height: '400px' }}></div>
            {textAfterBoxPlot && <div>{textAfterBoxPlot}</div>}
          </React.Fragment>
        );
      }        

      if (scatterPlotRegex.test(assMessage)) {
        const scatterPlotData = assMessage.match(scatterPlotRegex)[1].trim();
        const containerId = `scatter-plot-${index}`;

        setScatterPlotsData((prevState) => ({
          ...prevState,
          [containerId]: scatterPlotData,
        }));

        const textAfterScatterPlot = assMessage.replace(scatterPlotRegex, '').trim();

        return (
          <React.Fragment key={index}>
            <div id={containerId} className={styles['plot-container']} style={{ width: '100%', height: '400px' }}></div>
            {textAfterScatterPlot && <div>{textAfterScatterPlot}</div>}
          </React.Fragment>
        );
      }

      if (linePlotRegex.test(assMessage)) {
        const linePlotData = assMessage.match(linePlotRegex)[1].trim();
        const containerId = `line-plot-${index}`;

        setLinePlotsData((prevState) => ({
          ...prevState,
          [containerId]: linePlotData,
        }));

        const textAfterLinePlot = assMessage.replace(linePlotRegex, '').trim();

        return (
          <React.Fragment key={index}>
            <div id={containerId} className={styles['plot-container']} style={{ width: '100%', height: '400px' }}></div>
            {textAfterLinePlot && <div>{textAfterLinePlot}</div>}
          </React.Fragment>
        );
      }

      const messageParts = assMessage.split(imgRegex);
      return (
        <React.Fragment key={index}>
          {messageParts.map((part, i) => {
            if (i % 2 === 1) {
              const imgText = part.trim();
              const imageUrl = imageUrls[imgText];
              ////console.log("imageURL: ", imageUrl);
              return (
                <ImageContent
                  key={i}
                  description={imgText}
                  imageUrl={imageUrl}
                  onImageClick={handleImageClick}
                />
              );
            } else {
              return (
                <React.Fragment key={i}>
                  {part.split(equationRegex).map((equationPart, j) => {
                    if (equationPart.startsWith("@")) {
                      return (
                        <React.Fragment key={j}>
                          {renderEquation(equationPart)}
                        </React.Fragment>
                      );
                    } else {
                      return (
                        <React.Fragment key={j}>
                          {equationPart}
                        </React.Fragment>
                      );
                    }
                  })}
                  {(i < messageParts.length - 1 || i === messageParts.length - 1) && <br />}
                </React.Fragment>
              );
            }
          })}
        </React.Fragment>
      );

    }*/
    });
  };

  const memoizedContent = useMemo(() => {
    if (lesson) {
      if (!content) {
        // Show "Generating..." message when content is not available or loading
        return <div>Generating...</div>;
      } else {
        return renderContent(content);
      }
    }
    return null;
  }, [lesson, content, imageUrls, quizzes]);

  if (!lesson) {
    return (
      <div className={styles['lesson-information']}>
        Select a lesson from the list.
      </div>
    );
  }
  ////console.log("Lesson Information - lesson: ", lesson);
  ////console.log("Lesson Information - content: ", content);

  return (
    <div className={styles['lesson-information']} ref={scrollContainerRef}>
      <div>{memoizedContent}</div>
      <div ref={endOfChatRef} />
      {isModalOpen && (
        <div className={styles.modal} onClick={closeModal}>
          <img src={modalImageUrl} alt="Enlarged diagram" className={styles.modalImage} />
        </div>
      )}
      <ToastContainer />
    </div>
  );
}

export default LessonInformation;