import React, { useState, useEffect } from 'react';
import styles from './Account.module.css';
import { doc, getDoc } from 'firebase/firestore';
import { authInstance, EmailAuthProvider, firestoreInstance } from '../../../services/firebase';
import { updatePassword, reauthenticateWithCredential } from 'firebase/auth';

function Account() {
  const [showChangePasswordForm, setShowChangePasswordForm] = useState(false);
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userData, setUserData] = useState({ name: '', email: '' });

  useEffect(() => {
    fetchUserData();
  }, []);
  
  const handleToggleChangePasswordForm = () => {
    setShowChangePasswordForm(!showChangePasswordForm);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      alert("New passwords don't match.");
      return;
    }

    try {
      const user = authInstance.currentUser;
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      alert('Password changed successfully.');

      setNewPassword('');
      setConfirmPassword('');
      setShowChangePasswordForm(false);
    } catch (error) {
      alert(error.message);
    }
  };

  async function fetchUserData() {
    const user = authInstance.currentUser;
  
    if (user) {
      const userDoc = await getDoc(doc(firestoreInstance, 'users', user.uid));
      if (userDoc.exists()) {
        setUserData(userDoc.data());
      } else {
        //console.log('User not found in the database');
      }
    } else {
      //console.log('No user is currently signed in');
    }
  }
  

  return (
    <>
      <h3>My Account</h3>
      <div className={styles['account-container']}>
        <div className={styles['account-card']}>
          <div className={styles['account-content-wrapper']}>
            <div className={styles['account-content']}>
              <h4>Profile</h4>
              <p>Name: {userData.name}</p>
              <p>Email: {userData.email}</p>
              <button className={styles['edit-btn']}>Edit Profile</button>
            </div>
          </div>
        </div>
        <div className={styles['account-card']}>
          <div className={styles['account-content-wrapper']}>
            <div className={styles['account-content']}>
              <div className={styles['password-row']}>
                <h4>Password</h4>
                <p>********</p>
              </div>
              <button className={`${styles['edit-btn']} ${styles['change-password-btn']}`} onClick={handleToggleChangePasswordForm}>Change Password</button>
              <div className={`${styles['form-container']} ${showChangePasswordForm ? styles.show : ''}`}>
                <form className={styles['account-form']} onSubmit={handleChangePassword}>
                  <label htmlFor="currentPassword">Current Password:</label>
                  <input type="password" id="currentPassword" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} />
                  <label htmlFor="newPassword">New Password:</label>
                  <input type="password" id="newPassword" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                  <label htmlFor="confirmPassword">Confirm New Password:</label>
                  <input type="password" id="confirmPassword" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                  <button type="submit">Submit</button>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className={styles['account-card']}>
          <div className={styles['account-content-wrapper']}>
            <div className={styles['account-content']}>
              <h4>Subscription</h4>
              <p>Status: Active</p>
              <button className={styles['edit-btn']}>Manage Subscription</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Account;
