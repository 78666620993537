import { touchRippleClasses } from '@mui/material';
import { generateText, generateStream } from '../../services/openai';
import EvaluationEngine from '../EvaluationEngine/EvaluationEngine';
import { PromptGenerator } from './PromptGenerator';
import  ResponseProcessor from '../ContentRecommender/ResponseProcessor';
import { refineResponse } from './utils/responsePostProcessing';
import { updateSubtopicScore, getCurrentSubtopicScore, getCurrentSubtopicIndex, updateCurrentSubtopicIndex, fetchImagesBySubtopic, getCurrentSubtopic, savePerformanceSummaryToSubtopic } from '../../services/lessonService';


class ResponseGenerator {

  constructor() {
    this.promptGenerator = new PromptGenerator();
    //this.responseProcessor = new ResponseProcessor();
  }

  parseActionNumber(text) {
    const regex = /ACTION:\s+(\d+)/;
    const match = text.match(regex);
  
    if (match && match[1]) {
      return parseInt(match[1], 10);
    } else {
      return null;
    }
  }

  parsePerformanceSummary(text) {
    const regex = /SUMMARY:\s+(.*)/;
    const match = text.match(regex);
  
    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }
  
  async generateResponse(userId, subject, lessonId, lessonTitle, curSubtopic, 
                                                                 curSubtopicIndex, 
                                                                 studentInput, 
                                                                 recentDialogueObjects, 
                                                                 pineContext, 
                                                                 images,
                                                                 depth,
                                                                 teachingStyle,
                                                                 speakingStyle, 
                                                                 lessonPlan) {
  
    // Generate the prompt for the GPT-4 model
    const messages = this.promptGenerator.generatePrompt(subject, 
                                                     lessonTitle, 
                                                     curSubtopic, 
                                                     curSubtopicIndex, 
                                                     studentInput, 
                                                     recentDialogueObjects, 
                                                     pineContext, 
                                                     images,
                                                     depth,
                                                     teachingStyle,
                                                     speakingStyle, 
                                                     lessonPlan);
    //const testMsg = this.promptGenerator.generateDecisionPrompt(currentLessonTopic, curSubtopic, studentInput, recentDialogueObjects, pineContext);

    if (this.promptGenerator.recentQuestion) {
      // The student's input is an answer to the question
      const question = this.promptGenerator.recentQuestion;
      const answer = studentInput;
      
      // Process the answer (e.g., compare it to the correct answer, update scores, etc.)
      // QuestionIntegrity maybe
      // Clear the recentQuestion since the answer has been processed
      this.promptGenerator.recentQuestion = null;
    }

    //console.log("PROMPT TO SEND", messages);
    let response = await generateStream(messages);
   
    //If there is just a student response
    if (Object.keys(recentDialogueObjects).length === 1) {
      return response;
    }

    // Wrap the part where you want to run asynchronously in a function
    const getPerfSummaryResponse = async () => {
      const testMsg2 = this.promptGenerator.generateRealDecisionPrompt(lessonTitle, curSubtopic, studentInput, response, recentDialogueObjects);
      //console.log("PERF SUMMARY PROMPT", testMsg2);
      let nextTestResponse = await generateText(testMsg2, "gpt-3.5-turbo-0613");
      //console.log("PERF SUMMARY", nextTestResponse);
      
      // Process the nextTestResponse here or return it and process it elsewhere
      return nextTestResponse;
    }

    // Call the function and handle the result when it's ready
    getPerfSummaryResponse().then(async nextTestResponse => {
        // Do something with nextTestResponse here
        const actionNumber = this.parseActionNumber(nextTestResponse);
        const performanceSummary = this.parsePerformanceSummary(nextTestResponse);
        //console.log("Performance summary is ready:", performanceSummary);
        await savePerformanceSummaryToSubtopic(userId, subject, lessonId, curSubtopicIndex, performanceSummary);
    });

   
    /*let response = testResponse;

    if (response.startsWith("<S>:")) {
      response = "";
    }
    if (!response.startsWith("<T>:")) {
      response = "<T>:" + response;
    }
    // Check for question type indicators in the response
    const questionTypeIndicator = response.match(/\[(MCQ|FRQ)\]/);
    if (questionTypeIndicator) {
      //console.log('Question type:', questionTypeIndicator[1]);

      // Extract the question
      const questionRegex = /\[(MCQ|FRQ)\](.*?)\[\/(MCQ|FRQ)\]/;
      const questionMatch = response.match(questionRegex);
      if (questionMatch) {
        const question = questionMatch[2].trim();
        //console.log('ResponseGenerator() generateResponse() Question:', question);

        // Update the recentQuestion variable in the promptGenerator
        this.promptGenerator.updateRecentQuestion(question);
        // Set the flag to not update the subtopic score
        //this.evaluationEngine.setShouldUpdateSubtopicScore(false);
      }
    }
    else {
      // Set the flag to update the subtopic score
      //this.evaluationEngine.setShouldUpdateSubtopicScore(true);
    }

    // Process the response (e.g., decode tokens, extract information, format text, add content)
    //const refinedResponse = await this.responseProcessor.processResponse(response);

    // Return the generated response
    //console.log('Response: ', response);*/
    return response;
  }

  /*TODO: async generateSubtopicIntroduction(currentLessonTopic, curSubtopic, curSubtopicIndex, nextAction, recentDialogueObjects, pineContext, images) {
    messages1 = this.promptGenerator.generateSubtopicInfoPrompt(newSubtopic.name, curSubtopicIndex + 1, pineContext, newImages);
  }*/

  async generateResponseAction(currentLessonTopic, curSubtopic, curSubtopicIndex, nextAction, recentDialogueObjects, pineContext, images) {

    // Generate the prompt for the GPT-4 model
    //const messages = this.promptGenerator.generateActionPrompt(currentLessonTopic, curSubtopicIndex, nextAction, recentDialogueObjects, pineContext, images);
    let messages;

    switch (nextAction.action) {
      case 'moveToNextSubtopic':
        messages = this.promptGenerator.generateActionPrompt(currentLessonTopic, curSubtopicIndex, nextAction, recentDialogueObjects, pineContext, images);
        break;
      case 'continueWithMoreDetails':
        messages = this.promptGenerator.generateExamplePrompt(curSubtopic, pineContext);
        break;
      case 'provideAnotherQuestion':
        messages = this.promptGenerator.generateQuestionPrompt(curSubtopic, pineContext);
        break;
      default:
        messages = this.promptGenerator.generateActionPrompt(currentLessonTopic, curSubtopicIndex, nextAction, recentDialogueObjects, pineContext, images);
        break;
    }

    //console.log("ResponseGenerator generateResponse() promptAction: ", messages);

    // Call the GPT-4 API with the generated prompt
    let actionResponse = await generateStream(messages);
    return actionResponse;
  }
}
export default ResponseGenerator;