import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './GradeDetails.module.css';
import defaultImage from '../../ui/assets/defaultImage.png';
import { useNavigate, useRouteMatch, useParams, Routes, Route } from 'react-router-dom';
import ProgressCircle from '../../ui/ProgressCircle';
import { fetchAllGradeables } from '../../../services/gradingService';
import { authInstance } from '../../../services/firebase';
import GradeDetailContext from './GradeDetailContext';


function GradeDetails({ courseList }) {
    const [assignments, setAssignments] = useState([]);
    const [quizzes, setQuizzes] = useState([]);
    const [exams, setExams] = useState([]);
    const [selectedGradeType, setSelectedGradeType] = useState('All'); // default type
    const [selectedCourse, setSelectedCourse] = useState(null);
    const userId = authInstance.currentUser.uid;

    const encodedCourse = useParams();
    const encodedCourseName = encodedCourse.courseName;
    const selectedCourseName = decodeURIComponent(encodedCourseName);
    const gradeablesForCourse = useContext(GradeDetailContext);

    const filterByType = (gradeables, type) => {
        return gradeables.filter(gradeable => gradeable.type === type);
    };

    useEffect(() => {
        // Fetch data here
        const foundCourse = courseList.find(course => course.name === selectedCourseName);
        setSelectedCourse(foundCourse);
    }, [courseList, selectedCourseName]);  // re-run the effect when these variables change

    // Dummy data
    useEffect(() => {

        //const assignmentsList = await fetchAllGradeables(userId, "Assignments");
        //const quizzesList = await fetchAllGradeables(userId, "Quizzes");
        //const examsList = await fetchAllGradeables(userId, "Exams");
        //console.log(gradeablesForCourse);
        const assignments = filterByType(gradeablesForCourse, 'Assignment');
        const quizzes = filterByType(gradeablesForCourse, 'Quiz');
        const exams = filterByType(gradeablesForCourse, 'Exam');

        setAssignments(assignments);
        setQuizzes(quizzes);
        setExams(exams);
    }, []);

    // Check if selectedCourse is defined
    if (!selectedCourse) {
        //console.log(`No course found with the name: ${selectedCourseName}`);
        return null; // or some loading message
    }

    const courseGrade = selectedCourse && selectedCourse.grade;

    let assignPoints = 0, assignTotalPoints = 0, quizPoints = 0, quizTotalPoints = 0, examPoints = 0, examTotalPoints = 0;

    if (Array.isArray(courseGrade)) {

        if (courseGrade.length > 0) {
            assignPoints = courseGrade[0].assignmentPoints || 0;
            assignTotalPoints = courseGrade[0].totalassignmentPoints || 0;
        }

        if (courseGrade.length > 1) {
            quizPoints = courseGrade[1].quizPoints || 0;
            quizTotalPoints = courseGrade[1].totalquizPoints || 0;
        }

        if (courseGrade.length > 2) {
            examPoints = courseGrade[2].examPoints || 0;
            examTotalPoints = courseGrade[2].totalexamPoints || 0;
        }
    }
    // If the selected course was found, get its imageUrl. Otherwise, use a default value.
    const courseImage = selectedCourse ? selectedCourse.image : defaultImage;

    // Create function for each button
    const handleAllClick = () => setSelectedGradeType('All');
    const handleAssignmentsClick = () => setSelectedGradeType('Assignment');
    const handleQuizzesClick = () => setSelectedGradeType('Quiz');
    const handleExamsClick = () => setSelectedGradeType('Exam');

    // Calculate the total score for each category
    //const totalAssignmentScore = assignments.reduce((total, assignment) => total + assignment.score, 0);
    //const totalQuizScore = quizzes.reduce((total, quiz) => total + quiz.score, 0);
    //const totalExamScore = exams.reduce((total, exam) => total + exam.score, 0);

    /*if (assignPoints !== totalAssignmentScore) {
        assignPoints = totalAssignmentScore / 10;
        assignTotalPoints = assignments.length * 10;
    }*/

    const totalStudentPoints = assignPoints + quizPoints + examPoints;
    const totalCoursePoints = assignTotalPoints + quizTotalPoints + examTotalPoints;
    let totalGrade = (totalCoursePoints > 0) ? ((totalStudentPoints / totalCoursePoints) * 100).toFixed(2) : 100;

    // Filter the items based on selected type
    const gradeItems = [...assignments, ...quizzes, ...exams].filter(item => selectedGradeType === 'All' ? true : item.type === selectedGradeType);

    return (
        <div className={styles['cards-container']}>
            <Routes>
                <Route path="/" element={
                    <>
                        <div className={styles['opening-card']} elevation={3}>
                            <div className={styles['opening-card-content']}>
                                {/* New text fields */}
                                <div className={styles['opening-header']}>
                                    {selectedCourseName}
                                </div>
                                <div className={styles['opening-description']}>
                                    Grades
                                </div>
                            </div>
                            <img className={styles['opening-card-image']} src={courseImage} />
                        </div>
                        <div className={styles['container']}>
                            <div className={styles['grade-card']} elevation={3}>
                                <div className={styles['button-container']}>
                                    <button onClick={handleAllClick}
                                        className={`${styles['grade-button']} ${selectedGradeType === 'All' ? styles['grade-button-active'] : ''}`}>All</button>
                                    <button onClick={handleAssignmentsClick}
                                        className={`${styles['grade-button']} ${selectedGradeType === 'Assignment' ? styles['grade-button-active'] : ''}`}>Assignments</button>
                                    <button onClick={handleQuizzesClick}
                                        className={`${styles['grade-button']} ${selectedGradeType === 'Quiz' ? styles['grade-button-active'] : ''}`}>Quizzes</button>
                                    <button onClick={handleExamsClick}
                                        className={`${styles['grade-button']} ${selectedGradeType === 'Exam' ? styles['grade-button-active'] : ''}`}>Exams</button>
                                </div>
                                <div className={styles['grades-content']}>
                                    {/* Grades information */}
                                    {gradeItems.sort((a, b) => a.dueDate?.toDate() - b.dueDate?.toDate()).map((item, index) => {
                                        // Format the date only if the dueDate property exists
                                        let formattedDate = 'TBD';
                                        if (item.dueDate) {
                                            // Convert ISO date string to a Date object
                                            const date = item.dueDate.toDate();

                                            // Convert to 12-hour format
                                            let hours = date.getHours();
                                            const minutes = date.getMinutes().toString().padStart(2, '0');
                                            const ampm = hours >= 12 ? 'PM' : 'AM';
                                            hours = hours % 12;
                                            hours = hours ? hours : 12; // the hour '0' should be '12'

                                            // Format the date
                                            formattedDate = `${date.toLocaleString('default',
                                                { month: 'long' })} 
                                                ${date.getDate()}, 
                                                ${date.getFullYear()} 
                                                ${hours}:${minutes} 
                                                ${ampm}`;
                                        }

                                        return (
                                            <li
                                                key={item.name}
                                                className={`${styles['grade-item-li']} ${index === gradeItems.length - 1 ? styles['last-item'] : ''}`}
                                            >
                                                <div className={styles['grade-item-name']} variant="h5" component="h2">
                                                    {item.type} ({item.title})
                                                </div>
                                                <div className={styles['grade-item-score']}>
                                                    Score: {item.score}  Due Date: {formattedDate}
                                                </div>
                                            </li>
                                        );
                                    })}
                                </div>
                            </div>
                            <div className={styles['grades-card']} elevation={3}>
                                <h2 className={styles['card-title']}>Current Grade</h2>
                                <ProgressCircle className={styles['progress-circle']} progress={totalGrade} />

                                <div className={styles['total-scores']}>
                                    <p className={styles['score-text']}>Total Assignment Score: {assignPoints}/{assignTotalPoints}</p>
                                    <p className={styles['score-text']}>Total Quiz Score: {quizPoints}/{quizTotalPoints}</p>
                                    <p className={styles['score-text']}>Total Exam Score: {examPoints}/{examTotalPoints}</p>
                                </div>
                            </div>
                        </div>
                    </>
                } />
                <Route path=":courseName" element={<GradeDetails />} />
            </Routes>
        </div>
    );
}

export default GradeDetails;
