import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styles from './ExamsPage.module.css';
import { fetchGradeableOfType } from '../../../services/gradingService';
import { authInstance, firestoreInstance } from '../../../services/firebase';
import ExamDetails from './ExamDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import gradingExamImage from '../../ui/assets/gradingAssignment.gif'

function ExamsPage() {
  //console.log(useParams());
  const encodedCourse = useParams();
  const encodedCourseName = encodedCourse.courseName;
  const selectedCourseName = decodeURIComponent(encodedCourseName);
  const userID = authInstance.currentUser.uid;

  const [exams, setExams] = useState([]);
  const [selectedExam, setSelectedExam] = useState(null);
  const [tab, setTab] = useState('current');  // Default tab

  useEffect(() => {
    const getExams = async () => {
      const fetchedExams = await fetchGradeableOfType(userID, selectedCourseName, "Exams");
      setExams(fetchedExams);
      //console.log("Exams: ", exams);
      //console.log("Course: ", selectedCourseName);
      //console.log("UserID", userID);
    };

    getExams();
  }, [userID, selectedCourseName]);

  const handleExamClick = (assignment) => {
    setSelectedExam(assignment);
  };

  useEffect(() => {
    const examsRef = collection(firestoreInstance, `users/${userID}/Exams`);
    const courseQuery = query(examsRef, where('course', '==', selectedCourseName));

    // Set up snapshot listener
    const unsubscribe = onSnapshot(courseQuery, (snapshot) => {
      const updatedExams = snapshot.docs.map((doc) => ({
        ...doc.data(),
        _id: doc.id,
        context: doc.data().context || ''
      }));
      setExams(updatedExams);
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, [userID, selectedCourseName]);

  const getDueDateColor = (dueDate) => {
    const due = new Date(dueDate.toDate());
    const today = new Date();

    // Calculate the difference in days
    const diffTime = Math.abs(due - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Return color based on the difference
    if (diffDays <= 2) {
      return "red";
    } else if (diffDays <= 5) {
      return "orange";
    } else {
      return "green";
    }
  };

  function formatISODate(dueDate) {
    const dateObj = new Date(dueDate.toDate());
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const formattedMonth = monthNames[dateObj.getMonth()];
    const formattedDay = String(dateObj.getDate()).padStart(2, '0');

    return `${formattedMonth} ${formattedDay}`;
  }

  const currentExams = exams.filter(exam => (exam.status === "Creating" || exam.status === "In Progress"));
  const pastExams = exams.filter(exam => exam.status === "Submitted" || exam.status === "Graded");

  return (
    <div className={styles['exam-selection']}>
      {selectedCourseName && (
        <>
          {!selectedExam ? (
            <>
              <div className={styles['exam-header']}>
                <h3 className={styles['title']}>
                  {selectedCourseName} Exams
                </h3>
                <div className={styles['tab-buttons']}>
                  <button
                    className={`${styles['tab-button']} ${tab === 'current' ? styles['active'] : ''}`}
                    onClick={() => setTab('current')}
                  >
                    Current
                  </button>
                  <button
                    className={`${styles['tab-button']} ${tab === 'past' ? styles['active'] : ''}`}
                    onClick={() => setTab('past')}
                  >
                    Past
                  </button>
                </div>
              </div>

              {tab === 'current' ? (
                <>
                  <div className={styles['exam-list']}>
                    {currentExams.length === 0 ? (
                      <div className={styles['empty-exams']}>
                        <h3>No exams to show</h3>
                        {/* Add an image or other elements here if you want */}
                      </div>
                    ) : (currentExams.map((exam) => (
                      <div
                        key={exam._id}
                        className={styles['exam-card']}
                        onClick={exam.status === "Creating" || exam.status === "Submitted" ? null : () => handleExamClick(exam)}
                        style={exam.status === "Creating" || exam.status === "Submitted" ? { pointerEvents: 'none', opacity: '0.6' } : {}}
                      >
                        <div className={styles['due-date']}>
                          <div>DUE</div>
                          <div style={{ color: getDueDateColor(exam.dueDate) }}>
                            {formatISODate(exam.dueDate)}
                          </div>
                        </div>
                        <div className={styles['card']}>
                          {exam.status === "Creating" && <img src={gradingExamImage} />}
                          <h4>{exam.status === "Creating" ? "Creating" : exam.title}</h4>
                        </div>
                        <div className={styles['chevron']}>
                          <FontAwesomeIcon icon={faChevronRight} color="blue" />
                        </div>
                      </div>
                    )))}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles['exam-list']}>
                    {
                      pastExams.length === 0 ? (
                        <div className={styles['empty-exams']}>
                          <h3>No past exams to show</h3>
                          {/* Add an image or other elements here if you want */}
                        </div>
                      ) : (pastExams
                        .sort((a, b) => b.dueDate.toDate() - a.dueDate.toDate())
                        .map((exam) => (
                          <div
                            key={exam._id}
                            className={styles['exam-card']}
                            onClick={exam.status === "Submitted" ? null : () => handleExamClick(exam)}
                            style={exam.status === "Submitted" ? { pointerEvents: 'none', opacity: '0.6' } : {}}
                          >
                            <div className={styles['due-date']}>
                              <div>DUE</div>
                              <div style={{ color: getDueDateColor(exam.dueDate) }}>
                                {formatISODate(exam.dueDate)}
                              </div>
                            </div>
                            <div className={styles['card']}>
                              {exam.status === "Submitted" && <img src={gradingExamImage} />}
                              <h4>{exam.status === "Submitted" ? "Grading" : exam.title}</h4>
                            </div>
                            <div className={styles['chevron']}>
                              <FontAwesomeIcon icon={faChevronRight} color="blue" />
                            </div>
                          </div>
                        )))}
                  </div>
                </>
              )}
            </>
          ) : (
            <ExamDetails exam={selectedExam} />
          )}
        </>
      )}
    </div>
  );
}
export default ExamsPage;
