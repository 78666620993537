import React from 'react';
import styles from './ImageContent.module.css';

const ImageContent = ({ description, imageUrl, onImageClick }) => {
  return (
    <div className={styles['image-content-card']}>
      <img
        src={imageUrl}
        alt="Diagram"
        className={styles['image']}
        onClick={() => onImageClick(imageUrl)}
      />
      <p className={styles['image-description']}>{/*description*/}</p>
    </div>
  );
};


export default ImageContent;