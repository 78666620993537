import React, { useState } from 'react';
import QuestionItem from './QuestionItem';
import styles from './AssignmentDetails.module.css';
import { gradeGradeable, addToGradingQueue, markGradeableAsSubmitted, saveGradeable } from '../../../services/gradingService';
import { authInstance } from '../../../services/firebase';
import { useNavigate } from 'react-router-dom';


function AssignmentDetails({ assignment }) {

  const navigate = useNavigate();

  // Store selectedOptions in an object where keys are question indices and values are selected options
  // Store selectedOptions in an object where keys are question indices and values are selected options
  const [selectedOptions, setSelectedOptions] = useState(() => {
    const initialState = {};
    assignment.questions.forEach((question, index) => {
      initialState[index] = question.studentAnswer;
    });
    return initialState;
  });


  const handleOptionSelected = (id, option) => {
    setSelectedOptions(prev => ({ ...prev, [id]: option }));
  };

  const handleSaveButtonClick = async () => {
    const userID = authInstance.currentUser.uid;
    //console.log("Save Button Clicked!");

    const assignmentCopy = JSON.parse(JSON.stringify(assignment));

    // Loop over the questions and update the studentAnswer field
    assignmentCopy.questions.forEach((question, index) => {
      question.studentAnswer = selectedOptions[index] || null;
      //console.log("Question:", question);
      //console.log("Selected Option: ", selectedOptions[index]);
    });

    await saveGradeable(userID, assignment._id, assignmentCopy, "Assignments");
  };

  const handleSubmitButtonClick = async () => {
    const userID = authInstance.currentUser.uid;
    //console.log("Submit Button Clicked!");

    const assignmentCopy = JSON.parse(JSON.stringify(assignment));

    // Loop over the questions and update the studentAnswer field
    assignmentCopy.questions.forEach((question, index) => {
      question.studentAnswer = selectedOptions[index] || null;
    });

    await saveGradeable(userID, assignment._id, assignmentCopy, "Assignments")

    await markGradeableAsSubmitted(userID, assignment._id, assignmentCopy, "Assignments");
    navigate("/student-platform/assignments");
    //window.location.reload();

    ////console.log("UserID: ", userID);
    ////console.log("GradeableID: ", assignment._id);
    ////console.log("Gradeable: ", assignmentCopy);
    ////console.log("Gradeable questions: ", assignment.questions);
    ////console.log("Gradeable Type: ", "Assignments");

    //addToGradingQueue(userID, assignment._id, assignmentCopy, "Assignments");
    gradeGradeable(userID, assignment._id, assignmentCopy, "Assignments");
  };

  return (
    <div className={styles['assignment-details']}>
      <h1>{assignment.title}</h1>
      <div>
        {assignment.questions.map((question, index) => {
          return (
            <div key={index} className={styles['block']}>
              <QuestionItem
                question={question}
                index={index}
                onOptionSelected={(index, option) => handleOptionSelected(index, option, question.questionType)}
                completed={assignment.status === "Graded"}
              />
            </div>
          );
        })}
      </div>
      {
        assignment.status === "In Progress" ?
          <>
            <button className={styles['submit-button']} onClick={handleSubmitButtonClick}>
              Submit
            </button>
            <button className={styles['save-button']} onClick={handleSaveButtonClick}>
              Save
            </button>
          </>
          : null
      }
    </div>
  );
}

export default AssignmentDetails;
