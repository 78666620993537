import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate, useNavigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/shared/Navbar/Navbar';
import StudentDashboard from './components/teacher/Dashboard/StudentDashboard';
import AiEdPlatform from './components/student/AiEdPlatform';
import LoginPage from './components/shared/Login/LoginPage';
import AssignmentsPage from './components/student/assignments/AssignmentsPage';
import CoursePageRoute from './components/shared/Navbar/CoursePageRoute';
import { authInstance } from './services/firebase';
import { getUserRole } from './services/userService';
import Assignments from './components/shared/SideNav/Assignments';


function App() {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const unsubscribe = authInstance.onAuthStateChanged(async (user) => {
      setUser(user);
      //console.log("USER: ", user.uid);
      if (user) {
        const role = await getUserRole(user.uid);
        setUserRole(role);
      } else {
        setUserRole(null);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  if (!user) {
    return (
      <>
        <div className="background-animation"></div>
        <LoginPage />
      </>);
  }

  // Replace the following condition with the one based on user roles
  // when you have the getUserRole function implemented
  //return (
    //<Router>
      //<Navbar />
      //<Routes>
        //<Route path="/login" element={<LoginPage />} />
        //<Route path="/student-dashboard" element={<StudentDashboard />} />
        //<Route path="/student-platform" element={<AiEdPlatform />} />
      //</Routes>
    //</Router>
  //);

  if (userRole === 'student') {
    return (
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Navigate to="/student-platform" />} />
          <Route path="/student-platform/*" element={<AiEdPlatform />} />
          <Route path="/course" element={<CoursePageRoute />} />
          <Route path="/assignments/*" element={<Assignments />} />
        </Routes>
      </Router>
    );
  }

  if (userRole === 'teacher') {
    return (
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Navigate to="/student-dashboard" />} />
          <Route path="/student-dashboard" element={<StudentDashboard />} />
        </Routes>
      </Router>
    );
  }

  return (
    <Router>
      <Navbar />
    </Router>
  );
}

export default App;