import { firestoreInstance } from './firebase';
import { collection, doc, getDocs, getDoc, setDoc, addDoc, updateDoc, serverTimestamp, arrayUnion, onSnapshot, where, query } from 'firebase/firestore';
import { generateText } from './openai';
import axios from 'axios';
import { fetchUserLessons } from './lessonService';

const BACKEND_SERVER_URL = process.env.REACT_APP_BACKEND_SERVER_URL || 'https://api1.edu-ai.io';
//const BACKEND_SERVER_URL = 'http://172.17.0.3:5000';
//const BACKEND_SERVER_URL = 'http://host.docker.internal:5000';

export const addToGradingQueue = async (userID, gradeableID, gradeable, gradeableType) => {
    try {
        const response = await axios.post(`${BACKEND_SERVER_URL}/api/add-to-queue`, {
            userID, gradeableID, gradeable, gradeableType,
        });

        //console.log(response.data.message);
        return response.data.message;
    } catch (error) {
        console.error('Error adding to grading queue:', error);
        return '';
    }
};

export const uploadGradeable = async (userID, gradeable, gradeableType) => {
    try {
        const gradeablesRef = collection(firestoreInstance, `users/${userID}/${gradeableType}`);

        const docRef = await addDoc(gradeablesRef, gradeable);
        //console.log(`New ${gradeableType} created with ID: `, docRef.id);
        return docRef.id;  // return the ID
    }
    catch (e) {
        console.error('Error saving document: ', e);
    }
}

export const updateGradeable = async (userID, docId, updatedGradeable, gradeableType) => {
    try {
        const docRef = doc(firestoreInstance, `users/${userID}/${gradeableType}/${docId}`);
        await setDoc(docRef, updatedGradeable, { merge: true });
        //console.log(`${gradeableType} updated with ID: `, docId);
    }
    catch (e) {
        console.error('Error updating document: ', e);
    }
}

export const saveGradeable = async (userID, gradeableID, gradeable, gradeableType) => {
    try {
        const gradeableRef = doc(firestoreInstance, `users/${userID}/${gradeableType}/${gradeableID}`);

        //console.log(`${gradeableType}: `, gradeable);
        //console.log(`Old ${gradeableType}: `, gradeableRef);

        await setDoc(gradeableRef, { questions: gradeable.questions }, { merge: true });
        //console.log(`${gradeableType} saved: `, gradeableID);
    }
    catch (e) {
        console.error(`Error saving ${gradeableType}: `, e);
    }
}

export const markGradeableAsSubmitted = async (userID, gradeableID, gradeable, gradeableType) => {
    const gradeableRef = doc(firestoreInstance, `users/${userID}/${gradeableType}/${gradeableID}`);

    // Set completed to true and grading to true
    await setDoc(gradeableRef, { status: "Submitted" }, { merge: true });
}

export const markGradeableAsCompleted = async (userID, gradeableID, gradeable, gradeableType) => {
    const gradeableRef = doc(firestoreInstance, `users/${userID}/${gradeableType}/${gradeableID}`);

    // Set completed to true and grading to true
    await setDoc(gradeableRef, { completed: true, grading: true }, { merge: true });
}

export const gradeGradeable = async (userID, gradeableID, gradeable, gradeableType) => {
    try {
        const gradeableRef = doc(firestoreInstance, `users/${userID}/${gradeableType}/${gradeableID}`);
        //console.log("Working at start");
        let points = 0;
        let total = 0;
        let questions = [...gradeable.questions];  // Copy existing questions array

        for (let i = 0; i < questions.length; i++) {
            if (questions[i].questionType === 'MCQ') {
                total += 1;
                if (questions[i].correctAnswer === questions[i].studentAnswer) {
                    points += 1;
                    questions[i].score = 1;  // Add score to individual question
                } else {
                    questions[i].score = 0;
                }
            }

            if (questions[i].questionType === 'FRQ') {
                total += 5;

                const response = await gradeFRQ(questions[i], gradeable.course, gradeable.title);
                //console.log(typeof response.score);
                points += response.score;
                const unescapedExplanation = response.explanation
                    .replace(/\\n/g, '\n')
                    .replace(/\\t/g, '\t')
                    .replace(/\\r/g, '\r');
                const explanation = unescapedExplanation;
                //console.log(explanation);

                questions[i].score = response.score;  // Add score to individual question
                questions[i].evaluation = response.explanation
            }
        }

        let score = 0;
        if (total > 0) {
            score = (points / total) * 100;
            score = Math.round(score * 100) / 100;  // Round to 2 decimal places
        }
        //console.log("Grading Complete");
        const course = gradeable.course;
        let type;
        if (gradeableType === "Assignments") {
            type = "assignment";
        } else if (gradeableType === "Quizzes") {
            type = "quiz";
        } else if (gradeableType === "Exams") {
            type = "exam";
        } else {
            // Default case if needed
            type = gradeableType.toLowerCase();
        }
        await updateCourseGrade(userID, course, points, total, type);
        //console.log("Course Grade Updated");

        // Update questions and total score, and set grading to false
        await setDoc(gradeableRef, { score, status: "Graded", questions }, { merge: true });
        //console.log(`${gradeableType} graded: `, gradeableID);
        return questions;
    }
    catch (e) {
        console.error(`Error grading ${gradeableType}: `, e);
    }
}

const updateCourseGrade = async (userID, course, points, total, type) => {
    const userDocRef = doc(firestoreInstance, `users/${userID}`);

    // Fetch the user's document
    const userDocSnapshot = await getDoc(userDocRef);

    // Check if the user's document exists
    if (!userDocSnapshot.exists()) {
        console.error(`No document found for user with ID: ${userID}`);
        return [];
    }

    // Get the user's data
    const userData = userDocSnapshot.data();

    // Find the course
    const courseIndex = userData.courses.findIndex(c => c.course === course);

    // Check if course was found
    if (courseIndex === -1) {
        console.error(`No course found with name: ${course} for user with ID: ${userID}`);
        return;
    }

    // Get the grade for the assignmentType
    const typeIndex = {
        'assignment': 0,
        'quiz': 1,
        'exam': 2,
    }[type];

    // Add points and total to the appropriate field
    userData.courses[courseIndex].grade[typeIndex][`${type}Points`] += points;
    userData.courses[courseIndex].grade[typeIndex][`total${type}Points`] += total;

    await updateDoc(userDocRef, {
        courses: userData.courses
    });

    //console.log(`Course "${course}" grade updated for user with ID: ${userID}`);
}

export const fetchCourseGrade = async (userID, course) => {
    const userDocRef = doc(firestoreInstance, `users/${userID}`);

    // Fetch the user's document
    const userDocSnapshot = await getDoc(userDocRef);

    // Check if the user's document exists
    if (!userDocSnapshot.exists()) {
        console.error(`No document found for user with ID: ${userID}`);
        return [];
    }

    // Get the user's data
    const userData = userDocSnapshot.data();

    // Find the course
    const courseIndex = userData.courses.findIndex(c => c.course === course);

    // Check if course was found
    if (courseIndex === -1) {
        console.error(`No course found with name: ${course} for user with ID: ${userID}`);
        return;
    }

    // Get the grade for the assignmentType
    const types = [
        'assignment',
        'quiz',
        'exam',
    ]

    let points = 0;
    let total = 0;
    for (let i = 0; i < types.length; i++) {
        points += userData.courses[courseIndex].grade[i][`${types[i]}Points`];
        total += userData.courses[courseIndex].grade[i][`total${types[i]}Points`];
    }

    let score = 100;
    if (total > 0) {
        score = (points / total) * 100;
        score = Math.round(score * 100) / 100;  // Round to 2 decimal places
    }
    else {
        return ['A', score];
    }

    let grade;
    if (score >= 90) {
        grade = 'A';
    } else if (score >= 80) {
        grade = 'B';
    } else if (score >= 70) {
        grade = 'C';
    } else if (score >= 60) {
        grade = 'D';
    } else {
        grade = 'F';
    }


    //console.log(`Course "${course}" grade retrieved for user with ID: ${userID}`);
    return [grade, score];
}

export const fetchQuizByTitle = async (userID, course, title) => {
    if (!userID || !course || !title) {
        console.error('Invalid arguments passed to fetchQuizByTitle');
        return [];
    }
    const gradeablesRef = collection(firestoreInstance, `users/${userID}/Quizzes`);

    // Add the where clauses to filter assignments by course and title
    const courseAndTitleQuery = query(gradeablesRef,
        where('course', '==', course),
        where('title', '==', title)
    );

    const querySnapshot = await getDocs(courseAndTitleQuery);

    const quizzes = [];
    querySnapshot.forEach((doc) => {
        quizzes.push({ ...doc.data(), _id: doc.id, context: doc.data().context || '' });
    });

    return quizzes;
};

export const fetchGradeable = async (userID, gradeableType, docId) => {
    try {
        const docRef = doc(firestoreInstance, `users/${userID}/${gradeableType}/${docId}`);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
            return docSnapshot.data();
        }
        else {
            console.error('Error fetching document: document does not exist');
            return null;
        }
    } catch (e) {
        console.error('Error fetching document: ', e);
        return null;
    }
}

export const fetchGradeableOfType = async (userID, course, gradeableType) => {
    const gradeablesRef = collection(firestoreInstance, `users/${userID}/${gradeableType}`);

    // Add the where clause to filter assignments by course
    const courseQuery = query(gradeablesRef, where('course', '==', course));
    const querySnapshot = await getDocs(courseQuery);

    const gradeables = [];
    querySnapshot.forEach((doc) => {
        gradeables.push({ ...doc.data(), _id: doc.id, context: doc.data().context || '' });
    });

    return gradeables;
};

export const fetchAllGradeables = async (userID, gradeableType) => {
    const gradeablesRef = collection(firestoreInstance, `users/${userID}/${gradeableType}`);

    // Remove the where clause to fetch all assignments
    const querySnapshot = await getDocs(gradeablesRef);

    const gradeable = [];
    querySnapshot.forEach((doc) => {
        gradeable.push({ ...doc.data(), _id: doc.id, context: doc.data().context || '' });
    });

    return gradeable;
};

export const fetchAllQuestions = async (userID, course, gradeableType) => {
    try {
        const gradeablesRef = collection(firestoreInstance, `users/${userID}/${gradeableType}`);

        const courseQuery = query(gradeablesRef, where('course', '==', course));
        const querySnapshot = await getDocs(courseQuery);

        let questions = [];
        querySnapshot.forEach((doc) => {
            const gradeableData = doc.data();
            if (gradeableData.questions && Array.isArray(gradeableData.questions)) {
                const questionsWithSubtopic = gradeableData.questions.map(question => ({
                    ...question,
                    subtopic: gradeableData.subtopic
                }));
                questions = [...questions, ...questionsWithSubtopic];
            }
        });

        return questions;
    } catch (error) {
        console.error('Error fetching questions: ', error);
        throw error;
    }
};

export const fetchQuestions = async (userID, course, gradeableType, start, end) => {
    try {
        const lessons = await fetchUserLessons(userID, course);
        lessons.sort(lessonSort);

        const lessonRange = lessons.slice(start, end + 1);
        const lessonIDs = lessonRange.map(lesson => lesson._id);

        //console.log("Sorted Lessons: ", lessons);
        //console.log("Lesson Range: ", lessonRange);
        //console.log("Lesson IDs: ", lessonIDs);

        const gradeablesRef = collection(firestoreInstance, `users/${userID}/${gradeableType}`);

        let questions = [];
        for (const lessonID of lessonIDs) {
            const lessonQuery = query(gradeablesRef, where('course', '==', course), where('lessonID', '==', lessonID));
            const querySnapshot = await getDocs(lessonQuery);

            querySnapshot.forEach((doc) => {
                const gradeableData = doc.data();
                if (gradeableData.questions && Array.isArray(gradeableData.questions)) {
                    const questionsWithSubtopic = gradeableData.questions.map(question => ({
                        ...question,
                        subtopic: gradeableData.subtopic
                    }));
                    questions = [...questions, ...questionsWithSubtopic];
                }
            });
        }

        return questions;
    } catch (error) {
        console.error('Error fetching questions: ', error);
        throw error;
    }
};

const lessonSort = (lessonA, lessonB) => {
    return lessonA.lessonNumber - lessonB.lessonNumber;
};

function parseJsonFromResponse(response) {
    // First, try parsing the response as JSON.
    let parsedResponse;
    try {
        parsedResponse = JSON.parse(response);
    } catch (e) {
        // If parsing fails, attempt to extract JSON from the response using regex.
        const jsonPattern = /({.*})/s;
        const match = response.match(jsonPattern);
        if (match) {
            try {
                parsedResponse = JSON.parse(match[0]);
            } catch (e) {
                console.error('Failed to parse extracted JSON content:', e);
            }
        } else {
            console.error('No JSON content found in response.');
        }
    }
    // Handle or return the parsedResponse as needed.
    return parsedResponse;
}

function sanitizeAndParseJSON(jsonString) {
    try {
        // Escape control characters like newline and tab
        const sanitizedString = jsonString
            .replace(/\n/gu, '\\n')
            .replace(/\t/gu, '\\t')
            .replace(/\r/gu, '\\r')
            .replace(/[\x00-\x08\x0B\x0C\x0E-\x1F\x7F-\x9F]/gu, '');

        // Attempt to parse the sanitized string as JSON
        const parsedJSON = JSON.parse(sanitizedString);

        // Return the parsed JSON object
        return parsedJSON;
    } catch (error) {
        // Log an error message if parsing fails
        console.error('Failed to parse JSON:', error);

        // Return null or some default value
        return null;
    }
}


async function gradeFRQ(question, courseName, subtopic) {
    const gradingPrompt = {
        "role": "Free Response Grader",
        "course_name": courseName,
        "subtopic": subtopic,
        "question": question.questionText,
        "studentAnswer": question.studentAnswer,
        "rules": [
            "1. Evaluate the student's answer to the Free Response Question and give it a score within the specified range",
            "2. Create a response which explains whether or not the student answered the question correctly. Compose your response as if you are talking to the student directly.",
            "3. Unless the student answers the question perfectly, explain to them what they missed or how they could have improved upon their answer. If they are completely incorrect, write out how they would have solved the problem.",
            "4. Format the 'explanation' in Markdown and KaTex. For example, use KaTex to represent math (expressions, equations, numerical values). Use Markdown to improve the style of the generated 'explanation'.",
            "5. Must *ONLY* output valid output as specified in output_format",
            "6. The score must be a integer between 0 and 5", //Replace 5 with field for specific FRQ question
        ],
        "format_rules": {
            "markdown": true,
            "mathKaTex": true
        },
        'output_format':
            `EXPLANATION:
            explanation

            SCORE:
            score`
    };

    const gradingPromptString = JSON.stringify(gradingPrompt);
    const gradingMessage = [{ role: 'system', content: gradingPromptString }];
    const response = await generateText(gradingMessage);

    const parsedResponse = parseResponse(response, [['EXPLANATION', 'explanation', 'string'], ['SCORE', 'score', 'integer']]);


    return parsedResponse;
}

export async function calculateGradePercentages(userID) {
    // Fetch the courses from Firestore
    const userDocRef = doc(firestoreInstance, `users/${userID}`);
    const userDocSnapshot = await getDoc(userDocRef);

    // If no document exists for this user, return default grade percentages
    if (!userDocSnapshot.exists()) {
        console.error(`No document found for user with ID: ${userID}`);
        return { 'A': 0, 'B': 0, 'C': 0, 'D': 0, 'F': 0 };
    }

    const courses = userDocSnapshot.data().courses;

    if (courses.length === 0) {
        return { 'A': 0, 'B': 0, 'C': 0, 'D': 0, 'F': 0 };
    }

    // Initialize grade counts
    let gradeCounts = { 'A': 0, 'B': 0, 'C': 0, 'D': 0, 'F': 0 };

    // Calculate grades for each course
    for (let course of courses) {
        let points = 0, total = 0;

        // Sum up all the points and totals for the course
        if (Array.isArray(course.grade)) { // Check if course.grade is an array
            for (let item of course.grade) {
                if (typeof item === 'object' && item !== null) { // Check if item is an object
                    for (let key in item) {
                        if (key.startsWith('total')) {
                            total += Number(item[key]) || 0; // Ensure item[key] is a number, default to 0 if not
                        } else {
                            points += Number(item[key]) || 0; // Ensure item[key] is a number, default to 0 if not
                        }
                    }
                }
            }
        }

        // Calculate the percentage and map to a grade
        let grade;
        if (total === 0) {
            grade = 'A';  // If no grades yet, assume 'A'
        } else {
            let percentage = (points / total) * 100;
            if (percentage >= 90) {
                grade = 'A';
            } else if (percentage >= 80) {
                grade = 'B';
            } else if (percentage >= 70) {
                grade = 'C';
            } else if (percentage >= 60) {
                grade = 'D';
            } else {
                grade = 'F';
            }
        }

        // Increment the count for this grade
        gradeCounts[grade]++;
    }

    // Calculate percentages for each grade
    let gradePercentages = {};
    for (let grade in gradeCounts) {
        gradePercentages[grade] = (gradeCounts[grade] / courses.length) * 100;
    }

    return gradePercentages;
}

export function parseResponse(response, variableNamesAndTypes) {
    const parsedResponse = {};

    for (let i = 0; i < variableNamesAndTypes.length; i++) {
        const parsedName = variableNamesAndTypes[i][0];
        const storedName = variableNamesAndTypes[i][1];
        const varType = variableNamesAndTypes[i][2];

        // Find the start and end of the variable's value in the response
        const varStart = response.indexOf(`${parsedName}:\n`) + `${parsedName}:\n`.length;
        const varEnd = (i < variableNamesAndTypes.length - 1) ? response.indexOf(`${variableNamesAndTypes[i + 1][0]}:`) : response.length;

        if (varStart === -1 || varEnd === -1) {
            throw new Error("Invalid response format for variable " + parsedName);
        }

        // Extract the variable's value and clean it up
        let value = cleanup(response.substring(varStart, varEnd).trim());

        // Convert the variable's value to the specified type
        if (varType === 'integer') {
            value = parseInt(value.replace(/\s/g, ''));
        } else if (varType === 'float') {
            value = parseFloat(value.replace(/\s/g, ''));
        }

        // Add the variable's value to the parsedResponse object under the specified key
        parsedResponse[storedName] = value;
    }

    return parsedResponse;
}

export function cleanup(response) {
    const cleanedString = response.replace(/\\\\/g, '\\');
    const strippedString = cleanedString.replace(/^[\t ]+/gm, '');
    return strippedString;
}