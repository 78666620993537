import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from './Button';
import { useNavigate } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import './Form.css';



export default function BasicTextFields({ title, setName, setPassword, setEmail, handleAction }) {
  const navigate = useNavigate();

  const handleSwitchForm = () => {
    if (title === 'Login') {
      navigate('/register');
    } else {
      navigate('/login');
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#ffffff',
        maxWidth: '400px',
        margin: '0 auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div className="heading-container">
        <h3 style={{ fontWeight: '600', fontSize: '40px', marginBottom: '30px' }}>
          <span style={{ color: '#4285f4' }}>
            edu<span style={{ color: '#ffa500' }}>.ai</span>
          </span>
        </h3>
      </div>

      <Box
        component="form"
        sx={{
          '& > :not(style)': { m: 1, width: '100%' },
        }}
        noValidate
        autoComplete="off"
      >
        <div className={`name-field ${title === 'Register' ? 'visible' : ''}`}>
          <TextField
            fullWidth
            id="name"
            label="Name"
            variant="outlined"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <TextField
          fullWidth
          id="email"
          label="Email"
          variant="outlined"
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          fullWidth
          id="password"
          label="Password"
          type="password"
          variant="outlined"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Box>


      <div style={{ marginTop: '20px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Button title={title} handleAction={handleAction} />
      </div>
    </div>
  );
}