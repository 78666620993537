import { getDoc, doc, setDoc, addDoc, collection, getFirestore } from "firebase/firestore";
import { firestoreInstance, authInstance } from './firebase';
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { savePrepopulatedLessons } from "./lessonService";
import { useNavigate } from "react-router-dom";

export async function getUserRole(uid) {
  try {
    const userDoc = await getDoc(doc(firestoreInstance, 'users', uid));

    if (!userDoc.exists()) {
      throw new Error('User not found in the database.');
    }

    const userData = userDoc.data();
    return userData.role;
  } catch (error) {
    console.error('Error fetching user role:', error);
    return null;
  }
}

export async function getUserName(uid) {
  try {
    const userDoc = await getDoc(doc(firestoreInstance, 'users', uid));

    if (!userDoc.exists()) {
      throw new Error('User not found in the database.');
    }

    const userData = userDoc.data();
    return userData.name;
  } catch (error) {
    console.error('Error fetching user role:', error);
    return null;
  }
}

export async function createUser(name, email, password, role) {
  try {
    if (name === '') {
      console.error("Name cannot be empty string");
      return;
    }
    await createUserWithEmailAndPassword(authInstance, email, password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        const userData = {
          name: name,
          email: user.email,
          role: role,
          courses: [
          ],
          createdAt: new Date().getTime()
        };
        await setDoc(doc(firestoreInstance, "users", user.uid), userData);
        //console.log("User added with ID: ", user.uid);
        //await savePrepopulatedLessons(user.uid);
        //console.log('User and lessons created successfully');
        window.location.assign('/student-platform'); //Maybe Bad
      })
      .catch((error) => {
        console.error("Error creating user: ", error);
      });
  } catch (error) {
    console.error('Error creating user and lessons: ', error);
  }
}

export async function loginUser(authentication, email, password) {
  try {
    await signInWithEmailAndPassword(authentication, email, password)
      .then(() => {
        //console.log('User logged in successfully');
        window.location.assign('/student-platform'); //Maybe Bad
      })
      .catch((error) => {
        console.error('Error logging in the user: ', error);
      });
  } catch (error) {
    console.error('Error logging in the user: ', error);
  }
}
