import { faThList } from '@fortawesome/free-solid-svg-icons';
import { onSubtopicsScoreChange, updateCheckpoints } from '../../services/lessonService';
import TaskArbiter from './TaskArbiter';

class EvaluationEngine {
  constructor(userId, subject, lessonId, lessonTitle, aiCore, onResponseGenerated) {
    this.userId = userId;
    this.subject = subject;
    this.lessonId = lessonId;
    this.lessonTitle = lessonTitle;
    this.taskArbiter = new TaskArbiter();
    this.debounceTimer = null;
    this.shouldUpdateSubtopicScore = true;
    this.aiCore = aiCore;
    this.previousNextAction = null;
    this.responseGeneratedListeners = [];
    this.onResponseGenerated = onResponseGenerated;
  }
  
  setShouldUpdateSubtopicScore(shouldUpdate) {
    this.shouldUpdateSubtopicScore = shouldUpdate;
  }

  getShouldUpdateSubtopicScore() {
    return this.shouldUpdateSubtopicScore;
  }

  async updateThresholds(dialogueUnderstandingScore, checkpoints) {
    // Logic to update the thresholds based on user input and/or dialogue understanding score
    // For example, you might increase the thresholds if the user is struggling:
    const newThresholds = checkpoints.map((checkpoint) => {
      if (dialogueUnderstandingScore < checkpoint.threshold) {
        return { ...checkpoint, threshold: checkpoint.threshold + 0.1 };
      }
      return checkpoint;
    });
    return newThresholds;
  }

  debounceOnSubtopicsScoreChange() {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(async () => {
      // ... your logic that should be executed after the debounce delay
      const { subtopics, checkpoints, currentSubtopicIndex: changedIndex } = this.lessonData;

    }, 10000);
  }

  getTeacherResponseFlag() {
    const allowed = localStorage.getItem('allowTeacherResponse');
    return allowed === 'false' || allowed === null; // Default to false if not set
  }
  
  async onSubtopicsScoreChange(lessonData) {

    ////console.log("onSubtopicsScoreChange call ", this.userId, this.lessonId);
    //If a question is proposed (MCQ/FRQ), we don't want the AI to interrupt the user
    //if (!this.getShouldUpdateSubtopicScore()) {
      //return;
    //}

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    const changedIndex = lessonData.currentSubtopicIndex;
    const subtopics = lessonData.subtopics;  
    // Set up a new debounce timer
    this.debounceTimer = setTimeout(async () => {
        const checkpoints = lessonData.checkpoints;

        //TODO: Update the thresholds
        //const newThresholds = await this.updateThresholds( dialogueUnderstandingScore, this.userId, this.subject, this.lessonId, checkpoints);
        // Inside the onLessonScoreChange event listener, after updating the thresholds
        //await updateCheckpoints(newThresholds);
        // Call the execute method of the TaskArbiter
        const nextAction = await this.taskArbiter.execute(
          this.userId,
          this.subject,
          this.lessonId,
          this.lessonTitle,
          subtopics,
          changedIndex,
          checkpoints
        );
        //console.log("EvaluationEngine onLessonScoreChange() next action: ", nextAction);
        //the getresponse from eval engine is by default not called unless teacher response doesn't end with a question
        //can put flag in local storage, check if it's set to allow or not.
        //So, if (newTask or flag) call aiCore.getResponse
        //Maybe have to store previous action in database because it can be null if clicking off lesson and reclicking to create a new eval engine
        //To prevent from saying duplicate things, either have to make subtopics more granular, or store the content in each subtopic
        let notOff = false;
        if (nextAction && nextAction.action === 'moveToNextSubtopic' && notOff) {
          ////console.log("PREV action ", this.previousNextAction);
          //console.log("NEW action ", nextAction);
          this.previousNextAction = nextAction.action;

          // Call the new method in AiCore
          const responseStream = await this.aiCore.getTeacherResponseAction(
            this.userId,
            this.subject,
            this.lessonId,
            this.lessonTitle,
            nextAction
        );
        localStorage.setItem("allowTeacherResponse" + this.lessonId, false);
        // Call onResponseGenerated with the generated response
        this.onResponseGenerated(this.userId, this.subject, this.lessonId, responseStream);
      }
    }, 10000); // Adjust the delay (in milliseconds) as needed
  }

  subscribe(callback) {
    return onSubtopicsScoreChange(this.userId, this.subject, this.lessonId, callback);
  }

  /*subscribeToResponseGenerated(callback) {
    this.responseGeneratedListeners.push(callback);
    return () => {
      this.responseGeneratedListeners = this.responseGeneratedListeners.filter(
        (listener) => listener !== callback
      );
    };
  }

  triggerResponseGenerated(responseText) {
    for (const listener of this.responseGeneratedListeners) {
      listener(responseText);
    }
  }*/
  
}

export default EvaluationEngine;