import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authInstance } from '../../../services/firebase';
import styles from './Navbar.module.css';


function Navbar() {
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = authInstance.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = async () => {
    try {
      await authInstance.signOut();
      setUser(null);
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };
  ///student-dashboard for teacher somewhere in here
  return (
    <div className={styles.navbar}>
      <div className={styles.logoContainer}>
        <h1 className={styles.logo}>edu<span className={styles["logo-accent"]}>.ai</span></h1>
        <span className={styles.alphaLabel}>ALPHA</span>
      </div>
      <nav>
        <ul>
          <li>
            <Link to="/student-platform">Student Dashboard</Link>
          </li>
          <li>
            <Link to="/student-platform/assignments">Assignments</Link>
          </li>
          {user && (
            <>
              <li>
                <span>{user.email}</span>
              </li>
              <li>
                <button onClick={handleLogout}>Logout</button>
              </li>
            </>
          )}
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;