import React, { useState, useEffect } from 'react';
import styles from './AiEdPlatform.module.css';
import CoursePage from './CoursePage';
import Courses from '../shared/SideNav/Courses';
import Assignments from '../shared/SideNav/Assignments';
import Exams from '../shared/SideNav/Exams';
import Grades from '../shared/SideNav/Grades';
import Account from '../shared/SideNav/Account';
import Billing from '../shared/SideNav/Billing';
import Settings from '../shared/SideNav/Settings';
import { authInstance, firestoreInstance } from '../../services/firebase';
import UserContext from '../shared/SideNav/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faCreditCard, faCog, faBook, faFile, faAppleAlt, faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { Navigate, Routes, Route, Link, NavLink, useLocation } from 'react-router-dom';
import { fetchAllGradeables } from '../../services/gradingService';
import { collection, onSnapshot } from 'firebase/firestore';


function AiEdPlatform() {
  const [incompleteAssignmentExists, setIncompleteAssignmentExists] = useState(false);
  const userId = authInstance.currentUser.uid;
  const location = useLocation();

  const [developerMode, setDeveloperMode] = useState(
    JSON.parse(localStorage.getItem("devMode")) || false
  );

  useEffect(() => {
    const assignmentsRef = collection(firestoreInstance, `users/${userId}/Assignments`);
    const unsubscribe = onSnapshot(assignmentsRef, (snapshot) => {
      const userAssignments = snapshot.docs.map(doc => doc.data());
      //console.log("ASSIGNMENTS ", userAssignments);
      const incompleteExists = userAssignments.some(assignment => !(assignment.status === "Graded"));
      setIncompleteAssignmentExists(incompleteExists);
    });
    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [userId]);

  /*useEffect(() => {
    // This function gets called whenever developerMode changes
    localStorage.setItem("devMode", JSON.stringify(developerMode));
  }, [developerMode]);*/

  const toggleDeveloperMode = () => {
    //console.log("devMode toggled: ", !developerMode);
    localStorage.setItem("devMode", JSON.stringify(!developerMode));
    setDeveloperMode(!developerMode);
  }

  return (
    <UserContext.Provider value={{ userId, developerMode, toggleDeveloperMode }}>
      <div className={styles.wrapper}>
        <nav className={styles.sidebar}>
          <ul>
            <li>
              <NavLink to="courses"
                className={location.pathname.includes("/courses") ? styles['selected-link'] : styles.link}>
                <FontAwesomeIcon icon={faBook} />
                <span>Courses</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="assignments"
                className={location.pathname.includes("/assignments") ? styles['selected-link'] : styles.link}>
                <FontAwesomeIcon icon={faFile} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Assignments</span>
                  {incompleteAssignmentExists && <div className={styles.redDot}></div>}
                </div>
              </NavLink>
            </li>
            <li>
              <NavLink to="grades"
                className={location.pathname.includes("/grades") ? styles['selected-link'] : styles.link}>
                <FontAwesomeIcon icon={faAppleAlt} />
                <span>Grades</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="myAccount"
                className={location.pathname.includes("/myAccount") ? styles['selected-link'] : styles.link}>
                <FontAwesomeIcon icon={faUser} />
                <span>My Account</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="billing"
                className={location.pathname.includes("/billing") ? styles['selected-link'] : styles.link}>
                <FontAwesomeIcon icon={faCreditCard} />
                <span>Billing</span>
              </NavLink>
            </li>
            <li>
              <NavLink to="settings"
                className={location.pathname.includes("/settings") ? styles['selected-link'] : styles.link}>
                <FontAwesomeIcon icon={faCog} />
                <span>Settings</span>
              </NavLink>
            </li>
            <ul>
            </ul>
          </ul>
        </nav>
        <div className={styles['nav-selection']}>
          <Routes>
            <Route path="/" element={<Navigate to="courses" />} />
            <Route path="courses" element={<Courses />} />
            <Route path="assignments/*" element={<Assignments />} />
            <Route path="exams/*" element={<Exams />} />
            <Route path="grades/*" element={<Grades />} />
            <Route path="myAccount" element={<Account />} />
            <Route path="billing" element={<Billing />} />
            <Route path="settings" element={<Settings />} />
          </Routes>
        </div>
      </div>
    </UserContext.Provider>
  );
}

export default AiEdPlatform;