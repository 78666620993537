import React, { useState, useEffect, useRef } from 'react';
import styles from './Grades.module.css';
import OpeningCardImage from '../../ui/assets/gradesCartoon.png';
import { Card, makeStyles } from '@mui/material';
import { styled } from '@mui/system';
import createCourseImage from '../../ui/assets/createCourse.PNG';
import { authInstance, firestoreInstance } from '../../../services/firebase';
import mathImage from '../../ui/assets/math.jpg'
import scienceImage from '../../ui/assets/science.jpg';
import defaultImage from '../../ui/assets/defaultImage.png';
import { fetchUserCourses } from '../../../services/courseService';
import { doc, onSnapshot } from '@firebase/firestore';
import { getUserName } from '../../../services/userService';
import { useNavigate, useRouteMatch, Routes, Route } from 'react-router-dom';
import { calculateGradePercentages, fetchAllGradeables } from '../../../services/gradingService';
import GradeDetails from './GradeDetails';
import GradeDetailContext from './GradeDetailContext';


function Grades() {
    const [courseList, setCourseList] = useState([]);
    const [userName, setUserName] = useState('User');
    const [gradePercentages, setGradePercentages] = useState({ 'A': 0, 'B': 0, 'C': 0, 'D': 0, 'F': 0 });
    const [gradeables, setGradeables] = useState([]);
    const [gradeablesForCourse, setGradeablesForCourse] = useState([]);
    const navigate = useNavigate();
    const userId = authInstance.currentUser.uid;

    useEffect(() => {
        const fetchUserName = async () => {
            const name = await getUserName(userId);
            setUserName(name);
        }

        fetchUserName();
    }, [userId]);

    useEffect(() => {
        const fetchAndCalculatePercentages = async () => {
            const percentages = await calculateGradePercentages(userId);
            //console.log(percentages);
            setGradePercentages(percentages);
        };

        fetchAndCalculatePercentages();
    }, []);


    useEffect(() => {
        const fetchCourses = async () => {
            const userCourses = await fetchUserCourses(userId); // Modify this function to return course objects
            const courseArray = userCourses.map(course => ({
                name: course.course, // Use the 'course' property of each course object
                image: getCourseImage(course), // Use the 'imageUrl' property, or default images if 'imageUrl' is not available
                grade: course.grade,
                creating: false
            }));
            setCourseList(courseArray);
        };
        //console.log(userName);
        fetchCourses();
    }, [userId]);


    useEffect(() => {
        const fetchGradeables = async () => {
            const assignmentsList = await fetchAllGradeables(userId, "Assignments");
            const quizzesList = await fetchAllGradeables(userId, "Quizzes");
            const examsList = await fetchAllGradeables(userId, "Exams");

            // Add type field and combine assignments, quizzes, and exams into a single array
            const combinedList = [
                ...assignmentsList.map(item => ({ ...item, type: 'Assignment' })),
                ...quizzesList.map(item => ({ ...item, type: 'Quiz' })),
                ...examsList.map(item => ({ ...item, type: 'Exam' })),
            ];
            //console.log(combinedList);
            // Update the state with the combined list
            setGradeables(combinedList);
        };

        fetchGradeables();
    }, []);

    const filterByCourse = (gradeables, courseName) => {
        return gradeables.filter(gradeable => gradeable.course === courseName);
    };

    function handleCourseGradeClick(courseName) {
        // Replace this with the actual code to navigate to the course page
        const filteredGradeables = filterByCourse(gradeables, courseName);
        setGradeablesForCourse(filteredGradeables);
        //console.log(`Navigating to page for course: ${courseName}`);
        const encodedCourseName = encodeURIComponent(courseName);
        navigate(`${encodedCourseName}`);
    }

    function getCourseImage(course) {
        // If the course has an image URL, use it
        if (course.imageUrl) {
          if (course.imageUrl === "../components/ui/assets/defaultImage.png") {
            return defaultImage;
          }
          else {
            return course.imageUrl;
          }
        }
    
        // Otherwise, return a default image based on the course name
        switch (course.course) {
          case 'Math':
            return mathImage;  // replace with the URL or path of your default Math image
          case 'Science':
            return scienceImage;  // replace with the URL or path of your default Science image
          default:
            return defaultImage;  // replace with the URL or path of your default image for other courses
        }
    }

    function interpolateColor(color1, color2, factor) {
        let result = [];
        for (let i in color1) {
            result.push(Math.round(color1[i] + factor * (color2[i] - color1[i])));
        }
        return result;
    }

    function interpolateColors(color1, color2, steps) {
        let stepFactor = 1 / (steps - 1),
            interpolatedColorArray = [];

        for (let i = 0; i < steps; i++) {
            interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
        }

        return interpolatedColorArray;
    }

    /*let colors = [
        { r: 30, g: 144, b: 255 },
        { r: 214, g: 112, b: 240 },
        { r: 255, g: 165, b: 0 }
    ];*/

    let colors = [
        { r: 255, g: 255, b: 255 },
        { r: 255, g: 255, b: 255 },
        { r: 255, g: 255, b: 255 }
    ];

    let colorArray = [];

    for (let i = 0; i < colors.length - 1; i++) {
        let tempArray = interpolateColors(colors[i], colors[i + 1], Math.ceil(courseList.length / (colors.length - 1)));
        colorArray = [...colorArray, ...tempArray];
    }

    colorArray = colorArray.slice(0, courseList.length); // Make sure the array is not longer than needed

    return (
        <GradeDetailContext.Provider value={gradeablesForCourse}>
            <div className={styles['cards-container']}>
                <Routes>
                    <Route path="/" element={
                        <>
                            <div className={styles['opening-card']} elevation={3}>
                                <div className={styles['opening-card-content']}>
                                    {/* New text fields */}
                                    <div className={styles['opening-header']}>
                                        Hello {userName}!
                                    </div>
                                    <div className={styles['opening-description']}>
                                        Welcome to your personal dashboard. Here, you'll find all the relevant information about your academic performance, including the grades of your recent courses. Let's see how you've been doing!
                                    </div>
                                </div>
                                <img className={styles['opening-card-image']} src={OpeningCardImage} />
                            </div>
                            <div className={styles['container']}>
                                <div className={styles['courses-card']} elevation={3}>
                                    <h2 className={styles['courses-title']}>Courses</h2>
                                    <div className={styles['courses-content']}>
                                        {/* Courses information */}
                                        {courseList.map((course, index) => (
                                            <Card
                                                key={course.name}
                                                className={styles['course-card']}
                                                onClick={gradeables.length > 0 ? () => handleCourseGradeClick(course.name) : null}
                                                style={{
                                                    background: `rgba(${colorArray[index]}, 1)`,
                                                }}
                                            >
                                                <div className={styles['course-name']} variant="h5" component="h2">
                                                    {course.name}
                                                </div>
                                                <img src={course.image} alt={course.name} className={styles['course-img']} />
                                            </Card>
                                        ))}
                                    </div>
                                </div>
                                <div className={styles['grades-card']} elevation={3}>
                                    <h2 className={styles['card-title']}>Grades</h2>
                                    {/* Grades information */}
                                    {Object.entries(gradePercentages).map(([grade, percentage]) => {
                                        let emoji;
                                        switch (grade) {
                                            case "A":
                                                emoji = "👑"; // King's Crown for grade A
                                                break;
                                            case "B":
                                                emoji = "🎓"; // Rocket for grade B
                                                break;
                                            case "C":
                                                emoji = "📚"; // Tree for grade C
                                                break;
                                            case "D":
                                                emoji = "😕"; // Confused Face for grade D
                                                break;
                                            case "F":
                                                emoji = "💔"; // Broken Heart for grade F
                                                break;
                                            default:
                                                emoji = grade; // render grade as is if it does not match any known values
                                        }

                                        return (
                                            <div key={grade} className={styles['grade-row']}>
                                                <div className={`${styles['grade-label']} ${styles[`grade-${grade}`]}`}>{emoji}</div>
                                                <div className={styles['grade-bar-container']}>
                                                    <div
                                                        className={`${styles['grades-bar']} ${styles[`bar-${grade}`]}`}
                                                        style={{ width: `${gradePercentages[grade]}%` }}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </>
                    } />
                    <Route path=":courseName" element={<GradeDetails courseList={courseList} />} />
                </Routes>
            </div>
        </GradeDetailContext.Provider>
    );
}

export default Grades;
