import React, { useEffect, useState } from 'react';
import styles from './InLessonModalContent.module.css';
import QuestionItem from './assignments/QuestionItem'; // Import the QuestionItem component if it's in a different file
import { markGradeableAsCompleted, gradeGradeable, saveGradeable } from '../../services/gradingService';
import { CircularProgress } from '@mui/material';
import eventBus from '../shared/eventBus';

const InLessonModalContent = React.forwardRef(({ type, info1, info2, questions, completed, quiz, userID }, ref) => {
  
  // State to track selected options
  const [selectedOptions, setSelectedOptions] = useState([]);
  // State to track if the submission is in progress
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    //console.log('completed changed:', completed);
  }, [completed]);

  const handleOptionSelected = (index, option) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions[index] = option;
    setSelectedOptions(updatedOptions);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);

    const quizCopy = JSON.parse(JSON.stringify(quiz));

    quizCopy.questions.forEach((question, index) => {
      question.studentAnswer = selectedOptions[index] || null;
    });

    await saveGradeable(userID, quiz.quizId, quizCopy, "Quizzes");
    const gradedQuestions = await gradeGradeable(userID, quiz.quizId, quizCopy, "Quizzes");
    await markGradeableAsCompleted(userID, quiz.quizId, quizCopy, "Quizzes");

    setTimeout(() => {
      setIsSubmitting(false);
    }, 2000);

    eventBus.emit('quizTimeDone', { userID, quiz, gradedQuestions });
  };

  return (
    <div className={styles['modal-content-card']} ref={ref}>
      {isSubmitting ? (
        // Display spinner and status text while submitting
        <div className={styles['loading-container']}>
          <CircularProgress size={20}/>
          <div className={styles['loading-status']}>Grading...</div>
        </div>
      ) : (
        // Display the quiz content when not submitting
        <>
          <h4>{type}</h4>
          <p>{info1}</p>
          <p>{info2}</p>
          {
            type === 'QUIZ' && questions && questions.length > 0 &&
            <div>
              <div>
                {questions.map((question, index) => (
                  <div key={index} className={styles['block']}>
                    <QuestionItem
                      question={question}
                      index={index}
                      onOptionSelected={handleOptionSelected}
                      completed={completed}
                    />
                  </div>
                ))}
              </div>
              <div style={{ textAlign: 'center' }}>
                {!completed && <button className={styles['submit-button']} onClick={handleSubmit}>Submit</button>}
              </div>
            </div>
          }
        </>
      )}
    </div>
  );
});

export default InLessonModalContent;