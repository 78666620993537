import ContextManager from "./ContextManager/ContextManager";
import ResponseGenerator from "./ResponseGenerator/ResponseGenerator";
import { generateText, generateStream } from '../services/openai';  //temp for demo
import ContextStorage from "./ContextManager/ContextStorage";
import ResponseProcessor from './ContentRecommender/ResponseProcessor';
import { saveLessonPlanToSubtopic, getCurrentSubtopicIndex, 
  updateSubtopicScore, fetchImagesBySubtopic, getCurrentSubtopic,
  updateCurrentSubtopicIndex, getCurrentSubtopicScore, getNextSubtopic, getFormattedSubtopicNames, quizExistsInSetIfNotAdd} from "../services/lessonService";
import { EventEmitter } from 'events';
import { getUserName } from "../services/userService";
import { createAssignment } from "./CurriculumPlanner/AssignmentGenerator";
import eventBus from "../components/shared/eventBus";
import { createQuiz } from "./CurriculumPlanner/QuizGenerator";
import { fetchQuizByTitle } from "../services/gradingService";
import { getCourseByName } from "../services/courseService";

class AiCore {
    constructor() {
      this.contextManager = new ContextManager();
      this.contextStorage = new ContextStorage();
      this.responseGenerator = new ResponseGenerator();
      //this.responseProcessor = new ResponseProcessor();

      // Create an event emitter
      this.eventEmitter = new EventEmitter();

      // Load data from local storage at startup
      const storedData = localStorage.getItem('createdQuizzesSubtopics');
      this.createdQuizzesSubtopics = storedData ? new Set(JSON.parse(storedData)) : new Set();
      
      // Define the event listener for stream completion
      this.eventEmitter.on('streamComplete', async (userId, studentInput, teacherResponse, subject, lessonId, 
        lessonTitle, curSubtopicName, curSubtopicIndex, curLessonPlan, nextSubtopic) => {
        
        const lessonPlan = this.coverLessonTopic(curLessonPlan, teacherResponse);
        let coveredCount = this.amountCovered(curLessonPlan);
        const quizObject = await fetchQuizByTitle(userId, subject, curSubtopicName);
        //console.log("Quiz Object: ", quizObject);
        //console.log("AMOUNT COVERED: ", coveredCount);

        if (curLessonPlan && !this.createdQuizzesSubtopics.has(curSubtopicName)) {
          // Store the subtopic name in the Set. Set is currently used to reduce calls to quizExistsInSetIfNotAdd()
          this.createdQuizzesSubtopics.add(curSubtopicName);
          let quizExisted = await quizExistsInSetIfNotAdd(userId, subject, lessonId, curSubtopicIndex, curSubtopicName);
          if (!quizExisted) {
            //console.log("Creating Quiz for: ", curSubtopicName);
            createQuiz(userId, subject, lessonId, lessonTitle, curLessonPlan);
          }
        }
        if ((coveredCount === 2 || curLessonPlan && this.lessonTopicCompleted(curLessonPlan)) && this.isQuizValidAndNotCompleted(quizObject)) {
          eventBus.emit('quizTime', { userId, subject, lessonId, curSubtopicIndex });
          teacherResponse = teacherResponse + "\n[Modal: [QUIZ] " + curSubtopicName + " | Let's see what you've got!]";
        }
        else if (curLessonPlan && this.lessonTopicCompleted(curLessonPlan) && nextSubtopic !== null && this.isQuizValidAndCompleted(quizObject)) {
          //ask if student has any more questions
          //console.log("ASSIGNMENT WOULD BE GENERATED");
          createAssignment(userId, subject, lessonTitle, lessonId);
          //move to next subtopic index
          //Update subtopic score to 100
          await updateSubtopicScore(userId, subject, lessonId, curSubtopicIndex, 100);
          await updateCurrentSubtopicIndex(userId, subject, lessonId, curSubtopicIndex + 1);
          const newSubtopic = await getCurrentSubtopic(userId, subject, lessonId);
          //const newImages = await fetchImagesBySubtopic(userId, subject, lessonId, lessonTitle);
          // Emit a custom event to notify that the lesson topic is completed
          eventBus.emit('lessonTopicCompleted', { userId, subject, lessonId, curSubtopicIndex });
          teacherResponse = teacherResponse + "\n[Modal: [" + newSubtopic.name + "] You've reached a new subtopic | An assignment for the previous one has been made.]";
        }
        else if (nextSubtopic !== null) {
          const progressValue = this.calculateProgress(curLessonPlan);
          //console.log("Current progress value: ", progressValue);
          const currentSubtopicIndex = await getCurrentSubtopicIndex(userId, subject, lessonId);
          await updateSubtopicScore(userId, subject, lessonId, currentSubtopicIndex, progressValue);
        }
        await this.contextManager.setContext(userId, studentInput, teacherResponse, subject, lessonId, lessonTitle);
        await this.contextManager.setContent(userId, studentInput, teacherResponse, subject, lessonId, lessonTitle);
        if (lessonPlan !== null) {
          await saveLessonPlanToSubtopic(userId, subject, lessonId, curSubtopicIndex, lessonPlan);
        }
        eventBus.emit('lessonInfoUpdated', { userId, subject, lessonId });
      });

      this.eventEmitter.on('feedbackStreamComplete', async (userId, studentInput, teacherResponse, subject, lessonId, lessonTitle) => {
        //console.log("Quiz feedback complete for lesson: ", lessonTitle);
        await this.contextManager.setContext(userId, studentInput, teacherResponse, subject, lessonId, lessonTitle);
        await this.contextManager.setContent(userId, studentInput, teacherResponse, subject, lessonId, lessonTitle);
        eventBus.emit('lessonInfoUpdated', { userId, subject, lessonId });
      });

      this.eventEmitter.on('actionStreamComplete', async (userId, studentInput, teacherResponse, subject, lessonId, lessonTitle, curSubtopicIndex) => {
        await this.contextManager.setContext(userId, studentInput, teacherResponse, subject, lessonId, lessonTitle);
        await this.contextManager.setContent(userId, studentInput, teacherResponse, subject, lessonId, lessonTitle);
      });
    }

    isQuizValidAndNotCompleted(quizObject) {
      return quizObject && quizObject.length > 0 && !quizObject[0].completed;
    };

    isQuizValidAndCompleted(quizObject) {
      return quizObject && quizObject.length > 0 && quizObject[0].completed;
    };

    amountCovered(lessonPlan) {
      // Check if lessonPlan is defined
      if (lessonPlan) {
          let lessons = lessonPlan.lessonPlan ? lessonPlan.lessonPlan : lessonPlan;
          // Count how many topics are covered
          let coveredCount = 0;
  
          // Check if lessons is an array
          if (Array.isArray(lessons)) {
              for (let i = 0; i < lessons.length; i++) {
                  if (lessons[i].covered) {
                      coveredCount++;
                  }
              }
          } else {
              console.error('lessons is not an array');
          }
          
          return coveredCount;
      } else {
          console.error('lessonPlan is undefined');
          return 0; // or handle this case differently based on requirements
      }
    }
  

    coverLessonTopic(lessonPlan, string) {
      //console.log("LESSONPLANCOVER", JSON.stringify(lessonPlan, null, 3));
  
      let lessons;
  
      try {
          // First, attempt to use lessonPlan.lessonPlan
          lessons = lessonPlan.lessonPlan;
          this.processLessons(lessons, string);
      } catch (err) {
          // If that fails, fall back to lessonPlan
          try {
              lessons = lessonPlan;
              this.processLessons(lessons, string);
          } catch (err) {
              console.error("Unable to iterate over lessonPlan:", err);
              return null;
          }
      }
  
      //console.log("MODIFIED LESSONPLAN", JSON.stringify(lessonPlan, null, 3));
      return lessonPlan;
    }
  
    processLessons(lessons, string) {
      for(let lesson of lessons) {
          if(string.includes(lesson.topic)) {
            lesson.covered = true;
          }
      }
        return lessons;
    }
  
  
    //Check if lesson plan is completed
    lessonTopicCompleted(lessonPlan) {
      try {
          let lessons = lessonPlan.lessonPlan ? lessonPlan.lessonPlan : lessonPlan;
  
          if (Array.isArray(lessons) && lessons.length > 0) {
              return lessons[lessons.length - 1].covered === true;
          }
      } catch (err) {
          console.error("Error while checking if lesson topic is completed:", err);
      }
      return false;
    }
  
    
    calculateProgress(lessonPlan, maxPoints=100, scaleFactor=50) {
      let totalTopics = 0;
      let coveredTopics = 0;
      
      try {
          for(let lesson of lessonPlan.lessonPlan) {
              totalTopics++;
              if(lesson.covered) {
                  coveredTopics++;
              }
          }
      } catch(err) {
          try {
              for(let lesson of lessonPlan) {
                  totalTopics++;
                  if(lesson.covered) {
                      coveredTopics++;
                  }
              }
          } catch (err) {
              console.error("Unable to iterate over lessonPlan:", err);
          }
      }
  
      if(coveredTopics === totalTopics && totalTopics > 0) {
          // If all topics are covered, return maxPoints
          return maxPoints;
      }
      
      // Ensure scaleFactor is non-negative
      scaleFactor = Math.max(scaleFactor, 0);

      // Calculating the logarithmic progress
      let progress = scaleFactor * Math.log(coveredTopics + 1);

      // Ensuring that progress is non-negative and does not exceed maxPoints
      return Math.min(Math.max(progress, 0), maxPoints - 1);

    }

    async getTeacherResponse(userId, studentInput, subject, lessonId, lessonTitle) {
        const courseObject = await getCourseByName(userId, subject);
        //console.log("COURSE OBJECT: ", courseObject);
        const depth = courseObject.depthLevel ? courseObject.depth : "Undergraduate";
        const teachingStyle = courseObject.teachingStyle ? courseObject.teachingStyle : "Socratic";
        const speakingStyle = courseObject.speakingStyle ? courseObject.speakingStyle : "Please speak as if you were teaching a undergraduate student.";

        const pineContext = await this.contextManager.getSimilarContext(userId, studentInput, lessonTitle);
        const recentDialogueObjects = await this.contextStorage.getRecentDialogueObjects(userId, subject, lessonId);
        ////console.log("AiCore.getTeacherResponse() studentInput: ", studentInput);
        ////console.log("AiCore.getTeacherResponse() pineContext: ", pineContext);
        ////console.log("AiCore.getTeacherResponse() recentDialogue: ", recentDialogue);
        //probably get currentSubtopicIndex here and pass current subtopic to generateResponse
        const images = await fetchImagesBySubtopic(userId, subject, lessonId, lessonTitle);
        //const formattedImages = images.map((image, index) => `Image ${index}: ${image.description}`).join('\n');
        //console.log("IMAGE DESCRIPTIONS: ", images);
        const curSubtopic = await getCurrentSubtopic(userId, subject, lessonId);  
        const curSubtopicName = curSubtopic.name;
        const curLessonPlan = curSubtopic.lessonPlan;
        //console.log("CURLESSONPLAN", curLessonPlan);

        //Parse lesson plan
        //const topics = curLessonPlan.map((item, index) => `${index + 1}. ${item.topic}`).join(', ');
        //const prevLessonPlanTopics = `The lesson plan includes the following topics: ${topics}.`;
        const allSubtopics = await getFormattedSubtopicNames(userId, subject, lessonId);
        //console.log("ALL SUBTOPICS: ", allSubtopics);
        const curSubtopicIndex = await getCurrentSubtopicIndex(userId, subject, lessonId);


        if (!curSubtopic.lessonPlan) {
          // Start generating the lesson plan and saving it to Firestore in a separate asynchronous function
          (async () => {
              const lessonPlanJson = await this.generateLessonPlanJSON(lessonTitle, curSubtopicName, allSubtopics, depth);
              //console.log("LESSONPLANJSON", JSON.stringify(lessonPlanJson, null, 3));
              await saveLessonPlanToSubtopic(userId, subject, lessonId, curSubtopicIndex, lessonPlanJson.lessonPlan);
          })();
        }

        const nextSubtopic = await getNextSubtopic(userId, subject, lessonId);
        if (nextSubtopic !== null  && !nextSubtopic.lessonPlan) {
          // Start generating the lesson plan and saving it to Firestore in a separate asynchronous function
          (async () => {
            //console.log("NEXT SUBTOPIC", nextSubtopic.name);
            const nextLessonPlanJson = await this.generateLessonPlanJSON(lessonTitle, nextSubtopic.name, allSubtopics, depth);
            //console.log("NEXTLESSONPLANJSON", JSON.stringify(nextLessonPlanJson, null, 3));
            //console.log("NEXT SUBTOPIC", nextSubtopic.name);
            await saveLessonPlanToSubtopic(userId, subject, lessonId, curSubtopicIndex + 1, nextLessonPlanJson.lessonPlan);
          })();
        }
        else if (!nextSubtopic){
          //TODO: Put modal on screen
        }

        const teacherResponseStream = await this.responseGenerator.generateResponse(userId, 
                                                                                    subject, 
                                                                                    lessonId, 
                                                                                    lessonTitle, 
                                                                                    curSubtopicName, 
                                                                                    curSubtopicIndex, 
                                                                                    studentInput, 
                                                                                    recentDialogueObjects, 
                                                                                    pineContext, 
                                                                                    images,
                                                                                    depth,
                                                                                    teachingStyle,
                                                                                    speakingStyle,
                                                                                    curLessonPlan);

        let teacherResponse = '';

        const reader = teacherResponseStream.getReader();
        const decoder = new TextDecoder("utf-8");

        let that = this;

        // Create a new stream that will be returned
        const returnStream = new ReadableStream({
          async start(controller) {
            while (true) {
              const { done, value } = await reader.read();

              if (done) {
                // Emit the streamComplete event
                that.eventEmitter.emit('streamComplete', userId, studentInput, teacherResponse, subject, lessonId, lessonTitle, 
                                                                                            curSubtopicName, curSubtopicIndex, curLessonPlan, nextSubtopic, courseObject);
                break;
              }

              if (value) {
                const chunk = decoder.decode(value, {stream: true});
                teacherResponse += chunk;
                ////console.log("TEACHER RESP STREAM: ", teacherResponse);
                controller.enqueue(value);
              }
            }
            controller.close();
          }
        });
        //const baseScore = 20;
        //let questionScore = 0;
        return returnStream;
        // Parse scores and pass to evaluation engine
        /*const regex = /\[QS:\s(\d{1,3})\]/;
        const match = teacherResponse.match(regex);
        const baseScore = 10;
        let questionScore = 0;
      
        if (match) {
          questionScore = parseInt(match[1], 10);
          //console.log("Question Score:", questionScore);
        } else {
          //console.log("Question score not found in the text.");
        }
        if (!teacherResponse.endsWith('?') && !teacherResponse.includes('[MCQ]') && !teacherResponse.includes('[FRQ]')) {
          localStorage.setItem("allowTeacherResponse" + lessonId, true);
          //console.log("AiCore LOCAL STORAGE SET");
        }
        else {
          localStorage.setItem("allowTeacherResponse" + lessonId, false);
        }
        const currentSubtopicIndex = await getCurrentSubtopicIndex(userId, subject, lessonId);
        await updateSubtopicScore(userId, subject, lessonId, currentSubtopicIndex, baseScore + questionScore);
        await this.contextManager.setContext(userId, studentInput, teacherResponse, subject, lessonId, lessonTitle);
        return teacherResponse;*/
    }

    async getTeacherResponseAction(userId, subject, lessonId, lessonTitle, nextAction) {
      const courseObject = await getCourseByName(userId, subject);
      const depth = courseObject.depthLevel ? courseObject.depth : "Undergraduate";
      const teachingStyle = courseObject.teachingStyle ? courseObject.teachingStyle : "Socratic";

      const pineContext = await this.contextManager.getSimilarContext(userId, nextAction, lessonTitle);
      const recentDialogueObjects = await this.contextStorage.getRecentDialogueObjects(userId, subject, lessonId);

      const images = await fetchImagesBySubtopic(userId, subject, lessonId, lessonTitle);
      const curSubtopic = await getCurrentSubtopic(userId, subject, lessonId);
      const allSubtopics = await getFormattedSubtopicNames(userId, subject, lessonId);

      if (!curSubtopic.lessonPlan) {
        // Start generating the lesson plan and saving it to Firestore in a separate asynchronous function
        (async () => {
            const lessonPlanJson = await this.generateLessonPlanJSON(lessonTitle, curSubtopicName, allSubtopics, depth);
            let lessonPlanString = JSON.stringify(lessonPlanJson);
            //console.log("LESSONPLANJSON", lessonPlanJson);
            await saveLessonPlanToSubtopic(userId, subject, lessonId, curSubtopicIndex, lessonPlanString);
        })();
      }

      const curSubtopicName = curSubtopic.name;
      const curSubtopicIndex = await getCurrentSubtopicIndex(userId, subject, lessonId);

      const teacherResponseStream = await this.responseGenerator.generateResponseAction(lessonTitle, 
                                                                                  curSubtopicName, 
                                                                                  curSubtopicIndex, 
                                                                                  nextAction, 
                                                                                  recentDialogueObjects, 
                                                                                  pineContext, 
                                                                                  images);
      let teacherResponse = '';

      const reader = teacherResponseStream.getReader();
      const decoder = new TextDecoder("utf-8");

      let that = this;

      // Create a new stream that will be returned
      const returnStream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              // Emit the streamComplete event
              that.eventEmitter.emit('actionStreamComplete', userId, '', teacherResponse, subject, lessonId, lessonTitle, curSubtopicIndex);
              break;
            }

            if (value) {
              const chunk = decoder.decode(value, {stream: true});
              teacherResponse += chunk;
              //console.log("TEACHER ACTION RESP STREAM: ", teacherResponse);
              controller.enqueue(value);
            }
          }
          controller.close();
        }
      });
      return returnStream;
    }

    async generateLessonPlanJSON(lessonTitle, subtopic, allSubtopics, depthLevel) {
      const instruction =
      `"You are a helpful teacher lesson planner that breaksdown each subtopic of a lesson into a set of comprehensive topics. 
      Generate a lesson plan in JSON format for the following lesson: '${lessonTitle}'.
      Focus on creating a lesson plan that elaborates only this subtopic: '${subtopic}' 
      Avoid generating lessonPlan topics that overlap with the following other subtopics: 
      '${allSubtopics}'
      The lesson plan should include topics and sub-points. Each topic should have a 'covered' status field set to false initially, and a list of sub-points.
      A lessonPlan should be no longer than 3 topics.

      For instance, here is an excerpt of example output for a lesson with a subtopic of "Understanding ratios":
        {
          "lessonPlan": [
              {
                  "topic": "Definition and Understanding of Ratio",
                  "covered": false,
                  "subPoints": [
                      "Explain what a ratio is.",
                      "Discuss the importance of ratios in real-life scenarios."
                  ]
              },
              {
                  "topic": "Various Forms of Representing Ratios",
                  "covered": false,
                  "subPoints": [
                      "Explain different ways to represent ratios (e.g., 2:3, 2 to 3, or 2/3).",
                      "Show examples of each form."
                  ]
              },
              ...More topics
            ]
        }`

        const messages = [
          { "role": "system", "content": instruction },
        ];

        const lessonPlanResponse = await generateText(messages);
        let lessonPlanJSON = JSON.parse(lessonPlanResponse);
        return lessonPlanJSON;
    }

    async startQuizFeedback(userId, quizObject, gradedQuestions, lessonTitle, subject) {
      const courseObject = await getCourseByName(userId, subject);
      //console.log("COURSE OBJECT: ", courseObject);
      const depth = courseObject.depthLevel ? courseObject.depth : "Undergraduate";
      const teachingStyle = courseObject.teachingStyle ? courseObject.teachingStyle : "Socratic";
      const speakingStyle = courseObject.speakingStyle ? courseObject.speakingStyle : "Please speak as if you were teaching a undergraduate student.";
      const userName = await getUserName(userId);

      const assessQuizPromptJSON = {
        "ai_tutor": {
          "init": {
            "speaking_style": speakingStyle,
            "goal": "Give the student useful feedback on what to improve on based on the quiz score in 'quiz_to_assess'.",
            "subject": quizObject.course, 
            "studentName": userName
          },
          "teaching_style": teachingStyle,
          'depth': {
            "description": "This is the level of depth of the content the student wants to learn.",
            "setDepth": depth
          },
          "quiz_to_assess": gradedQuestions,
          "rules": [
            "1. Be decisive, take the lead on the student's learning, and never be unsure of where to continue.",
            "2. Double-check your knowledge or answer step-by-step if the student requests it.",
            "3. Mention to the student: `say /continue to continue the lesson` at the end of your response.",
            "4. Don't let the student stray off topic.",
            "5. The quiz feedback must be in plain english do NOT use any JSON formatting like in the quiz_to_assess."
          ],
          "currentLesson": {
            "lessonTitle": lessonTitle,
            "subtopic": quizObject.title
          },
          "format": {
            "Description": "These are strictly the specific formats you should follow in order. Ignore Desc as it is contextual information.",
            "markdown": true,
            "mathKaTex": true,
            "Quiz feedback": [
                "Desc: This is the format you respond for quiz feedback. Your feedback should be postive, encouraging but also fair and no more than 3 sentences.",
                "<quiz performance feedback>",
                "<strictly execute rule 3 and rule 5>"
            ]
          }
        }
      };
      const assessQuizJSONString = JSON.stringify(assessQuizPromptJSON);
    
      const messages = [
        { "role": "system", "content": assessQuizJSONString },
      ];
    
      //console.log("AiCore.assessQuiz() messages: ", messages);
      const teacherResponseStream = await generateStream(messages);

      let teacherResponse = '';

      const reader = teacherResponseStream.getReader();
      const decoder = new TextDecoder("utf-8");

      let that = this;

      // Create a new stream that will be returned
      const returnStream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              // Emit the streamComplete event
              that.eventEmitter.emit('feedbackStreamComplete', userId, '', teacherResponse, quizObject.course, 
                                                                              quizObject.lessonID, lessonTitle);
              break;
            }

            if (value) {
              const chunk = decoder.decode(value, {stream: true});
              teacherResponse += chunk;
              controller.enqueue(value);
            }
          }
          controller.close();
        }
      });
      return returnStream;
    }


    async startLesson(userId, subject, lessonId, lessonTitle) {
      const courseObject = await getCourseByName(userId, subject);
      const depth = courseObject.depthLevel ? courseObject.depthLevel : "Undergraduate";
      const teachingStyle = courseObject.teachingStyle ? courseObject.teachingStyle : "Socratic";
      const speakingStyle = courseObject.speakingStyle ? courseObject.speakingStyle : "Please speak as if you were teaching a undergraduate student.";

      const curSubtopic = await getCurrentSubtopic(userId, subject, lessonId);
      const curSubtopicName = curSubtopic.name;
      const allSubtopics = await getFormattedSubtopicNames(userId, subject, lessonId);

      if (!curSubtopic.lessonPlan) {
        // Start generating the lesson plan and saving it to Firestore in a separate asynchronous function
        (async () => {
            const lessonPlanJson = await this.generateLessonPlanJSON(lessonTitle, curSubtopicName, allSubtopics, depth);
            //let lessonPlanString = JSON.stringify(lessonPlanJson);
            //console.log("FIRSTLESSONPLANJSON", lessonPlanJson);
            await saveLessonPlanToSubtopic(userId, subject, lessonId, 0, lessonPlanJson);
        })();
      }

      const userName = await getUserName(userId);
      const images = await fetchImagesBySubtopic(userId, subject, lessonId, lessonTitle);
      const formattedImages = images.map((image, index) => `Image 0-${index}: ${image.description}`).join('\n');
      //console.log("IMAGE DESCRIPTIONS: ", images);

      const startLessonPromptJSON = {
        "ai_tutor": {
          "init": {
            "speaking_style": speakingStyle,
            "goal": "help the student understand concepts, address their questions, and guide them towards mastery of the subject matter",
            "subject": subject, 
            "methods": "Encourage active learning through images, example problems and explanations.",
            "studentName": userName
          },
          "teaching_style": teachingStyle,
          'depth': {
            "description": "This is the level of depth of the content the student wants to learn.",
            "setDepth": depth
          },
          "rules": [
            "1. Be decisive, take the lead on the student's learning, and never be unsure of where to continue.",
            "2. Double-check your knowledge or answer step-by-step if the student requests it.",
            "3. Mention to the student: `say /continue to continue or /practice to practice` at the end of your response.",
            "4. Always end your response to the student with a follow-up question or <execute rule 3>",
            "5. Don't let the student stray off topic."
          ],
          "currentLesson": {
            "lessonTitle": lessonTitle,
            "subtopics": curSubtopic
          },
          "formats": {
            "Description": "These are strictly the specific formats you should follow in order. Ignore Desc as they are contextual information.",
            "markdown": true,
            "mathKaTeX": {
              "enabled": true,
              "format": "Math equations should be in KaTeX enclosed in dollar signs ($math_equation$)."
            },
            "LessonIntroduction": [
                "Desc: This is the format you respond for every lesson introduction, you shall teach step-by-step so the student can learn. It is necessary to provide examples and exercises for the student to practice.",
                "a markdown heading of the lesson title",
                "<lesson introduction, and please strictly execute rule 5>",
                "<strictly execute rule 4>"
            ],
            "images": [
              "Desc: You can include pictures for visual flair to make lessons more engaging. The pictures must be simple without any quantities, words, or diagrams.",
              "Desc: Strictly follow the format specified in usage_instruction, where description is a one to two word description of an image",
              "usage_instruction: [Image: description]",
              "example: If you want to highlight centripetal force, show a picture of rollercoaster [Image: rollercoaster]"
            ]
          }
        }
      };
      const startLessonJSONString = JSON.stringify(startLessonPromptJSON);
      //${plotInstruction}`
      //`Lastly, provide a list of subtopics covered in this lesson, formatted as follows: SUBTOPICS_START Subtopic1, Subtopic2, Subtopic3, ... SUBTOPICS_END`
    
      const messages = [
        { "role": "system", "content": startLessonJSONString },
      ];
    
      //console.log("AiCore.startLesson() messages: ", messages);
      const teacherResponseStream = await generateStream(messages);

      let teacherResponse = '';

      const reader = teacherResponseStream.getReader();
      const decoder = new TextDecoder("utf-8");

      let that = this;

      // Create a new stream that will be returned
      const returnStream = new ReadableStream({
        async start(controller) {
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              // Emit the streamComplete event
              that.eventEmitter.emit('streamComplete', userId, '', teacherResponse, subject, lessonId, lessonTitle,
                                                                  curSubtopicName, 0, null, null, courseObject);
              break;
            }

            if (value) {
              const chunk = decoder.decode(value, {stream: true});
              teacherResponse += chunk;
              controller.enqueue(value);
            }
          }
          controller.close();
        }
      });
      return returnStream;
    }
  
}

export default AiCore;