import React, { useState, useEffect } from 'react';
import 'chart.js/auto';
import { Line } from 'react-chartjs-2';
import styles from './Statistics.css';
import Card from 'react-bootstrap/Card';
import testData from '../../../testData.json';

function Statistics({students, className}) {
  const [studentGrades, setStudentGrades] = useState([]);

  useEffect(() => {
    const gradePromises = testData.students.map((student) => {
      return Promise.resolve(student.grades);
    });

    Promise.all(gradePromises)
      .then((responses) => {
        const grades = responses.map((response) => response);
        setStudentGrades(grades);
      })
      .catch((err) => {
        //console.log(err);
      });
  }, []);

  const grades = studentGrades ? studentGrades.flat() : [];
  const sum = grades.reduce((total, grade) => total + grade, 0);
  const average = grades.length > 0 ? sum / grades.length : 0;

  const chartData = {
    labels: testData.assignments.map((_, i) => `Assignment ${i + 1}`),
    datasets: studentGrades.map((grades, i) => {
      return {
        label: `Student ${i + 1}`,
        data: grades,
        fill: false,
        borderColor: `rgb(${Math.random() * 255}, ${
          Math.random() * 255
        }, ${Math.random() * 255})`,
        tension: 0.1,
      };
    }),
  };

  const options = {
    scales: {
      y: {
        ticks: {
          suggestedMax: 100,
          suggestedMin: 0,
          stepSize: 10,
        },
      },
    },
  };

  return (
    <div className={`statistics ${className}`}>
      <h2 className="text-left">Statistics</h2>
      <Card className="shadow" style={{padding: '20px'}}>
        <div className={styles['average-grade-container']}>
        <p style={{ fontSize: '24px', fontWeight: 'bold', margin: 0 }}>{average.toFixed(2)}</p>
        <p style={{ fontSize: '14px', margin: 0 }}>Average grade</p>
         </div>
      </Card>
      <Card className="shadow" style={{ width: '600px', height: '400px', padding: '20px', marginTop: '20px', marginBottom: '20px'}}>
        <h3 className="text-center">Grades</h3>
        <Line data={chartData} options={options} />
      </Card>
    </div>
  );
}

export default Statistics;
