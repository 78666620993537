import styles from './ProgressCircle.module.css';
function ProgressCircle({ progress }) {

    const currentGrade = progress;
    let circleCircumference = 2 * Math.PI * 50; //50 assuming as the radius of your circle
    let offset = circleCircumference * (1 - (currentGrade / 100));

    return (
        <svg width="120" height="120" viewBox="0 0 120 120" className={styles['progress-circle']}>
            <circle 
                cx="60" 
                cy="60" 
                r="50" 
                fill="transparent" 
                stroke="#4285f4" 
                strokeWidth="10" 
                strokeDasharray={circleCircumference}
                strokeDashoffset={offset}
            />
            <text x="50%" y="50%" className={styles['progress-text']} textAnchor="middle" dominantBaseline="middle">{currentGrade}%</text>
        </svg>
    )
}

export default ProgressCircle;