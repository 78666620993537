import { firestoreInstance } from './firebase';
import { collection, doc, getDocs, getDoc, setDoc, addDoc, updateDoc, serverTimestamp, arrayUnion, onSnapshot, where, query } from 'firebase/firestore';
import { createSemesterCourseContent } from './courseService';
import { initializeExams } from '../aicore/CurriculumPlanner/ExamGenerator';

export const saveLesson = async (userId, subject, lesson, newContext = null, lessonNumber) => {
  try {
    const newLesson = {
      ...lesson,
      createdAt: serverTimestamp(),
    };

    if (lessonNumber !== undefined) {
      newLesson.lessonNumber = lessonNumber;
    }

    if (newContext !== null) {
      newLesson.context = newContext;
    }

    if (lesson.lessonId) {
      const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lesson.lessonId}`);
      const docSnapshot = await getDoc(lessonRef);

      if (docSnapshot.exists()) {
        await updateDoc(lessonRef, newLesson);
        //console.log('Lesson updated with ID: ', lessonRef.id);
      } else {
        await setDoc(lessonRef, newLesson);
        //console.log('Lesson created with ID: ', lessonRef.id);
      }
    } else {
      const lessonsRef = collection(firestoreInstance, `users/${userId}/${subject}`);
      const docRef = await addDoc(lessonsRef, newLesson);
      //console.log('New lesson created with ID: ', docRef.id);
    }
  } catch (e) {
    console.error('Error saving document: ', e);
  }
};


export const updateUserLesson = async (userId, subject, lessonId, responseText) => {
  try {
    const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
    const lessonSnapshot = await getDoc(lessonRef);

    if (lessonSnapshot.exists()) {
      await updateDoc(lessonRef, {
        content: arrayUnion(responseText),
        updatedAt: serverTimestamp(),
      });
      //console.log('Document updated successfully');
    } else {
      console.error('Error updating document: document does not exist');
    }
  } catch (e) {
    console.error('Error updating document:', e);
  }
};

export const fetchUserLessons = async (userId, subject) => {
  const subjectLessonsRef = collection(firestoreInstance, `users/${userId}/${subject}`);
  const querySnapshot = await getDocs(subjectLessonsRef);

  const userLessons = [];
  querySnapshot.forEach((doc) => {
    userLessons.push({ ...doc.data(), _id: doc.id, context: doc.data().context || '' });
  });

  return userLessons;
};

export const fetchLessonById = async (userId, subject, lessonId) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    return { ...lessonSnapshot.data(), _id: lessonSnapshot.id };
  } else {
    return null;
  }
};

export const savePrepopulatedLessons = async (userId) => {
  const prepopulatedLessons = require('./prepopulatedLessons.json');
  //console.log('prepopulatedLessons:', prepopulatedLessons); // Debugging statement

  for (const subject in prepopulatedLessons) {
    //console.log('subject:', subject); // Debugging statement
    //console.log('prepopulatedLessons[subject]:', prepopulatedLessons[subject]); // Debugging statement

    //console.log('typeof prepopulatedLessons[subject]:', typeof prepopulatedLessons[subject]); // Debugging statement
    for (const lesson of prepopulatedLessons[subject]) {
      await saveLesson(userId, subject, lesson);
    }
  }
};

export const createAndSaveGeneratedCourse = async (userId, courseName, courseLength, setCreationProgress) => {
  const generatedLessons = await createSemesterCourseContent(courseName, courseLength, setCreationProgress);

  //console.log('generatedLessons:', generatedLessons); // Debugging statement

  for (const subject in generatedLessons) {
    //console.log('subject:', subject); // Debugging statement
    //console.log('generatedLessons[subject]:', generatedLessons[subject]); // Debugging statement

    //console.log('typeof generatedLessons[subject]:', typeof generatedLessons[subject]); // Debugging statement
    for (let i = 0; i < generatedLessons[subject].length; i++) {
      const lesson = generatedLessons[subject][i];
      await saveLesson(userId, subject, lesson, null, i);

      if (i % 4 === 0) {
        //await initializeExams(userId, courseName, i);
      }
    }
  }
};

export const saveLessonContext = async (userId, subject, lessonId, context) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  try {
    if (context) {
      await updateDoc(lessonRef, {
        context: context,
      });
    }
    //console.log('Lesson context saved successfully');
  } catch (e) {
    console.error('Error saving lesson context:', e);
  }
};

//TODO:To be used when AI starts generating subtopics to help user
export const saveLessonSubtopics = async (userId, subject, lessonId, subtopics) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);

  try {
    if (!subtopics || !Array.isArray(subtopics)) {
      throw new Error('Invalid subtopics provided');
    }

    await updateDoc(lessonRef, {
      subtopics: subtopics,
    });

    //console.log('Lesson context saved successfully');
  } catch (e) {
    console.error('Error saving lesson context:', e);
  }
};

export const fetchImagesByLesson = async (userId, subject, lessonId, lessonTitle) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);

  const regex = /Lesson (\d+)/;
  const match = lessonTitle.match(regex);
  let curLesson = 1;
  if (match && match[1]) {
    curLesson = parseInt(match[1], 10);
  }

  const subjectId = subject.toString().toLowerCase() + '6';
  const subtopics = lessonSnapshot.data().subtopics;
  const imagesBySubtopic = {};

  for (let i = 0; i < subtopics.length; i++) {
    const subtopicId = 'subtopic' + (i + 1);
    const imagesRef = collection(
      firestoreInstance,
      `courses/${subjectId}/lessons/lesson${curLesson}/subtopics/${subtopicId}/images`
    );
    const querySnapshot = await getDocs(imagesRef);

    const images = [];
    querySnapshot.forEach((doc, index) => {
      images.push({ ...doc.data(), _id: `${i}-${index}` });
    });

    imagesBySubtopic[i] = images;
  }

  return imagesBySubtopic;
};

export const fetchImagesBySubtopic = async (userId, subject, lessonId, lessonTitle) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);
  const regex = /Lesson (\d+)/;
  const match = lessonTitle.match(regex);
  let curLesson = 1;
  if (match && match[1]) {
    curLesson = parseInt(match[1], 10);
  }
  const subjectId = subject.toString().toLowerCase() + '6';
  //console.log("current subtopic index: ", lessonSnapshot.data().currentSubtopicIndex);
  const currentSubtopicIndex = lessonSnapshot.data().currentSubtopicIndex + 1;
  const subtopicId = 'subtopic' + currentSubtopicIndex;
  //console.log("IMAGE REF: ", `courses/${subjectId}/lessons/lesson${curLesson}/subtopics/${subtopicId}/images`);
  const imagesRef = collection(firestoreInstance, `courses/${subjectId}/lessons/lesson${curLesson}/subtopics/${subtopicId}/images`);
  const querySnapshot = await getDocs(imagesRef);

  const images = [];
  querySnapshot.forEach((doc) => {
    images.push({ ...doc.data(), _id: doc.id });
  });

  return images;
};

export const updateCurrentSubtopicIndex = async (userId, subject, lessonId, currentSubtopicIndex) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);

  try {
    await updateDoc(lessonRef, {
      currentSubtopicIndex: currentSubtopicIndex,
    });
    //console.log('currentSubtopicIndex updated successfully');
  } catch (e) {
    console.error('Error updating currentSubtopicIndex:', e);
  }
};

export const onSubtopicsScoreChange = (userId, subject, lessonId, callback) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  //console.log("LessonService onSubtopics: ", userId, subject, lessonId);
  let oldSubtopics = null;

  const unsubscribe = onSnapshot(lessonRef, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const lessonData = docSnapshot.data();
      const newSubtopics = lessonData.subtopics;

      if (oldSubtopics) {
        const changedIndex = newSubtopics.findIndex((newSubtopic, index) => {
          return newSubtopic.score !== oldSubtopics[index].score;
        });

        if (changedIndex !== -1) {
          callback({ ...lessonData, currentSubtopicIndex: changedIndex });
        } else {
          console.warn("No subtopic score change detected.");
        }
      }

      oldSubtopics = newSubtopics;
    } else {
      console.error("Error listening to subtopic score changes: document does not exist");
    }
  });

  return () => {
    unsubscribe();
  };
};

export const updateCheckpoints = async (userId, subject, lessonId, newCheckpoints) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  try {
    await updateDoc(lessonRef, {
      checkpoints: newCheckpoints,
    });
    //console.log('Checkpoints updated successfully');
  } catch (e) {
    console.error('Error updating checkpoints:', e);
  }
};

export const getCurrentSubtopicIndex = async (userId, subject, lessonId) => {
  ////console.log("currSubtopicIndx ", userId, subject, lessonId);
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    return lessonData.currentSubtopicIndex || 0;
  } else {
    console.error('Error fetching current subtopic index: lesson does not exist');
    return 0;
  }
};

export const getCurrentSubtopic = async (userId, subject, lessonId) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    const currentSubtopicIndex = lessonData.currentSubtopicIndex || 0;
    return lessonData.subtopics[currentSubtopicIndex];
  } else {
    console.error('Error fetching current subtopic: lesson does not exist');
    return null;
  }
};

export const getNextSubtopic = async (userId, subject, lessonId) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    const currentSubtopicIndex = lessonData.currentSubtopicIndex || 0;

    // Check if there's a next subtopic
    if (currentSubtopicIndex < lessonData.subtopics.length - 1) {
      // Increment the index by 1 to get the next subtopic
      const nextSubtopicIndex = currentSubtopicIndex + 1;

      // Update the currentSubtopicIndex in the database
      //await updateDoc(lessonRef, {
      //currentSubtopicIndex: nextSubtopicIndex
      //});

      // Return the next subtopic
      return lessonData.subtopics[nextSubtopicIndex];
    } else {
      //console.log("This is the last subtopic");
      return null;
    }
  } else {
    console.error('Error fetching next subtopic: lesson does not exist');
    return null;
  }
};

export const getFormattedSubtopicNames = async (userId, subject, lessonId) => {
  // Fetch all subtopics
  const subtopics = await getAllSubtopics(userId, subject, lessonId);

  // Extract the names
  const subtopicNames = subtopics.map(subtopic => subtopic.name);

  // Format the names for better readability
  const formattedSubtopicNames = formatListForReading(subtopicNames);

  return formattedSubtopicNames;
};

const formatListForReading = (list) => {
  if (!list || list.length === 0) {
    return "no items";
  }
  switch (list.length) {
    case 1:
      return list[0];
    case 2:
      return `${list[0]} and ${list[1]}`;
    default:
      // Join with commas and new lines
      return `${list.slice(0, -1).join(',\n')}, and ${list.slice(-1)}`;
  }
};

export const getAllSubtopics = async (userId, subject, lessonId) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    return lessonData.subtopics || [];
  } else {
    console.error('Error fetching subtopics: lesson does not exist');
    return [];
  }
};

export const saveLessonPlanToSubtopic = async (userId, subject, lessonId, subtopicIndex, lessonPlanJson) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);

  // Get the current data
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    let subtopics = lessonData.subtopics;

    if (Array.isArray(subtopics) && subtopicIndex >= 0 && subtopicIndex < subtopics.length) {
      // Update the specific subtopic with the new lessonPlan
      subtopics[subtopicIndex].lessonPlan = lessonPlanJson;

      // Update the subtopics array in Firestore
      await updateDoc(lessonRef, { subtopics });
    } else {
      console.error('Invalid subtopicIndex or subtopics is not an array');
    }
  } else {
    console.error('Error fetching current subtopic: lesson does not exist');
  }
};


export const getLessonPlanFromSubtopic = async (userId, subject, lessonId, subtopicIndex) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);

  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    const subtopics = lessonData.subtopics;

    if (subtopics[subtopicIndex] && subtopics[subtopicIndex].lessonPlan) {
      return subtopics[subtopicIndex].lessonPlan;
    } else {
      console.error(`Error fetching lesson plan: Subtopic ${subtopicIndex} or lesson plan does not exist`);
      return null;
    }
  } else {
    console.error('Error fetching lesson: Lesson does not exist');
    return null;
  }
};

export const savePerformanceSummaryToSubtopic = async (userId, subject, lessonId, subtopicIndex, perfSummary) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);

  // Get the current data
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    let subtopics = lessonData.subtopics;

    // Update the specific subtopic with the new lessonPlan
    subtopics[subtopicIndex].perfSummary = perfSummary;

    // Update the subtopics array in Firestore
    await updateDoc(lessonRef, { subtopics });
  } else {
    console.error('Error fetching current subtopic: lesson does not exist');
  }
};

export const getPerformanceSummaryFromSubtopic = async (userId, subject, lessonId, subtopicIndex) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);

  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    const subtopics = lessonData.subtopics;

    if (subtopics[subtopicIndex] && subtopics[subtopicIndex].perfSummary) {
      return subtopics[subtopicIndex].perfSummary;
    } else {
      console.error(`Error fetching performance summary: Subtopic ${subtopicIndex} or performance summary does not exist`);
      return null;
    }
  } else {
    console.error('Error fetching lesson: Lesson does not exist');
    return null;
  }
};

export const quizExistsInSetIfNotAdd = async (userId, subject, lessonId, subtopicIndex, quiz) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  try {
    const lessonSnapshot = await getDoc(lessonRef);

    if (lessonSnapshot.exists()) {
      const lessonData = lessonSnapshot.data();
      if (lessonData.subtopics && lessonData.subtopics[subtopicIndex]) {
        const subtopics = lessonData.subtopics;
        let quizzes = subtopics[subtopicIndex].quizzes || [];
        
        if (quizzes.includes(quiz)) {
          //console.log("Quiz already exists in subtopic");
          return true;
        } else {
          // Add the quiz to the array
          quizzes = [...quizzes, quiz];
          subtopics[subtopicIndex].quizzes = quizzes;
          
          // Set the entire updated subtopics array back to the document
          await updateDoc(lessonRef, {
            subtopics: subtopics
          });
          //console.log("Quiz added to subtopic so it didn't exist before");
          return false;
        }
      } else {
        console.error("Subtopic does not exist");
        return true;
      }
    } else {
      console.error("Lesson does not exist");
      return true;
    }
  } catch (error) {
    console.error("Error fetching or updating lesson data: ", error);
    return true;
  }
};

export const getCurrentSubtopicScore = async (userId, subject, lessonId) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    const currentSubtopicIndex = lessonData.currentSubtopicIndex || 0;
    const currentSubtopic = lessonData.subtopics[currentSubtopicIndex];
    return currentSubtopic.score;
  } else {
    console.error('Error fetching current subtopic score: lesson does not exist');
    return null;
  }
};

export const updateSubtopicScore = async (userId, subject, lessonId, currentSubtopicIndex, questionScore) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    const subtopics = lessonData.subtopics;
    const updatedSubtopic = {
      ...subtopics[currentSubtopicIndex],
      score: questionScore,
    };

    subtopics[currentSubtopicIndex] = updatedSubtopic;

    await updateDoc(lessonRef, {
      subtopics: subtopics,
    });
    //console.log("Subtopic score updated successfully");
  } else {
    console.error("Error updating subtopic score: document does not exist");
  }
};

export const getTotalProgressScore = async (userId, subject, lessonId) => {
  const lessonRef = doc(firestoreInstance, `users/${userId}/${subject}/${lessonId}`);
  const lessonSnapshot = await getDoc(lessonRef);

  if (lessonSnapshot.exists()) {
    const lessonData = lessonSnapshot.data();
    const subtopics = lessonData.subtopics;
    const totalScore = subtopics.reduce((sum, subtopic) => {
      return sum + subtopic.score;
    }, 0);

    return (totalScore + 130) / 100;  //normalize the score to not be in hundreds
  } else {
    console.error('Error fetching total score: lesson does not exist');
    return 0;
  }
};

export const getTotalScoreAndPointsForSubject = async (userId, subject) => {
  try {
    // Get reference to the subject collection
    const subjectRef = collection(firestoreInstance, `users/${userId}/${subject}`);
    const subjectSnapshot = await getDocs(subjectRef);

    // Iterate through each lesson and calculate the total score and total points available
    let totalScore = 0;
    let totalPointsAvailable = 0;
    subjectSnapshot.forEach((lessonDoc) => {
      const lessonData = lessonDoc.data();
      const subtopics = lessonData.subtopics;
      const lessonScore = subtopics.reduce((sum, subtopic) => {
        totalPointsAvailable += 100; // increment total points available by 100 for each subtopic
        return sum + (subtopic.score || 0);
      }, 0);
      //console.log("PROA ", lessonScore);
      totalScore += lessonScore;
    });
    //console.log("TOTAL SCORE ", totalScore);
    return {
      totalScore: totalScore,
      totalPointsAvailable: totalPointsAvailable
    };
  } catch (error) {
    console.error('Error fetching total score and points for subject:', error);
    return {
      totalScore: 0,
      totalPointsAvailable: 0
    };
  }
};

export const fetchExamBriefings = async (userID, course) => {
  const examsRef = collection(firestoreInstance, `users/${userID}/Exams`);

  // Add the where clause to filter exams by course and status
  const courseQuery = query(
    examsRef,
    where('course', '==', course)
  );

  const querySnapshot = await getDocs(courseQuery);

  const exams = [];
  querySnapshot.forEach((doc) => {
    // Extract only the 'title', 'covering', and 'briefing' fields of each exam
    const { title, status, covering, briefing } = doc.data();

    exams.push({
      title,
      status,
      covering,
      briefing,
      _id: doc.id
    });
  });

  return exams;
};
/****************
      Stripe
 ***************/
export const updateSubscriptionStatus = async (userId, isSubscribed) => {
  const userRef = doc(firestoreInstance, `users/${userId}`);

  try {
    await updateDoc(userRef, {
      isSubscribed: isSubscribed,
    });
    //console.log('Subscription status updated successfully');
  } catch (e) {
    console.error('Error updating subscription status:', e);
  }
};

export const getSubscriptionStatus = async (userId) => {
  const userRef = doc(firestoreInstance, `users/${userId}`);

  try {
    const userSnapshot = await getDoc(userRef);

    if (userSnapshot.exists()) {
      const userData = userSnapshot.data();
      return userData.isSubscribed || false;
    } else {
      console.error('Error fetching subscription status: user does not exist');
      return false;
    }
  } catch (e) {
    console.error('Error fetching subscription status:', e);
    return false;
  }
};