import React, { useState, useEffect, useRef, useContext } from 'react';
import styles from './cards.module.css'
import mathImage from '../../ui/assets/math.jpg'
import scienceImage from '../../ui/assets/science.jpg';
import createCourseImage from '../../ui/assets/createCourse.PNG';
import loadingCourseImage from '../../ui/assets/loadingCourse.gif';
import defaultImage from '../../ui/assets/defaultImage.png';
import { useNavigate } from 'react-router-dom';
import CourseCreationModal from './CourseCreationModal';
import { authInstance, firestoreInstance } from '../../../services/firebase';
import { fetchUserCourses, removeUserCourse } from '../../../services/courseService';
import { CircularProgress, createTheme, LinearProgress, Menu, MenuItem } from '@mui/material';
import { doc, onSnapshot } from '@firebase/firestore';
import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DeleteCourseDialog } from './DeleteCourseDialog';
import UserContext from './UserContext';
import { getTotalScoreAndPointsForSubject } from '../../../services/lessonService';


function Courses() {
    const [selectedCourse, setSelectedCourse] = useState(null);
    const [courseList, setCourseList] = useState([]);
    const [showCourseCreationModal, setShowCourseCreateModal] = useState(false);
    const [creationProgress, setCreationProgress] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteWarningOpen, setDeleteWarningOpen] = useState(false);
    const [courseToDelete, setCourseToDelete] = useState(null);
    const userId = authInstance.currentUser.uid;
    const navigate = useNavigate();
    const { developerMode } = useContext(UserContext);
      

    const handleCourseClick = (courseName) => {
        // If a menu is open, do nothing
        if (anchorEl) return;

        if (courseName === 'New Course') {
            setShowCourseCreateModal(true);
        } else {
            setSelectedCourse(courseName);
            navigate('/course', { state: { courseName, developerMode} });
            //console.log('Course: ', courseName);
        }
    };

    const handleModalClose = () => {
        setShowCourseCreateModal(false);
    };

    const handleNewCourse = (newCourse) => {
        setCourseList((prevCourses) => {
          const newCourses = [...prevCourses];
          newCourse.depthLevel = newCourse.depthLevel ? findDepthLevel(newCourse.depthLevel) : "Undergraduate";
          newCourse.teachingStyle = newCourse.teachingStyle ? findTeachingStyle(newCourse.teachingStyle) : "Default";
          newCourses.splice(newCourses.length - 1, 0, newCourse);
          return newCourses;
        });
    };

    const handleMoreOptionsClick = (event, courseName) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        //console.log(`More options clicked for course: ${courseName}`);
        setSelectedCourse(courseName);
        // add your functionality here
    }

    const handleDeleteCourseClick = () => {
        setCourseToDelete(selectedCourse);
        setDeleteWarningOpen(true);
    }

    const handleDeleteWarningClose = () => {
        setDeleteWarningOpen(false);
    }

    const deleteCourse = (courseName) => {
        // delete course logic
        //console.log(`Deleting course: ${courseName}`);
        removeUserCourse(userId, courseName);
        // Close the delete warning after deleting the course
        setDeleteWarningOpen(false);
        setAnchorEl(null);
    }

    const handleMoreOptionsClose = () => {
        setAnchorEl(null);
    };

    // Helper function to get course image
    function getCourseImage(course) {
      // If the course has an image URL, use it
      if (course.imageUrl) {
        if (course.imageUrl === "../components/ui/assets/defaultImage.png") {
          return defaultImage;
        }
        else {
          return course.imageUrl;
        }
      }
  
      // Otherwise, return a default image based on the course name
      switch (course.course) {
        case 'Math':
          return mathImage;  // replace with the URL or path of your default Math image
        case 'Science':
          return scienceImage;  // replace with the URL or path of your default Science image
        default:
          return defaultImage;  // replace with the URL or path of your default image for other courses
      }
    }

    function findDepthLevel(sentence) {
      const regex = /(\d{1,2}(?:st|nd|rd|th) Grade)|undergraduate|graduate|post-graduate/gi;
      
      const match = sentence.match(regex);
      
      return match ? match[0] : null;
    }

    function findTeachingStyle(sentence) {
      const regex = /Socratic|Story-telling|Visual/gi;
      
      const match = sentence.match(regex);
      
      return match ? match[0] : null;
    }

    const getDepthTagColor = (depthLevel) => {
      switch (depthLevel) {
          case '1st Grade':
          case '2nd Grade':
          case '3rd Grade':
              return '#8bc34a'; // Light Green for early grades
          case 'Undergraduate':
              return '#90caf9'; // Faded Blue for undergraduate to be less prominent
          case 'Graduate':
              return '#e91e63'; // Pink for graduate
          case 'Post-graduate':
              return '#3f51b5'; // Indigo for post-graduate
          default:
              return '#757575'; // Default color
      }
  };
  
  const getTeachingStyleTagColor = (teachingStyle) => {
      switch (teachingStyle) {
          case 'Default':
              return '#bdbdbd';  // Light Grey for Default to be less prominent
          case 'Socratic':
              return '#ff9800';  // Orange for Socratic
          case 'Visual':
              return '#009688';  // Teal for Visual
          case 'Story-telling':
              return '#673ab7';  // Deep Purple for Story-telling
          default:
              return '#607d8b';  // Blue Grey
      }
  };
  

    useEffect(() => {
      const fetchCourses = async () => {
          const userDocRef = doc(firestoreInstance, `users/${userId}`);
  
          // Setting up a listener
          const unsubscribe = onSnapshot(userDocRef, async (doc) => {
              const userCourses = doc.data()?.courses;
              if (Array.isArray(userCourses)) {
                  // Fetch scores for all courses in parallel
                  const scorePromises = userCourses.map(course => getTotalScoreAndPointsForSubject(userId, course.course));
                  const scores = await Promise.all(scorePromises);
                  //console.log("ALL SCORES: ", scores);
                  // Create an array of course objects
                  const courseArray = userCourses.map((course, index) => ({
                      name: course.course,
                      image: getCourseImage(course),
                      depthLevel: course.depthLevel ? findDepthLevel(course.depthLevel) : "Undergraduate",
                      teachingStyle: course.teachingStyle ? findTeachingStyle(course.teachingStyle) : "Default",
                      creating: false,
                      score: scores[index].totalScore,
                      total: scores[index].totalPointsAvailable
                  }));
  
                  // Add the 'New Course' object to the array
                  courseArray.push({ name: 'New Course', image: createCourseImage, creating: false });
  
                  setCourseList(courseArray);
              }
          });
  
          // Clean up the listener when the component unmounts
          return unsubscribe;
      };
  
      fetchCourses();
    }, [userId]);
  
    
    useEffect(() => {
        // Start the interval only when a course is being created
        const courseBeingCreated = courseList.find(course => course.creating);
    
        if (courseBeingCreated && creationProgress < 100) {
            const intervalId = setInterval(() => {
                // Generate a random number between 1 and 10 (you can adjust these numbers as needed)
                const randomProgressIncrease = Math.floor(Math.random() * 4) + 1;
                setCreationProgress(prevProgress => Math.min(prevProgress + randomProgressIncrease, 100));
            }, Math.random() * 3000 + 2000); // Random interval between 1 and 3 seconds (you can adjust these numbers as needed)
    
            // Clear the interval when the component unmounts or when the course is no longer being created
            return () => clearInterval(intervalId);
        }
    }, [courseList, creationProgress]);

    return (
        <>
          <h3>Courses</h3>
          <div className={styles['course-list']}>
            {courseList.map((course) => {
              // Define isLongTitle here
              const isLongTitle = course.name.length > 25; // Adjust the length threshold as needed
              return (
                <div
                  key={course.name}
                  className={styles['course-card']}
                  onClick={!course.creating ? () => handleCourseClick(course.name) : null}
                  style={{ pointerEvents: course.creating ? "none" : "auto", position: 'relative', paddingBottom: '20px' }}
                >
                  <img src={course.creating ? loadingCourseImage : course.image} alt={course.name} />
                  <div className={styles['course-card-content']}>
                    {course.name === 'New Course' ? 
                      <h3 className={styles['new-course-title']}>
                        <span>{course.name}</span>
                      </h3>
                    :
                      <>
                        <h3 className={`${styles['course-title']} ${isLongTitle ? styles['long-title'] : ''}`}>
                          <span>{course.name}</span>
                        </h3>
                        <div className={styles['course-subtitle']}>
                          
                          {/* Depth Level Tag */}
                          {course.depthLevel && (
                            <span
                              className={styles['tag-container']}
                              style={{ backgroundColor: getDepthTagColor(course.depthLevel) }}
                            >
                              <span className={styles['tag-text']}>{course.depthLevel.slice(0,9)}</span>
                            </span>
                          )}
                          {/* Teaching Style Tag */}
                          {course.teachingStyle && (
                            <span
                              className={styles['tag-container']}
                              style={{ backgroundColor: getTeachingStyleTagColor(course.teachingStyle) }}
                            >
                              <span className={styles['tag-text']}>{course.teachingStyle}</span>
                            </span>
                          )}
                        </div>
                      </>
                    }
                    {course.creating && (
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: '-15px' }}>
                        <span style={{ marginRight: '10px' }}>{creationProgress.toFixed(0)}% finished.</span>
                        <CircularProgress size={20}/>
                    </div>
                    )}
                  </div>
                  {course.name !== 'New Course' && !course.creating && (
                    <>
                      <button
                        className={styles['more-options-btn']}
                        style={{ position: 'absolute', bottom: 10, left: 10 }}
                        aria-controls="simple-menu" 
                        aria-haspopup="true"
                        onClick={(event) => handleMoreOptionsClick(event, course.name)}
                      >
                        <FontAwesomeIcon icon={faEllipsisH} />
                      </button>
                      <Menu
                        PaperProps={{
                            style: {
                            boxShadow: '0px 3px 3px 2px rgba(0, 0, 0, 0.1)', // Customize the shadow here
                            },
                        }}
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleMoreOptionsClose}
                        >
                        <MenuItem onClick={() => handleDeleteCourseClick()}>Delete Course</MenuItem>
                      </Menu>
                      <DeleteCourseDialog
                        open={deleteWarningOpen}
                        onClose={handleDeleteWarningClose}
                        courseToDelete={courseToDelete}
                        onDelete={deleteCourse}
                      />
                      <div style={{ position: 'absolute', bottom: 20, right: 20, width: '50%'}}>
                        <LinearProgress
                          variant="determinate" 
                          value={course.total !== 0 ? (course.score / course.total) * 100 : 0}
                          style={{ height: '10px', borderRadius: '5px' }}
                        />
                      </div>
                      <div style={{ position: 'absolute', bottom: 30, right: 20 }}>
                        <span style={{ fontSize: '12px' }}>
                          {(course.score !== undefined ? course.score.toFixed(0) : 0)}/{course.total || 0} points
                        </span>
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <CourseCreationModal 
            open={showCourseCreationModal} 
            onClose={handleModalClose} 
            onCreate={handleNewCourse}
            setCreationProgress={setCreationProgress}
            courseList={courseList} />
        </>
      );
}

export default Courses;
