import React, { useState, useEffect } from 'react';
import styles from './LessonList.module.css';

function LessonList({ onLessonClick, lessons }) {
  const [fetchedLessons, setFetchedLessons] = useState([]);
  const [selectedLessonId, setSelectedLessonId] = useState(null);

  useEffect(() => {
    // Set the fetched lessons based on the lessons prop
    setFetchedLessons(lessons);
  }, [lessons]);

  const handleLessonClick = (lesson) => {
    setSelectedLessonId(lesson._id);
    onLessonClick(lesson);
  };

  // Custom compare function for sorting lessons by numerical order in title
  const lessonSort = (lessonA, lessonB) => {
    return lessonA.lessonNumber - lessonB.lessonNumber;
  };

  // Sort fetched lessons by numerical order in title
  const sortedLessons = fetchedLessons.slice().sort(lessonSort);

  return (
    <div className={styles['lesson-list']}>
      <h3 className={styles['lesson-header']}>Lessons</h3>
      <div className={styles['lesson-list-content']}>
        <ul>
          {sortedLessons.map((lesson) => (
            <li
              key={lesson._id}
              onClick={() => handleLessonClick(lesson)}
              className={`${styles['lesson-item']} ${lesson._id === selectedLessonId ? styles['selected'] : ''
                }`}
            >
              <div className={styles['lesson-number']}>
                <h4>{lesson.title.split(":")[0]}</h4>
              </div>
              <div className={styles['divider']} />
              <div className={styles['lesson-info']}>
                <h4>{lesson.title.split(":")[1]}</h4>
                {lesson._id === selectedLessonId && (
                  <p>{lesson.description}</p>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default LessonList;