import { generateText } from "../../services/openai";
import { getCurrentSubtopic } from "../../services/lessonService";
import { uploadGradeable, updateGradeable } from "../../services/gradingService";
import { fetchGradeable, fetchGradeableOfType, fetchAllQuestions, fetchQuestions } from "../../services/gradingService";
import { firebase } from 'firebase/app';
import { getFirestore, Timestamp } from 'firebase/firestore';

export async function initializeExams(userID, course, i) {
    const num = (i / 4) + 1;
    const title = `Exam ${num}`;

    const from = i;
    const to = i + 3;

    const examSkeleton = {
        title: title,
        score: 0,
        course: course,
        status: "Initialized",
        covering: { from, to }
    };

    const docId = await uploadGradeable(userID, examSkeleton, "Exams");
}

export async function populateExam(userID, course, examID) {
    const examSkeleton = await fetchGradeable(userID, "Exams", examID);

    let date = new Date();
    date.setDate(date.getDate() + 3);

    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);

    const initialExam = {
        ...examSkeleton,
        status: "Creating",
        dueDate: date,
    };

    const from = examSkeleton.covering.from;
    const to = examSkeleton.covering.to;

    await updateGradeable(userID, examID, initialExam, "Exams");

    //const allAssignmentQuestions = await fetchAllQuestions(userID, course, "Assignments");
    const allAssignmentQuestions = await fetchQuestions(userID, course, "Assignments", from, to);
    //console.log(allAssignmentQuestions);

    const groupedMCQ = groupMCQQuestionsBySubtopic(allAssignmentQuestions);
    const groupedFRQ = groupFRQQuestionsBySubtopic(allAssignmentQuestions);

    //console.log("MCQ: ", groupedMCQ);
    //console.log("FRQ: ", groupedFRQ);

    const randomMCQ = selectRandomQuestionPerSubtopic(groupedMCQ);
    const randomFRQ = selectRandomQuestionPerSubtopic(groupedFRQ);

    const randomQuestions = [...randomMCQ, ...randomFRQ];

    //console.log(randomQuestions);

    const questions = await generateQuestions(randomQuestions);
    //console.log(questions);

    const exam = {
        ...initialExam,
        status: "In Progress",
        questions: questions  // add the generated questions
    };

    //console.log("course: ", course);
    //console.log(exam);

    await updateGradeable(userID, examID, exam, "Exams");
    return;
}

export async function createExam(userID, course) {
    const title = "Exam 1";

    let date = new Date();
    date.setDate(date.getDate() + 3);

    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);

    const from = 0;
    const to = 3;

    const initialExam = {
        title: title,
        score: 0,
        course: course,
        status: "Creating",
        dueDate: date,
        covering: { from, to }
    };

    const docId = await uploadGradeable(userID, initialExam, "Exams");

    //const allAssignmentQuestions = await fetchAllQuestions(userID, course, "Assignments");
    const allAssignmentQuestions = await fetchQuestions(userID, course, "Assignments", from, to);
    //console.log(allAssignmentQuestions);

    const groupedMCQ = groupMCQQuestionsBySubtopic(allAssignmentQuestions);
    const groupedFRQ = groupFRQQuestionsBySubtopic(allAssignmentQuestions);

    //console.log("MCQ: ", groupedMCQ);
    //console.log("FRQ: ", groupedFRQ);

    const randomMCQ = selectRandomQuestionPerSubtopic(groupedMCQ);
    const randomFRQ = selectRandomQuestionPerSubtopic(groupedFRQ);

    const randomQuestions = [...randomMCQ, ...randomFRQ];

    //console.log(randomQuestions);

    const questions = await generateQuestions(randomQuestions);
    //console.log(questions);

    const exam = {
        ...initialExam,
        status: "In Progress",
        questions: questions  // add the generated questions
    };

    //console.log("course: ", course);
    //console.log(exam);

    await updateGradeable(userID, docId, exam, "Exams");
    return;
}

// May be in JSON format later
async function generateMCQ(ogQuestion) {
    //console.log("MCQ Question: ", ogQuestion);
    try {
        const questionPrompt = {
            'role': 'Multiple Choice Question Generator',
            'standard': 'CPALMS Florida Department of Education Standards/Strands',
            'question': ogQuestion.questionText,
            'options': ogQuestion.options,
            'rules': [
                "1. Must output valid JSON as specified in output_format",
                "2. Generate a new question that is similar to the question specified by the 'question' field.",
                "3. 'Markdown' and 'Math KaTeX ($)' formatting should be used respectively and when appropriate to create all components of the output",
                "4. Whenever you want to denote a newline for formatting use this character: '\\n'. Make sure this character is outside of KaTeX formatting so it is processed correctly.",
            ],
            'format_rules': {
                "markdown": true,
                "mathKaTex": true
            },
            'output_format': `
                {
                    "questionType": MCQ,
                    "questionText": "[Insert New Question Here]",
                    "explanation": "[Insert Question Explanation Here]",
                    "options": {
                        "a": "[Insert Option 'a' Here]",
                        "b": "[Insert Option 'b' Here]",
                        "c": "[Insert Option 'c' Here]",
                        "d": "[Insert Option 'd' Here]"
                    },
                    "correctAnswer": "[Insert Correct Option Here]",
                    "studentAnswer": null,
                }
            `,
        };

        //console.log("Question Prompt: ", questionPrompt);
        const questionPromptJSONString = JSON.stringify(questionPrompt);

        const messages = [
            { "role": "system", "content": questionPromptJSONString },
        ];

        const questionString = await generateText(messages);
        let question = JSON.parse(questionString);
        return question;
    }
    catch (error) {
        console.error('Error generating MCQ question:', error);
        return;
    }
}

// May remove aiAnswer in the future, may change to JSON format
async function generateFRQ(ogQuestion) {
    //console.log("FRQ Question: ", ogQuestion);
    try {
        const questionPrompt = {
            'role': 'Free Response Question Generator',
            'question': ogQuestion.questionText,
            'rules': [
                "1. Must output valid JSON as specified in output_format",
                "2. Generate a new question that is similar to the question specified by the 'question' field.",
                "3. 'Markdown' and 'Math KaTeX ($)' formatting should be used respectively and when appropriate to create all components of the output",
                "4. Whenever you want to denote a newline for formatting use this character: '\\n'. Make sure this character is outside of KaTeX formatting so it is processed correctly.",
            ],
            'format_rules': {
                "markdown": true,
                "mathKaTex": true
            },
            'output_format': `
            {
                "questionType": "FRQ",
                "questionText": "[Insert Question Text Here]",
                "studentAnswer": null,
            }
            `,
        };

        //console.log("Question Prompt: ", questionPrompt);
        const questionPromptJSONString = JSON.stringify(questionPrompt);

        const messages = [
            { "role": "system", "content": questionPromptJSONString },
        ];

        const questionString = await generateText(messages);
        let question = JSON.parse(questionString);
        return question;
    }
    catch (error) {
        console.error('Error generating question:', error);
        return;
    }
}

const groupMCQQuestionsBySubtopic = (questions) => {
    return questions.reduce((groupedQuestions, question) => {
        if (question.questionType === 'MCQ') {
            const key = question.subtopic;
            if (!groupedQuestions[key]) {
                groupedQuestions[key] = [];
            }
            groupedQuestions[key].push(question);
        }
        return groupedQuestions;
    }, {});
};

const groupFRQQuestionsBySubtopic = (questions) => {
    return questions.reduce((groupedQuestions, question) => {
        if (question.questionType === 'FRQ') {
            const key = question.subtopic;
            if (!groupedQuestions[key]) {
                groupedQuestions[key] = [];
            }
            groupedQuestions[key].push(question);
        }
        return groupedQuestions;
    }, {});
};


const selectRandomQuestionPerSubtopic = (groupedQuestions) => {
    const randomQuestions = [];
    for (let subtopic in groupedQuestions) {
        const questions = groupedQuestions[subtopic];
        const randomIndex = Math.floor(Math.random() * questions.length);
        randomQuestions.push(questions[randomIndex]);
    }
    return randomQuestions;
};

async function generateQuestions(randomQuestions) {
    const questions = [];

    for (let i = 0; i < randomQuestions.length; i++) {
        if (randomQuestions[i].questionType === "MCQ") {
            const MCQ = await generateMCQ(randomQuestions[i]);

            //console.log("MCQ Question: ", MCQ);
            questions.push(MCQ);
        }
        else if (randomQuestions[i].questionType === "FRQ") {
            const FRQ = await generateFRQ(randomQuestions[i]);

            //console.log("FRQ Question: ", FRQ);
            questions.push(FRQ);
        }
    }
    return questions;
}

const fetchRandomAmount = (array, amount) => {
    let result = [];
    let tempArray = [...array];
    for (let i = 0; i < amount; i++) {
        if (tempArray.length === 0) break;
        const randomIndex = Math.floor(Math.random() * tempArray.length);
        result.push(tempArray[randomIndex]);
        tempArray.splice(randomIndex, 1);
    }
    return result;
};

