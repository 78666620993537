import { fetchLessonById, saveLessonSubtopics, updateCurrentSubtopicIndex } from '../../services/lessonService';
import { createAssignment } from '../CurriculumPlanner/AssignmentGenerator';

class TaskArbiter {
  constructor() {
    this.DUS_weight = 0.6;
    this.TQS_weight = 0.4;
  }

  calculateDUS(dialogueUnderstandingScore) {
    // Assuming dialogueUnderstandingScore is in the range of [0, 100]
    return dialogueUnderstandingScore / 100;
  }

  calculateTQS(questionScores) {
    const totalPoints = questionScores.reduce((sum, score) => sum + score, 0);
    const maxPoints = questionScores.length * 100;
    return totalPoints / maxPoints;
  }

  calculateWeightedAverageScore(DUS, TQS) {
    const normalizedDUS = this.calculateDUS(DUS);
    const normalizedTQS = this.calculateTQS(TQS);
    return (normalizedDUS * this.DUS_weight) + (normalizedTQS * this.TQS_weight);
  }

  getCheckpoint(normalizedScore, checkpoints) {
    let currentCheckpoint = checkpoints[0];

    for (const checkpoint of checkpoints) {
      if (normalizedScore >= checkpoint.threshold) {
        currentCheckpoint = checkpoint;
      } else {
        break;
      }
    }

    return currentCheckpoint;
  }

  async execute(userId, subject, lessonId, lessonTitle, subtopics, currentSubtopicIndex, checkpoints) {

    if (!subtopics) {
      throw new Error('Subtopics not found');
    }
    //console.log("TaskArbiter subtopics() ", subtopics);
    const currentSubtopic = subtopics[currentSubtopicIndex];
    const currentSubtopicName = currentSubtopic.name;
    const currSubtopicScore = currentSubtopic.score;
    const normalizedScore = currSubtopicScore / 100;
    //console.log("TaskArbiter normalized score: ", normalizedScore);
    const currentCheckpoint = this.getCheckpoint(normalizedScore, checkpoints);
    //console.log("TaskArbiter execute curr checkpoint: ", currentCheckpoint);

    // Create an object to store the updatedInstruction and current action
    const result = {
      updatedInstruction: '',
      action: currentCheckpoint.action,
    };
    // Perform actions based on the checkpoint
    // With the duplicates, skipping subtopics can cause the subtopic index to not update properly
    // Create new action that moves on to sub-subtopic and has question to test understanding
    // Adjust thresholds accordingly based on how many questions/quality of questions
    // What if after onResponseGenerated, we generate a code based event that asks the user "does that make sense?"
    // Based on the response from that, we can delegate which event, more details, a question, or example, to do

    /*if (currentCheckpoint.action === 'moveToNextSubtopic') {
      const newIndex = Math.min(currentSubtopicIndex + 1, subtopics.length - 1);
      const nextSubtopic = subtopics[newIndex].name;
      await updateCurrentSubtopicIndex(userId, subject, lessonId, newIndex);
      result.updatedInstruction = `Now, move on to the next subtopic and go into detail: ${nextSubtopic}.`;*/
    if (currentCheckpoint.action === 'provideAnotherQuestion') {
      // Provide another question for the current subtopic
      result.updatedInstruction = `Ask a question [MCQ] or [FRQ] based on the subtopic: ${currentSubtopicName}.`;
    } else if (currentCheckpoint.action === 'continueWithMoreDetails') {
      // Continue with more details about the current subtopic
      result.updatedInstruction = `Now, provide more examples or details about the subtopic: ${currentSubtopicName}.`;
    } else if (currentCheckpoint.action === 'produceAssignment') {
      //Create an assignment
      //await createAssignment(userId, subject, lessonTitle, lessonId);
    } else if (currentCheckpoint.action === 'produceQuiz') {
      // Produce a quiz/homework and put it in the assignments folder
      result.updatedInstruction = `Generate a quiz composed of [MCQ] and [FRQ] questions.`;
    }
    //console.log("TaskArbiter updated Instruction: ", result.updatedInstruction);
    //await saveLessonSubtopics(userId, subject, lessonId, subtopics);
    return result;   //Instead of updatedInstruction, we may just get rid of that
  }
}

export default TaskArbiter;
