import React, { useContext } from 'react';
import styles from './Settings.module.css';
import UserContext from './UserContext';

function Settings() {
  const { developerMode, toggleDeveloperMode } = useContext(UserContext);

  const handleToggleChange = () => {
    const password = window.prompt("Enter password to change Developer Mode:");
    if (password === 'horns@borns') {
      toggleDeveloperMode();
    } else {
      alert('Incorrect password');
    }
  }

  return (
    <div className={styles['course-selection']}>
      <h3>Settings</h3>
      <div className={styles['developer-mode-container']}>
        <label className={styles.switch}>
          <input
            type="checkbox"
            checked={developerMode}
            onChange={handleToggleChange}
          />
          <span className={styles.slider}></span>
        </label>
        <span className={styles['developer-mode-label']}> Developer Mode</span>
      </div>
    </div>
  );
}

export default Settings;