import { initializeApp } from 'firebase/app';
import { getAuth, EmailAuthProvider, connectAuthEmulator } from 'firebase/auth';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import * as firebaseui from 'firebaseui';

const firebaseConfig = {
    apiKey: "AIzaSyAf4sdLTwD5gGQv5Jw7JoX_vTIsNFvE58k",
    authDomain: "eduai-74b65.firebaseapp.com",
    projectId: "eduai-74b65",
    storageBucket: "eduai-74b65.appspot.com",
    messagingSenderId: "788255030733",
    appId: "1:788255030733:web:eb212b8916228bcef99884",
    measurementId: "G-7EQY73PFX2"
};

const firebaseApp = initializeApp(firebaseConfig);
const authInstance = getAuth(firebaseApp);
const firestoreInstance = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

/*if (process.env.NODE_ENV === 'development') {
    connectFirestoreEmulator(firestoreInstance, 'localhost', 8080); // Make sure the host and port match your Firestore emulator
    connectAuthEmulator(authInstance, 'http://127.0.0.1:9099');
    //connectStorageEmulator(storage, 'localhost', 9199); // Use the correct port for the Storage emulator
}*/

const ui = new firebaseui.auth.AuthUI(authInstance);

export { firebaseApp, authInstance, firestoreInstance, storage, ui, EmailAuthProvider };