export class PromptGenerator {
    constructor() {
        // Define the recent question field
        this.recentQuestion = null;
        // Define instructions for the GPT-4 model to act like a teacher
        this.teacherInstruction =
        `You are a teacher, your goal is to help the student (named <S>) understand concepts,
        address their questions, and guide them towards mastery of the subject matter.
        Encourage active learning through images, example problems and explanations.`

        this.imageInstruction =
        `When providing explanations or examples, use diagrams or images if they are funny or can help clarify concepts.
        Generate a 3-6 word description with no links to an image
        and format it as '[Image: description]'.`

        this.behaviorInstruction =
        `Remember, the teacher (you) is labeled '<T>' and will reply in the following format: <T>: {What you will say}.
        Remember, the student is labeled '<S>'. DO NOT act as the student, and never answer for them.
        Do not repeat the last thing you (<T>) said, you need to create a new piece of dialogue involving the next action.`

        this.formattingInstruction =
        `If you need to present any equation or expression, including in questions, you can use one or two '@' signs at the start and end of a KaTeX equation or expression.
        One '@' sign on each side for inline and two on each side for display format. For example: '@E = mc^2@' or '@@E = mc^2@@'.
        You may also bold words that you believe are important using the KaTeX format and @ symbols like this: '@\\\\mathbf{Bold text here}@'.
        When providing examples or explaining complex concepts, use clear and concise language and proper formatting.`

        this.questionInstruction =
        `When asking a multiple-choice or free-response question, format the question with 3 dashes at the beginning and 3 dashes at the end'.
        Add a [MCQ] tag for multiple-choice and a [FRQ] tag for free-response, like this:
        ---
        [FRQ] question text
        ---`

        this.plotInstruction =
        `If you need to provide a box plot for demonstration or for questions, format the box plot like this [BoxPlot: comma-separated values]: 5, 3, 8, 2, 4, 6, 9, 7, 1, 10
        If you need to provide a scatter plot for demonstration or for questions, format the scatter plot like this [ScatterPlot: x1,y1;x2,y2;x3,y3;...]
        If you need to provide a line plot for demonstration or for questions, format the line plot like this [LinePlot: x1,y1;x2,y2;x3,y3;...].`

        // Define a subprompt to trigger the GPT-4 model to select the best action to take
        this.actionInstruction = `
        Carefully analyze the contexts and the student's input and what the teacher and the student said most recently from the recent dialogue.
        Then choose the most appropriate action number from the list below and output it in the format: 
        ACTION: selected action number`

        // Define a set of possible actions for GPT-4 to take based on the student's input
        this.actions = [
            '- provide an explanation',
            '- move to the next subtopic',
            '- provide an example',
            '- check the student answer'
        ];
    }

    generatePrompt(subject, currentLessonTopic, curSubtopic, curSubtopicIndex, studentInput, recentDialogue, context, images, depth, teachingStyle, speakingStyle, lessonPlan) {

        const formattedImages = images.map((image, index) => `Image ${curSubtopicIndex}-${index}: ${image.description}`).join('\n');
        //console.log("IMAGE DESCRIPTIONS: ", images);

        const promptJSON = {
            "ai_tutor": {
              "init": {
                "speaking_style": speakingStyle,
                "goal": "help the student understand concepts, address their questions, and guide them towards mastery of the subject matter",
                "methods": "Encourage active learning through images, example problems and explanations."
              },
              "teaching_style": teachingStyle,
              "rules": [
                "1. Be decisive, take the lead on the student's learning, and never be unsure of where to continue.",
                "2. Follow the lesson plan based on what was covered and what was not. Cover one topic at a time.",
                "3. Always announce the topic (as a markdown header) you will be covering in the lesson plan verbatim before covering it",
                "4. Double-check your knowledge or answer step-by-step if the student requests it.",
                "5. Mention to the student: `say /continue to continue the lesson or /practice to practice` at the end of your response.",
                "6. Always end your response to the student with either a follow-up question or <execute rule 5>",
                "7. You *must* never stray off topic or the lesson plan.",
                "8. You *must* never use [MODAL: ] or [Diagram: ] format in your responses, these are reserved for other processes.",
                "9. Call function 'get_diagram' for creating example problems and explanations especially for Math and Science subjects."
              ],
              "currentLesson": {
                "topic": subject + ": " + currentLessonTopic,
                "subtopic": curSubtopic
              },
              "studentInput": studentInput,
              "formats": {
                "Description": "These are strictly the specific formats you should follow in order. Ignore Desc as they are contextual information.",
                "markdown": true,
                "mathKaTeX": {
                    "enabled": true,
                    "format": "Math equations should be in KaTeX enclosed in dollar signs ($math_equation$)."
                },
                "images": [
                    "Desc: You are hooked up to an image generator and you can now create images to make lessons more engaging. The pictures must be simple without any quanities, words, diagrams, or graphs.",
                    "Desc: Strictly follow the format specified in usage_format, where description is a one to two word description of an image.",
                    "usage_format: [Image: a description]",
                    "example: If you want to highlight centripetal force, show a picture of rollercoaster [Image: rollercoaster]"
                ],
                "Lesson": [
                    "Desc: This is the format you respond for every lesson, you shall teach step-by-step so the student can learn. It is necessary to provide examples and exercises for the student to practice.",
                    "<lesson, and please strictly execute rule 2, 3, 7, and rule 8>",
                    "<strictly execute rule 6>"
                ],
                "practice": [
                    "Desc: This is the format you respond for every practice set, you shall test the student's knowledge, understanding, and problem solving.",
                    "Example Problem: <create and solve the problem step-by-step so the student can understand the next questions>",
                    "Now solve the following problems: <problems>"
                ]
              },
              "lessonPlan": lessonPlan
            }
        };
        /*
        "images": {
                "formattedImages": formattedImages,
                "usageInstruction": "Based on the descriptions of each image, you may use them in your teaching with the following format: [Image: ${curSubtopicIndex}-image number]"
              },
        */
        //console.log("PROMPT TO SEND JSON", promptJSON);
        const systemMessageContent = JSON.stringify(promptJSON);
         // Add the latest student input to the recentDialogue array
         recentDialogue.push({ role: 'user', content: studentInput });

        /*const systemMessageContent =
        `${this.teacherInstruction}
        The lesson is '${currentLessonTopic}'.
        Focus on explaining the subtopic: '${curSubtopic}'
        Consider the latest student input: '${studentInput}'
        You *must* format in markdown.
        You *must* format math in KaTeX like this $math$.
        Consider the following images, their number and descriptions:
        ${formattedImages}
        Based on the descriptions of each image, you may use them in your teaching with the following format: [Image: ${curSubtopicIndex}-image number]
        End with a question for the student.`;

        // Add the latest student input to the recentDialogue array
        recentDialogue.push({ role: 'user', content: studentInput });

        // Add teacher instruction and evaluation instruction
        if (this.recentQuestion) {
            //console.log("generatePrompt() recent Question ", this.recentQuestion);
            systemMessageContent += ` Explain the answer, think step-by-step and evaluate the student's answer to the question "${this.recentQuestion}". Is it correct? If not, provide feedback.`;
            systemMessageContent += ` Based on the student's responses, give the student's answer a score out of 20. Format the score at the end of your response as follows: [QS: yy]`;
        }*/

        const messages = [
            {
                role: 'system',
                content: systemMessageContent
            },
            //...context.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
            ...recentDialogue.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
        ];

        return messages;
    }

    generateDecisionPrompt(currentLessonTopic, curSubtopic, studentInput, recentDialogue, context) {
        const systemMessageContent =
        `${this.teacherInstruction}
        The lesson is '${currentLessonTopic}'.
        Focus on explaining the subtopic: '${curSubtopic}'
        Consider the latest student input: '${studentInput}'
        You *must* format in markdown.
        You *must* format math in KaTeX like this $math$`

        const messages = [
            {
                role: 'system',
                content: systemMessageContent
            },
            ...context.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
            ...recentDialogue.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
        ];
        return messages;
    }

    generateRealDecisionPrompt(currentLessonTopic, curSubtopic, studentInput, teacherInput, recentDialogueObjects) {
        let lastAssistant = null;

        recentDialogueObjects.forEach(obj => {
        if (obj.role === 'assistant') {
            lastAssistant= obj;
        }
        });
        const lastTeacherResponse = lastAssistant;

        const systemMessageContent =
        `You are an action delegating machine analyzing a teacher's conversation with a student.
        The overall subject is: ${currentLessonTopic} and subtopic being discussed is: ${curSubtopic}
        Take your time and analyze the conversation carefully.
        Consider the student's input: '${studentInput}'
        Consider the teacher's latest response: '${teacherInput}'
        Based on the conversation, you *must* select an action the teacher should do next.

        Actions:
        1. provide an explanation
        2. move to the next subtopic
        3. provide an example
        4. check the student answer
        5. give a question to test the student

        If the teacher did not say anything, select an action the teacher should do.
        
        Examples:
        ACTION: 1
        ACTION: 2
        
        After selecting an action, summarize briefly the performance of the student throughout the conversation.
        Highlight the student's apparent strengths and weaknesses for the subject ${currentLessonTopic} and subtopic ${curSubtopic}.
        The overall format will look like the following:
        
        ACTION: {action number}
        SUMMARY: {summary}`

        const messages = [
            {
                role: 'system',
                content: systemMessageContent
            },
            ...recentDialogueObjects.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined)
        ];
        return messages;
    }

    generateActionPrompt(currentLessonTopic, curSubtopicIndex, nextAction, recentDialogue, context, images) {

        const formattedImages = images.map((image, index) => `Image ${curSubtopicIndex}-${index}: ${image.description}`).join('\n');
        //console.log("IMAGE DESCRIPTIONS: ", images);

        const systemMessageContent =
        `You are a teacher, you *must* write a comprehensive passage regarding the action.
        action: ${nextAction.updatedInstruction}
        Consider the following images, their number and descriptions:
        ${formattedImages}
        Based on the descriptions of each image, you may use them in your teaching with the following format: [Image: ${curSubtopicIndex}-image number]
        You *must* format in markdown.
        You *must* format math/formulas in KaTeX like this $math$. 
        ${this.questionInstruction}
        End your response with a question to the student.`

        const messages = [
            {
                role: 'system',
                content: systemMessageContent
            },
            ...context.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
            //...recentDialogue.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
        ];

        return messages;
    }

    generateSubtopicInfoPrompt(curSubtopic, curSubtopicIndex, context, images) {
        const formattedImages = images.map((image, index) => `Image ${curSubtopicIndex}-${index}: ${image.description}`).join('\n');
        //console.log("IMAGE DESCRIPTIONS: ", images);

        const systemMessageContent =
        `You are a teacher, please write a comprehensive passage regarding the topic.
        Topic: Now, let's move on to discuss ${curSubtopic}
        Consider the following images, their number and descriptions:
        ${formattedImages}
        Based on the descriptions of each image, you may embed them in your response with the following format: 
        [Image: ${curSubtopicIndex}-image number]
        You *must* format in markdown.
        You *must* format math/formulas in KaTeX like this $math$
        End your response with a question to the student.`

        const messages = [
            {
                role: 'system',
                content: systemMessageContent
            },
            //...context.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
            //...recentDialogue.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
        ];

        return messages;
    }

    generateQuestionPrompt(currSubtopic, context) {

        const systemMessageContent =
        `You are a teacher, generate a free-response or multiple choice question based on the topic: ${currSubtopic}
        You *must* format in markdown.
        You *must* format math/formulas in KaTeX like this $math$
        You *must* never give the answer. 
        ${this.questionInstruction}
        Begin by saying something like "Let's try a question" before giving the question.`

        const messages = [
            {
                role: 'system',
                content: systemMessageContent
            },
            //...context.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
            //...recentDialogue.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
        ];

        return messages;
    }

    generateCheckAnswerPrompt(studentInput, recentDialogue) {

        const systemMessageContent =
        `You are an assistant who asked a question in their latest message.
        This is the student's answer: ${studentInput}
        Take your time. Explain the answer, and then evaluate the student's answer to the question
        You *must* format in markdown.
        You *must* format math/formulas in KaTeX like this $math$.`

        const messages = [
            {
                role: 'system',
                content: systemMessageContent
            },
            //...context.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
            ...recentDialogue.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
        ];

        return messages;
    }

    generateExamplePrompt(currSubtopic, context) {

        const systemMessageContent =
        `You are a teacher, generate a unique example based on the topic.
        The current topic is: Moving on to another example for ${currSubtopic}
        You *must* format in markdown.
        You *must* format math/formulas in KaTeX like this $math$.
        If you need to provide a box plot for demonstration, format the box plot like this [Box: comma-separated values]
        Begin by saying something like "Let's look at an example" before giving the example.
        End your response by asking if the student understands your example.`

        const messages = [
            {
                role: 'system',
                content: systemMessageContent
            },
            ...context.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
            //...recentDialogue.map(dialogue => ({ role: dialogue.role, content: dialogue.content })).filter(message => message.content !== undefined),
        ];

        return messages;
    }

    updateTeacherInstruction(customPrompt) {
        this.teacherInstruction = customPrompt;
    }
    updateRecentQuestion(question) {
        this.recentQuestion = question;
    }
}
