import { generateText } from "../../services/openai";
import { getCurrentSubtopic } from "../../services/lessonService";
import { updateGradeable, uploadGradeable, parseResponse, cleanup } from "../../services/gradingService";
import { getCourseByName } from "../../services/courseService";

export async function createQuiz(userID, course, lessonId, lessonTitle, lessonPlan) {
    const courseObject = await getCourseByName(userID, course);
    const subtopic = await getCurrentSubtopic(userID, course, lessonId);
    const perfSummary = subtopic.perfSummary;

    const title = generateTitle(subtopic);

    let date = new Date();
    date.setDate(date.getDate() + 3);

    // Set the time to 11:59:59.999pm
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);

    const initialQuiz = {
        title: title,
        creating: true,  // new field indicating the assignment is being created
        score: 0,
        course: course,
        lessonID: lessonId,
        completed: false,
        dueDate: date
    };

    const docId = await uploadGradeable(userID, initialQuiz, "Quizzes");

    const quizPlan = await generateQuizPlan(course, lessonTitle, subtopic, perfSummary, courseObject, lessonPlan);
    //console.log("Quiz Plan", quizPlan);

    let questions;
    try {
        questions = await generateQuestions(course, quizPlan, courseObject);
        //console.log('Questions generated:', questions);
    } catch (error) {
        console.error('Error while generating questions:', error);
    }

    const quiz = {
        ...initialQuiz,
        creating: false,  // update `creating` to `false` to indicate the quiz has been fully created
        questions: questions,  // add the generated questions
        quizId: docId
    };

    //console.log("course: ", course);
    //console.log("Quiz", quiz);

    await updateGradeable(userID, docId, quiz, "Quizzes");
    return;
}

async function generateQuizPlan(courseName, lessonTitle, subtopic, perfSummary, courseObject, lessonPlan) { //Add Lesson Plan
    const depth = courseObject.depthLevel ? courseObject.depthLevel : "Undergraduate";
    //console.log("LESSON PLAN QUIZ PART", lessonPlan);
    const materialCovered = lessonsCoveredString(lessonPlan);

    const quizPlanPrompt = {
        'role': 'Quiz Plan Generator',
        'course_name': courseName,
        'lesson': lessonTitle,
        'subtopic': subtopic.name,
        'standard': 'CPALMS Florida Department of Education Standards/Strands',
        'depth_level': {
            "description": "This is the level of depth of the content the student wants to learn",
            "currentDepth": depth
        },
        'question_types': [
            "MCQ",
            "FRQ",
        ],
        'number_of_questions': {
            "description": "This is how many questions the quiz will have. The 'FRQ' and 'MCQ' fields store the number of Free Response and Multiple Choice questions that will be present in the assignment respectively.",
            "FRQ": 1,
            "MCQ": 1,
        },
        'rules': [
            "1. Must output valid JSON as specified in output_format",
            "2. Generate quiz plan for course based on subtopic",
            "3. The output should be an array of objects, each containing the layout for generation of a specific question",
            "4. question_topics should be a small description between one sentence and three sentences in length, detailing what the problem generated should specifically be about",
            "5. determine question topics based on the strengths and weaknesses outlined in the 'perfSummary', more questions for weaker areas.",
            "6. question_topics should be unique",
            "7. ONLY generate question_topics covered by the topics/subpoints outlined in 'material_covered'"
        ],
        'perfSummary': perfSummary,
        'material_covered': materialCovered,
        'output_format': `
            [
                {   
                    "question_topic": [Insert Question Topic Here],
                    "question_type": [Insert Question Type Here],
                    "depth_level": [Insert Depth Level Here]
                },
                // ... and so on for all questions
            ]
        `
    };

    console.log("quiz Plan Prompt: ", quizPlanPrompt);
    const quizPlanJSONString = JSON.stringify(quizPlanPrompt);

    const messages = [
        { "role": "system", "content": quizPlanJSONString },
    ];

    const quizPlanResponse = await generateText(messages);
    let quizPlan = JSON.parse(quizPlanResponse);
    //console.log("Quiz Plan: ", quizPlan);
    return quizPlan;
}

function lessonsCoveredString(lessonPlan) {
    let lessons;
    let coveredLessons;

    try {
        // First, attempt to use lessonPlan.lessonPlan
        lessons = lessonPlan.lessonPlan;
        coveredLessons = processLessons(lessons);
    } catch (err) {
        // If that fails, fall back to lessonPlan
        try {
            lessons = lessonPlan;
            coveredLessons = processLessons(lessons);
        } catch (err) {
            console.error("Unable to iterate over lessonPlan:", err);
            return null;
        }
    }

    if(coveredLessons.length === 0) return null;

    // Format the lessons into a string or JSON. This example formats as JSON.
    let result = coveredLessons.map(lesson => ({
        topic: lesson.topic,
        subpoints: lesson.subPoints
    }));
    return JSON.stringify(result);
}

function processLessons(lessons) {
    let coveredLessons = [];
    let count = 0;

    for(let lesson of lessons) {
        coveredLessons.push(lesson);
        count++;
        if(count >= 2) break; // stop adding lessons once we've reached two covered lessons
        
    }
    return coveredLessons;
}


async function generateQuestions(courseName, quizPlan, courseObject) {
    //console.log("Generating Questions Begins!");
    let questions = [];

    for (let i = 0; i < quizPlan.length; i++) {
        //console.log("Question Iteration # ", i);
        if (quizPlan[i].question_type === "MCQ") {
            const MCQ = await generateMCQ(courseName, quizPlan[i], courseObject);

            if (MCQ) {
                questions.push(MCQ);
            } else {
                //console.log("MCQ generation failed for:", quizPlan[i]);
            }

            //console.log("MCQ Question: ", MCQ);
        }
        else if (quizPlan[i].question_type === "FRQ") {
            const FRQ = await generateFRQ(courseName, quizPlan[i], courseObject);

            if (FRQ) {
                questions.push(FRQ);
            } else {
                //console.log("FRQ generation failed for:", quizPlan[i]);
            }

            //console.log("FRQ Question: ", FRQ);
        }
    }
    return questions;
}
// May be in JSON format later
async function generateMCQ(courseName, quizPlan, courseObject) {
    const questionWordingStyle = courseObject.depthLevel ?  "Please word the question as if you were writing for a " + courseObject.depthLevel + " student.": "Please word the question as if you were writing for an undergraduate student.";
    try {
        const questionPrompt = {
            'role': 'Multiple Choice Question Generator',
            'standard': 'CPALMS Florida Department of Education Standards/Strands',
            'course_name': courseName,
            'question_topic': quizPlan.question_topic,
            'question_wording': questionWordingStyle,
            'depth_level': {
                "description": "This is the level of depth of the content the student wants to learn",
                "currentDepth": quizPlan.depth_level
            },
            'rules': [
                "1. Must output valid JSON as specified in output_format",
                "2. Whenever you want to denote a newline for formatting use this character: '\\n'. Make this character is outside of KaTeX formatting so it is processed correctly.",
                "3. The question created should be specific and based off of the 'question_topic'",
                "4. The question created should be appropriate for the 'depth_level' specified",
                "5. 'Markdown' and 'Math KaTeX ($)' formatting should be used respectively and when appropriate to create all components of the output"
            ],
            'format_rules': {
                "Description": "These are strictly the specific formats you should follow in order.",
                "markdown": true,
                "mathKaTex": true,
                "Question": "<the format strictly outlined in output_format>"
            },
            'output_format': `
                {
                    "questionType": MCQ,
                    "questionText": "[Insert Question Here]",
                    "explanation": "[Insert Question Explanation Here]",
                    "options": {
                        "a": "[Insert Option 'a' Here]",
                        "b": "[Insert Option 'b' Here]",
                        "c": "[Insert Option 'c' Here]",
                        "d": "[Insert Option 'd' Here]"
                    },
                    "correctAnswer": "[Insert Correct Option Here]",
                    "studentAnswer": null
                }
            `,
        };

        //console.log("Question Prompt: ", questionPrompt);
        const questionPromptJSONString = JSON.stringify(questionPrompt);

        const messages = [
            { "role": "system", "content": questionPromptJSONString },
        ];

        const questionString = await generateText(messages);
        let question = JSON.parse(questionString);
        return question;
    }
    catch (error) {
        console.error('Error generating MCQ question:', error);
        return;
    }
}

// May remove aiAnswer in the future, may change to JSON format
async function generateFRQ(courseName, quizPlan, courseObject) {
    const questionWordingStyle = courseObject.depthLevel ?  "Please word the question as if you were writing for a " + courseObject.depthLevel + " student.": "Please word the question as if you were writing for an undergraduate student.";
    try {
        const questionPrompt = {
            'role': 'Free Response Question Generator',
            'standard': 'CPALMS Florida Department of Education Standards/Strands',
            'course_name': courseName,
            'question_topic': quizPlan.question_topic,
            'selected_depth_level': quizPlan.depth_level,
            'question_wording': questionWordingStyle,
            'rules': [
                "1. Must *ONLY* output valid output as specified in output_format",
                "2. The question created should be specific and based off of the 'question_topic'",
                "3. The question created should be appropriate for the 'depth_level' specified"
            ],
            'format_rules': {
                "Description": "These are strictly the specific formats you should follow in order.",
                "markdown": true,
                "mathKaTex": true
            },
            'output_format':
                `QUESTIONTEXT:
                questionText`
        };

        //console.log("Question Prompt: ", questionPrompt);
        const questionPromptJSONString = JSON.stringify(questionPrompt);

        const messages = [
            { "role": "system", "content": questionPromptJSONString },
        ];

        const response = await generateText(messages);
        const parsedResponse = parseResponse(response, [['QUESTIONTEXT', 'questionText', 'string']]);

        parsedResponse["questionType"] = "FRQ";
        return parsedResponse;
    }
    catch (error) {
        console.error('Error generating question:', error);
        return;
    }
}

function generateTitle(subtopic) {
    return subtopic.name;
}