import { generateText } from "./openai";
import { firestoreInstance } from './firebase';
import { collection, doc, getDocs, getDoc, setDoc, addDoc, updateDoc, serverTimestamp, arrayUnion, arrayRemove, writeBatch } from 'firebase/firestore';
import { fetchImage } from "./imageSearch";
import { getAllSubtopics } from "./lessonService";
import { uploadGradeable } from "./gradingService";


async function createLessonSubtopics(courseName, title) {
    const subtopicPrompt = {
        'role': 'assistant',
        'content': `
            Let's create subtopics for a ${courseName} lesson titled "${title}". 
            We need 5 unique subtopics relevant to the lesson title. 
            Please generate ONLY the JSON as outlined in "output_format" in your response.`,
        'output_format': `
        [
          {"name": "[Insert Subtopic Name Here]"},
          {"name": "[Insert Subtopic Name Here]"},
          {"name": "[Insert Subtopic Name Here]"},
          {"name": "[Insert Subtopic Name Here]"},
          {"name": "[Insert Subtopic Name Here]"}
        ]`
    }

    const subtopicPromptString = JSON.stringify(subtopicPrompt)
    const subtopicMessage = [{ role: 'system', content: subtopicPromptString }];
    const subtopicsResponse = await generateText(subtopicMessage);
    // Parse the response into a valid JSON format
    let subtopics = JSON.parse(subtopicsResponse);
    
    // Add the "score" field to each subtopic
    subtopics = subtopics.map(subtopic => {
        subtopic.score = 0;
        return subtopic;
    });
    //console.log("SUBTOPICS", subtopics);
    return subtopics;
}

export async function createSemesterCourseContent(courseName, courseLength, setCreationProgress) {
    if (courseName.toLowerCase() === 'assignments' 
            || courseName.toLowerCase() === 'new course' 
            || courseName.toLowerCase() === 'quizzes'
            || courseName.toLowerCase() === 'exams') {
       return; // Prevent from continuing with course creation
    }

    const courseContent = {};
    courseContent[courseName] = [];
    const depth = "Undergraduate";
    // Generate 16 high-level topics for the semester in one shot
    const semesterPrompt = {
        'role': 'lesson plan generator',
        'course_name': courseName,
        'standard': 'CPALMS Florida Department of Education Standards/Strands',
        'length': {
            "description": "This is how long the course will be. One lesson corresponds to one week of work. This determines the number of lessons and content covered. The shortest length is A, and the longest is C.",
            "length_options": {
                "A": "midterm study (4 weeks)",
                "B": "summer course (12 weeks)",
                "C": "semester long (16 weeks)"
            },
            "setLength": courseLength
        },
        'depth': {
            "description": "This is the level of depth of the content the student wants to learn.",
            "setDepth": depth
        },
        'rules': [
            "1. Must output valid JSON as specified in output_format",
            "2. Generate lesson plan for course described in course_name",
        ],
        'output_format': `
            [
                {"title": "Lesson 1: [Insert Lesson Topic Here]"},
                {"title": "Lesson 2: [Insert Lesson Topic Here]"},
                // ... and so on for all lessons
            ]
        `,
    };
    const semesterPromptString = JSON.stringify(semesterPrompt);
    const semesterMessage = [{ role: 'system', content: semesterPromptString }];
    const semesterResponse = await generateText(semesterMessage);
    // Parse response and extract lesson topics
    let lessonPlan;
    try {
      lessonPlan = JSON.parse(semesterResponse);
    } catch(e) {
      console.error("Failed to parse lesson plan JSON:", e);
      return null;
    }
  
    // For each lesson, generate a title, description and the 5 subtopics
    for(let i = 0; i < lessonPlan.length; i++) {
      const title = lessonPlan[i].title;
    
      setCreationProgress((i + 1) / lessonPlan.length * 100); 
      /*const titlePrompt = `Give me a unique title for a ${courseName} lesson on the topic "${topic}".`;
      const titleMessage = { role: 'system', content: titlePrompt };
      const titleResponse = await generateText(titleMessage);
      const title = titleResponse.choices[0].text.trim();*/
  
      const subtopics = await createLessonSubtopics(courseName, title);

      const descriptionPrompt = `Give me a 1 sentence description for a ${courseName} lesson titled "${title}". Consider the subtopics in the lesson: ${subtopics}`;
      const descriptionMessage = [{ role: 'system', content: descriptionPrompt }];
      const descriptionResponse = await generateText(descriptionMessage, "gpt-3.5-turbo-0613");
      const description = descriptionResponse;
  
      courseContent[courseName].push({
        "title": title,
        "description": description,
        "content": "",
        "understanding": 0.00,
        "currentSubtopicIndex": 0,
        "subtopics": subtopics,
        "checkpoints": [
          {"threshold": 0.5, "action": "continueWithMoreDetails"},
          {"threshold": 0.7, "action": "provideAnotherQuestion"},
          {"threshold": 0.8, "action": "produceQuiz"},
          {"threshold": 0.9, "action": "produceAssignment"},
          {"threshold": 1, "action": "moveToNextSubtopic"}
        ]
      });
    }
    setCreationProgress(0);
    return courseContent;
  }

  export const fetchUserCourses = async (userId) => {
    // Get a reference to the user's document
    const userDocRef = doc(firestoreInstance, `users/${userId}`);
  
    // Fetch the user's document
    const userDocSnapshot = await getDoc(userDocRef);
  
    // Check if the user's document exists
    if (!userDocSnapshot.exists()) {
      console.error(`No document found for user with ID: ${userId}`);
      return [];
    }
  
    // Get the subjects field from the user's document
    const userCourses = userDocSnapshot.data().courses;
  
    // If the subjects field is not defined or not an array, return an empty array
    if (!Array.isArray(userCourses)) {
      console.error(`Invalid subjects field for user with ID: ${userId}`);
      return [];
    }
  
    return userCourses;
  };

  export const getCourseByName = async (userId, courseName) => {
    try {
      // Get a reference to the user's document
      const userDocRef = doc(firestoreInstance, `users/${userId}`);
  
      // Fetch the current data from Firestore
      const userSnapshot = await getDoc(userDocRef);
      const userData = userSnapshot.data();
  
      // Find the course with the given name
      const course = userData.courses.find((course) => course.course === courseName);
      return course;

    } catch (error) {
      //console.log("getCourseByName() Course not found");
      return null;
    }
  }

  export const addUserCourse = async (userId, courseName, depthLevel, teachingStyle, speakingStyle) => {
    try {
        let imageUrl = await generateCourseThumbnail(courseName);
        
        // Use a default image URL if imageUrl is null
        if (!imageUrl) {
            imageUrl = '../components/ui/assets/defaultImage.png'; // Change this to the path of your default image
        }
        
        // Get a reference to the user's document
        const userDocRef = doc(firestoreInstance, `users/${userId}`);
        
        // Create a new course object
        const newCourse = {
            course: courseName,
            depthLevel: depthLevel,
            teachingStyle: teachingStyle,
            speakingStyle: speakingStyle,
            imageUrl: imageUrl,
            grade: [
                { assignmentPoints: 0, totalassignmentPoints: 0 },
                { quizPoints: 0, totalquizPoints: 0 },
                { examPoints: 0, totalexamPoints: 0 }
            ]
        };
        
        // Use Firestore's arrayUnion to add the new course to the array of courses
        await updateDoc(userDocRef, {
            courses: arrayUnion(newCourse)
        });
        
        //console.log(`Course "${courseName}" added for user with ID: ${userId}`);
    } catch (error) {
        console.error(`Failed to add course for user with ID: ${userId}`, error);
    }
  };

  export const removeUserCourse = async (userId, courseName) => {
    try {
      // Get a reference to the user's document
      const userDocRef = doc(firestoreInstance, `users/${userId}`);
  
      // Fetch the current data from Firestore
      const userSnapshot = await getDoc(userDocRef);
      const userData = userSnapshot.data();
  
      // Find the course with the given name
      const courseToRemove = userData.courses.find((course) => course.course === courseName);
  
      if (!courseToRemove) {
        //console.log(`Course "${courseName}" not found for user with ID: ${userId}`);
        return;
      }
  
      // Use Firestore's arrayRemove to remove the course from the array of courses
      await updateDoc(userDocRef, {
        courses: arrayRemove(courseToRemove)
      });

      deleteCourseCollection(userId, courseName);
  
      //console.log(`Course "${courseName}" removed for user with ID: ${userId}`);
    } catch (error) {
      console.error(`Failed to remove course for user with ID: ${userId}`, error);
    }
  };

  export const deleteCourseCollection = async (userId, courseName) => {
    // Get a reference to the collection
    const subjectCollectionRef = collection(firestoreInstance, `users/${userId}/${courseName}`);
  
    // Fetch all documents in the collection
    const snapshot = await getDocs(subjectCollectionRef);
  
    // Initialize a batch
    const batch = writeBatch(firestoreInstance);
  
    // Add each document to the batch for deletion
    snapshot.docs.forEach(doc => {
      batch.delete(doc.ref);
    });
  
    // Commit the batch
    await batch.commit();
  };
  
  export const generateCourseThumbnail = async (courseName) => {
    // Construct the prompt and message object array
    let prompt = "Generate a 1 word query to find an image related to a course. The word should be simple. For example, Calculus -> Math, Physics -> Rollercoaster, React.js -> code, things that can be easily related to the course. The course is: " + courseName + ".";
    let messages = [{ role: 'system', content: prompt }];
  
    // Generate the text using GPT-3.5
    let generatedTextResponse = await generateText(messages);
    //console.log("IMAGE QUERY ", generatedTextResponse);
    // Check if text was successfully generated
    if (!generatedTextResponse) {
      console.error("Failed to generate text");
      return null;
    }
  
    let generatedText = generatedTextResponse;
  
    // Query the image API
    let imageUrl;
    try {
      imageUrl = await fetchImage(generatedText);
    }
    catch {
      imageUrl = '../components/ui/assets/defaultImage.png';
    }
  
    // Return the image URL
    return imageUrl;
  };

  export const createInitialGradeables = async (userID, course, lesson, interval, gradeableType) => {
    const subtopics = await getAllSubtopics(userID, course, lesson._id);
    let date = new Date();

    for (let i = 0; i < subtopics.length; i++) {
        const subtopic = subtopics[i];
        const title = subtopic.name;

        // Set due date to 1 week apart for each subtopic
        date.setDate(date.getDate() + interval);
        
        // Set the time to 11:59:59.999pm
        date.setHours(23);
        date.setMinutes(59);
        date.setSeconds(59);
        date.setMilliseconds(999);

        const initialGradeable = {
            title: title,
            creating: true,  // new field indicating the assignment is being created
            score: 0,
            course: course,
            lessonID: lesson._id,
            completed: false,
            dueDate: date.toISOString() // include time component
        };

        const docId = await uploadGradeable(userID, initialGradeable, gradeableType);
    }
};
  
  