import React, { useState, useEffect } from 'react';
import styles from './cards.module.css'
import mathImage from '../../ui/assets/math.jpg'
import scienceImage from '../../ui/assets/science.jpg';
import defaultImage from '../../ui/assets/defaultImage.png';
import { useNavigate, useRouteMatch, Routes, Route } from 'react-router-dom';
import ExamsPage from '../../student/assignments/ExamsPage';
import { authInstance } from '../../../services/firebase';
import { fetchUserCourses } from '../../../services/courseService';
import { fetchAllGradeables } from '../../../services/gradingService';

function Exams() {
    const [courseList, setCourseList] = useState([]);
    const [examsList, setExamsList] = useState([]);
    const userId = authInstance.currentUser.uid;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchCourses = async () => {
            const userCourses = await fetchUserCourses(userId); // Modify this function to return course objects
            const courseArray = userCourses.map(course => ({
                name: course.course, // Use the 'course' property of each course object
                image: getCourseImage(course), // Use the 'imageUrl' property, or default images if 'imageUrl' is not available
                creating: false
            }));
            setCourseList(courseArray);
        };
        const fetchExams = async () => {
            const userExams = await fetchAllGradeables(userId, "Exam"); // Modify this function to return course objects
            const examsArray = userExams.map(exam => ({
                course: exam.course,
                dueDate: exam.dueDate.toDate(),
                completed: exam.completed
            }));
            setExamsList(examsArray);
        };
    
        fetchCourses();
        fetchExams();
    }, [userId]);    

    function getCourseImage(course) {
        // If the course has an image URL, use it
        if (course.imageUrl) {
            return course.imageUrl;
        }
        
        // Otherwise, return a default image based on the course name
        switch (course.course) {
            case 'Math':
                return mathImage;  // replace with the URL or path of your default Math image
            case 'Science':
                return scienceImage;  // replace with the URL or path of your default Science image
            default:
                return defaultImage;  // replace with the URL or path of your default image for other courses
        }
    }

    const handleCourseClick = (courseName) => {
        //console.log("COURSE NAME", courseName);
        const encodedCourseName = encodeURIComponent(courseName);
        navigate(`${encodedCourseName}`);
    };

    return (
        <div className={styles['course-selection']}>
            <Routes>
                <Route path="/" element={
                    <>  
                        <h3>Exams</h3>
                        <div className={styles['course-list']}>
                            {courseList.map((course) => (
                                <div
                                    key={course.name}
                                    className={styles['course-card']}
                                    onClick={() => handleCourseClick(course.name)}
                                >
                                    <img src={course.image} alt={course.name} />
                                    <div className={styles['course-card-content']}>
                                        <h3>{course.name}</h3>
                                        <div className={styles['course-subtitle']}>
                                            <span>Exams</span> 
                                            {examsList.find(exam => exam.course === course.name && !exam.completed) && <span className={styles['not-completed-tag']}>Incomplete</span>}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </>
                } />
                <Route path=":courseName" element={<ExamsPage />} />
            </Routes>
        </div>
    );
}

export default Exams;
