import { generateText } from "../../services/openai";
import { getCurrentSubtopic } from "../../services/lessonService";
import { updateGradeable, uploadGradeable, parseResponse, cleanup } from "../../services/gradingService";
import { firebase } from 'firebase/app';
import { getFirestore, Timestamp } from 'firebase/firestore';


export async function createAssignment(userID, course, lessonTitle, lessonId) {
    const subtopic = await getCurrentSubtopic(userID, course, lessonId);
    const perfSummary = subtopic.perfSummary;

    const title = await generateTitle(subtopic);

    let date = new Date();

    //date.setMinutes(date.getMinutes() + 2);

    date.setDate(date.getDate() + 3);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    date.setMilliseconds(999);

    const initialAssignment = {
        title: title,
        score: 0,
        course: course,
        lessonID: lessonId,
        subtopic: subtopic.name,
        status: "Creating",
        dueDate: date,
    };

    const docId = await uploadGradeable(userID, initialAssignment, "Assignments");

    const assignmentPlan = await generateAssignmentPlan(course, lessonTitle, subtopic, perfSummary)
    //console.log(assignmentPlan)

    let questions;
    try {
        questions = await generateQuestions(course, assignmentPlan);
        //console.log('Questions generated:', questions);
    } catch (error) {
        console.error('Error while generating questions:', error);
    }

    const assignment = {
        ...initialAssignment,
        status: "In Progress",  // update `creating` to `false` to indicate the assignment has been fully created
        questions: questions  // add the generated questions
    };

    //console.log("course: ", course);
    //console.log(assignment);

    await updateGradeable(userID, docId, assignment, "Assignments");
    return;

}

async function generateAssignmentPlan(courseName, lessonTitle, subtopic, perfSummary) { //Add Lesson Plan
    const assignmentPlanPrompt = {
        'role': 'Assignment Plan Generator',
        'course_name': courseName,
        'lesson': lessonTitle,
        'subtopic': subtopic.name,
        'standard': 'CPALMS Florida Department of Education Standards/Strands',
        'question_types': [
            "MCQ",
            "FRQ",
        ],
        'number_of_questions': {
            "description": "This is how many questions the assignment will have. The 'FRQ' and 'MCQ' fields store the number of Free Response and Multiple Choice questions that will be present in the assignment respectively.",
            "FRQ": 1,
            "MCQ": 4,
        },
        'rules': [
            "1. Must output valid JSON as specified in output_format",
            "2. Generate assignment plan for course based on subtopic",
            "3. FRQ question layouts are made after MCQ question layouts", //Optional
            "4. The output should be an array of objects, each containing the layout for generation of a specific question",
            "5. question_topics should be a small description between one sentence and one paragraph in length, detailing what the problem generated should specifically be about",
            "6. question_topics should ONLY be based on what the student has been learning, which can be obtained from the 'perfSummary'",
            "7. question_topics should be unique",
            "8. The depth_level of each question should be decided by observing the students 'perfSummary' and tailoring question difficulty to best help the student improve",
        ],
        "depth_levels": {
            "1/10": "Elementary (Grade 1-6)",
            "2/10": "Middle School (Grade 7-9)",
            "3/10": "High School (Grade 10-12)",
            "4/10": "College Prep",
            "5/10": "Undergraduate",
            "6/10": "Graduate",
            "7/10": "Master's",
            "8/10": "Doctoral Candidate",
            "9/10": "Postdoc",
            "10/10": "Ph.D"
        },
        'perfSummary': perfSummary,
        'output_format': `
            [
                {   
                    "question_topic": [Insert Question Topic Here],
                    "question_type": [Insert Question Type Here],
                    "depth_level": [Insert Depth Level Here]
                },
                // ... and so on for all questions
            ]
        `
    };

    //console.log("Assignment Plan Prompt: ", assignmentPlanPrompt);
    const assignmentPlanJSONString = JSON.stringify(assignmentPlanPrompt);

    const messages = [
        { "role": "system", "content": assignmentPlanJSONString },
    ];

    const assignmentPlanResponse = await generateText(messages);
    let assignmentPlan = JSON.parse(assignmentPlanResponse);
    //console.log("Assignment Plan: ", assignmentPlan);
    return assignmentPlan;
}

async function generateQuestions(courseName, assignmentPlan) {
    //console.log("Generating Questions Begins!");
    let questions = [];

    for (let i = 0; i < assignmentPlan.length; i++) {
        //console.log("Question Iteration # ", i);
        if (assignmentPlan[i].question_type === "MCQ") {
            const MCQ = await generateMCQ(courseName, assignmentPlan[i]);

            if (MCQ) {
                questions.push(MCQ);
            } else {
                //console.log("MCQ generation failed for:", assignmentPlan[i]);
            }

            //console.log("MCQ Question: ", MCQ);
        }
        else if (assignmentPlan[i].question_type === "FRQ") {
            const FRQ = await generateFRQ(courseName, assignmentPlan[i]);

            if (FRQ) {
                questions.push(FRQ);
            } else {
                //console.log("FRQ generation failed for:", assignmentPlan[i]);
            }

            //console.log("FRQ Question: ", FRQ);
        }
    }
    return questions;
}
// May be in JSON format later
async function generateMCQ(courseName, questionPlan) {
    try {
        const questionPrompt = {
            'role': 'Multiple Choice Question Generator',
            'standard': 'CPALMS Florida Department of Education Standards/Strands',
            'course_name': courseName,
            'question_topic': questionPlan.question_topic,
            'selected_depth_level': questionPlan.depth_level,
            'rules': [
                "1. Must output valid JSON as specified in output_format",
                "2. The question created should be specific and based off of the 'question_topic'",
                "3. The question created should be appropriate for the 'depth_level' specified",
                "4. 'Markdown' and 'Math KaTeX ($)' formatting should be used respectively and when appropriate to create all components of the output",
                "5. Whenever you want to denote a newline for formatting use this character: '\\n'. Make sure this character is outside of KaTeX formatting so it is processed correctly."
            ],
            'format_rules': {
                "markdown": true,
                "mathKaTex": true
            },
            'output_format': `
                {
                    "questionType": MCQ,
                    "questionText": "[Insert Question Here]",
                    "explanation": "[Insert Question Explanation Here]",
                    "options": {
                        "a": "[Insert Option 'a' Here]",
                        "b": "[Insert Option 'b' Here]",
                        "c": "[Insert Option 'c' Here]",
                        "d": "[Insert Option 'd' Here]"
                    },
                    "correctAnswer": "[Insert Correct Option Here]",
                    "studentAnswer": null
                }
            `,
        };

        //console.log("Question Prompt: ", questionPrompt);
        const questionPromptJSONString = JSON.stringify(questionPrompt);

        const messages = [
            { "role": "system", "content": questionPromptJSONString },
        ];

        const questionString = await generateText(messages);
        let question = JSON.parse(questionString);
        return question;
    }
    catch (error) {
        console.error('Error generating MCQ question:', error);
        return;
    }
}

// May remove aiAnswer in the future, may change to JSON format
async function generateFRQ(courseName, questionPlan) {
    try {
        const questionPrompt = {
            'role': 'Free Response Question Generator',
            'standard': 'CPALMS Florida Department of Education Standards/Strands',
            'course_name': courseName,
            'question_topic': questionPlan.question_topic,
            'selected_depth_level': questionPlan.depth_level,
            'rules': [
                "1. Must *ONLY* output valid output as specified in output_format",
                "2. The question created should be specific and based off of the 'question_topic'",
                "3. The question created should be appropriate for the 'depth_level' specified"
            ],
            'format_rules': {
                "markdown": true,
                "mathKaTex": true
            },
            'output_format':
                `QUESTIONTEXT:
                questionText`
        };

        //console.log("Question Prompt: ", questionPrompt);
        const questionPromptJSONString = JSON.stringify(questionPrompt);

        const messages = [
            { "role": "system", "content": questionPromptJSONString },
        ];

        const response = await generateText(messages);
        const parsedResponse = parseResponse(response, [['QUESTIONTEXT', 'questionText', 'string']]);

        parsedResponse["questionType"] = "FRQ";
        return parsedResponse;
    }
    catch (error) {
        console.error('Error generating question:', error);
        return;
    }
}

// TO DO
async function generateTitle(subtopic) {
    return subtopic.name;
}

// TO DO
async function parseQuestion(question) {
    return JSON.parse(question);
}

async function parseMCQ(questionText) {
    const regex = /\[[a-z]\)\s[^]]+\]/g;
    const matches = questionText.match(regex) || [];
    const options = matches.map(match => match.slice(4, -1));
    return options;
}