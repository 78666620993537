import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../../../App.css';
import styles from './StudentDashboard.module.css';
import testData from '../../../testData.json';
import Statistics from '../Statistics/Statistics';

function StudentDashboard() {
  const [students, setStudents] = useState([]);

  useEffect(() => {
    // axios.get('/students')
    //  .then(res => {
    //    setStudents(res.data);
    //  })
    //  .catch(err => {
    //    //console.log(err);
    //  });
    setStudents(testData.students);
  }, []);

  return (
    <div className={styles.studentDashboard}>
      <Statistics className={styles.statisticsComponent} students={students} />
      <div className={styles.studentsListContainer}>
        <div className={styles.studentsList}>
          <main>
            <ul className={styles.students}>
              {students.map(student => (
                <li key={student.id}>
                  <h2>{student.name}</h2>
                  <p>Grade: {student.gradeLevel}</p>
                  <p>Subjects: {student.subjects.join(', ')}</p>
                  <p>Homework due: {student.homeworkDue}</p>
                </li>
              ))}
            </ul>
          </main>
        </div>
      </div>
    </div>
  );
}

export default StudentDashboard;