import ContextStorage from './ContextStorage';
import ContextFormatter from './ContextFormatter';

class ContextManager {
  constructor() {
    this.contextStorage = new ContextStorage();
    this.contextFormatter = new ContextFormatter();
  }

  async getSimilarContext(userId, latestStudentResponse, lessonTitle) {
    //const oldContext = await this.contextStorage.getOldContext(userId, subject, lessonId);
    // Format the context
    const formattedNewContext = await this.contextFormatter.format(userId, latestStudentResponse, lessonTitle);
    return formattedNewContext;
  }

  //sets new context objects in pinecone and context field in firebase
  async setContext(userId, latestStudentResp, latestTeacherResp, subject, lessonId, lessonTitle) {
    ////console.log("ContextManager setContext() latestTeacherResp: ", latestTeacherResp);
    ////console.log("ContextManager setContext() ", newContextWithLatestResp);
    //const latestTeacherRespProcessed = latestTeacherResp.replace(/(<T>:|\r\n|\n|\r)/gm, "").trim();
    ////console.log("ContextManager setContext() processed ", latestTeacherRespProcessed);
    //The userid, lsr, ltr, context is for pinecone, the rest is for firebase
    await this.contextStorage.addToPineContext(userId, latestStudentResp, latestTeacherResp, lessonTitle);
    await this.contextStorage.setRecentDialogue(userId, subject, lessonId, latestStudentResp, latestTeacherResp);
  }

  async setContent(userId, latestStudentResp, latestTeacherResp, subject, lessonId, lessonTitle) {
    await this.contextStorage.setContent(userId, subject, lessonId, latestStudentResp, latestTeacherResp);
  }
}

export default ContextManager;