import React, { useState } from 'react';
import styles from './QuestionItem.module.css';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math'
import rehypeKatex from 'rehype-katex'
import TextField from '@mui/material/TextField';

function QuestionItem({ question, index, onOptionSelected, completed }) {
  const { questionText, options = {}, aiAnswer, studentAnswer } = question || {};
  const [selectedOption, setSelectedOption] = useState(studentAnswer);

  const handleTextChange = (event) => {
    if (!completed) {
      onOptionSelected(index, event.target.value);
    }
  };

  const handleOptionChange = (value) => {
    if (!completed) { // only change the selectedOption if the assignment is not completed
      if (selectedOption === value) {
        setSelectedOption(null);
        onOptionSelected(index, null);
      } else {
        setSelectedOption(value);
        onOptionSelected(index, value);
      }
    }
  };

  const sortedOptions = Object.entries(options).sort((a, b) => a[0].localeCompare(b[0]));
  if (question.questionType === 'FRQ') {
    return (
      <div className={styles['card-container']}>
        <Card style={{ borderRadius: '15px' }} className={`${styles['question-card']} `}>
          <CardContent>
            <div className={styles['question-header']} variant="h6" component="h2">{`Question ${index + 1}`}</div>
            <ReactMarkdown
              className={styles['markdown-content']}
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeKatex]}
            >
              {questionText}
            </ReactMarkdown>
          </CardContent>
        </Card>
        {!completed && (
          <TextField
            id="outlined-multiline-static"
            label="Your answer"
            multiline
            rows={12}
            defaultValue={studentAnswer}
            variant="outlined"
            fullWidth
            onChange={handleTextChange}
          />
        )}
        {completed && (
          <>
            <Card style={{ borderRadius: '15px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)', marginBottom:'1rem' }}>
              <CardContent>
                <div className={styles['question-header']} variant="h6" component="h2">Your Answer</div>
                <ReactMarkdown
                  className={styles['markdown-content']}
                  remarkPlugins={[remarkMath]}
                  rehypeKatex={[rehypeKatex]}
                >
                  {studentAnswer}
                </ReactMarkdown>
              </CardContent>
            </Card>
            <Card style={{ borderRadius: '15px', boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)' }}>
              <CardContent>
                <div className={styles['question-header']} variant="h6" component="h2">Evaluation</div>
                <ReactMarkdown
                  className={styles['markdown-content']}
                  remarkPlugins={[remarkMath]}
                  rehypePlugins={[rehypeKatex]}
                >
                  {question.evaluation}
                </ReactMarkdown>
              </CardContent>
            </Card>
          </>
        )}
      </div>
    );
  }
  else {
    return (
      <div className={styles['card-container']}>
        <Card style={{ borderRadius: '15px' }} className={`${styles['question-card']} `}>
          <CardContent>
            <div className={styles['question-header']} variant="h6" component="h2">{`Question ${index + 1}`}</div>
            <ReactMarkdown
              className={styles['markdown-content']}
              remarkPlugins={[remarkMath]}
              rehypePlugins={[rehypeKatex]}
            >
              {questionText}
            </ReactMarkdown>
          </CardContent>
        </Card>
        {sortedOptions.map(([optionKey, optionValue]) => (
          <Card
            key={optionKey}
            onClick={completed ? null : () => { handleOptionChange(optionKey); }}
            style={{
              marginBottom: '1rem',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
              backgroundColor: completed
                ? (optionKey === question.studentAnswer
                  ? (question.studentAnswer === question.correctAnswer
                    ? 'rgba(0, 255, 0, 0.1)'
                    : 'rgba(255, 0, 0, 0.1)')
                  : (optionKey === question.correctAnswer
                    ? 'rgba(135, 206, 250, 0.3)'
                    : '#fff'))
                : (selectedOption === optionKey
                  ? '#b7b7b7'
                  : '#fff'),
              border: selectedOption === optionKey ? "none" : "1px solid #ddd",
              borderRadius: '15px'
            }}
          >
            <CardActionArea disabled={completed}>
              <CardContent>
                <Typography variant="body1" component="div">
                  <span>{optionKey.toUpperCase()}) </span>
                  <ReactMarkdown
                    remarkPlugins={[remarkMath]}
                    rehypePlugins={[rehypeKatex]}
                    components={{
                      p: ({ node, ...props }) => <span {...props} />
                    }}
                  >
                    {optionValue}
                  </ReactMarkdown>
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        ))}
        {completed && question.explanation && (
          <Card
            style={{
              marginTop: '1rem',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
              borderRadius: '15px',
            }}
          >
            <CardContent>
              <div className={styles['question-header']} variant="h6" component="h2">
                Explanation
              </div>
              <div className={styles['markdown-content']}>
                <ReactMarkdown
                  remarkPlugins={[remarkMath]}
                  rehypePlugins={[rehypeKatex]}
                >
                  {question.explanation}
                </ReactMarkdown>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    );
  }
}

export default QuestionItem;
