import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import styles from './AssignmentsPage.module.css';
import { fetchGradeableOfType } from '../../../services/gradingService';
import { authInstance, firestoreInstance } from '../../../services/firebase';
import AssignmentDetails from './AssignmentDetails';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { onSnapshot, collection, query, where } from 'firebase/firestore';
import gradingAssignmentImage from '../../ui/assets/gradingAssignment.gif'

function AssignmentsPage() {
  //console.log(useParams());
  const encodedCourse = useParams();
  const encodedCourseName = encodedCourse.courseName;
  const selectedCourseName = decodeURIComponent(encodedCourseName);
  const userID = authInstance.currentUser.uid;

  const [assignments, setAssignments] = useState([]);
  const [selectedAssignment, setSelectedAssignment] = useState(null);
  const [tab, setTab] = useState('current');  // Default tab

  useEffect(() => {
    const getAssignments = async () => {
      const fetchedAssignments = await fetchGradeableOfType(userID, selectedCourseName, "Assignments");
      setAssignments(fetchedAssignments);
      //console.log("Assignments ", assignments);
      //console.log("Course: ", selectedCourseName);
      //console.log("UserID", userID);
    };

    getAssignments();
  }, [userID, selectedCourseName]);

  const handleAssignmentClick = (assignment) => {
    setSelectedAssignment(assignment);
  };

  useEffect(() => {
    const assignmentsRef = collection(firestoreInstance, `users/${userID}/Assignments`);
    const courseQuery = query(assignmentsRef, where('course', '==', selectedCourseName));

    // Set up snapshot listener
    const unsubscribe = onSnapshot(courseQuery, (snapshot) => {
      const updatedAssignments = snapshot.docs.map((doc) => ({
        ...doc.data(),
        _id: doc.id,
        context: doc.data().context || ''
      }));
      setAssignments(updatedAssignments);
    });

    // Clean up subscription on unmount
    return () => unsubscribe();
  }, [userID, selectedCourseName]);

  const getDueDateColor = (dueDate) => {
    const due = new Date(dueDate.toDate());
    const today = new Date();

    // Calculate the difference in days
    const diffTime = Math.abs(due - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Return color based on the difference
    if (diffDays <= 2) {
      return "red";
    } else if (diffDays <= 5) {
      return "orange";
    } else {
      return "green";
    }
  };

  function formatISODate(dueDate) {
    const dateObj = new Date(dueDate.toDate());
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    const formattedMonth = monthNames[dateObj.getMonth()];
    const formattedDay = String(dateObj.getDate()).padStart(2, '0');

    return `${formattedMonth} ${formattedDay}`;
  }

  const currentAssignments = assignments.filter(assignment => (assignment.status === "Creating" || assignment.status === "In Progress"));
  const pastAssignments = assignments.filter(assignment => (assignment.status === "Submitted" || assignment.status === "Graded"));


  return (
    <div className={styles['assignments-selection']}>
      {selectedCourseName && (
        <>
          {!selectedAssignment ? (
            <>
              <div className={styles['assignment-header']}>
                <h3 className={styles['title']}>
                  {selectedCourseName} Assignments
                </h3>
                <div className={styles['tab-buttons']}>
                  <button
                    className={`${styles['tab-button']} ${tab === 'current' ? styles['active'] : ''}`}
                    onClick={() => setTab('current')}
                  >
                    Current
                  </button>
                  <button
                    className={`${styles['tab-button']} ${tab === 'past' ? styles['active'] : ''}`}
                    onClick={() => setTab('past')}
                  >
                    Past
                  </button>
                </div>
              </div>

              {tab === 'current' ? (
                <>
                  <div className={styles['assignment-list']}>
                    {currentAssignments.length === 0 ? (
                      <div className={styles['empty-assignments']}>
                        <h3>No assignments to show</h3>
                        {/* Add an image or other elements here if you want */}
                      </div>
                    ) : (currentAssignments.map((assignment) => (
                      <div
                        key={assignment._id}
                        className={styles['assignment-card']}
                        onClick={assignment.status === "Creating" || assignment.status === "Submitted" ? null : () => handleAssignmentClick(assignment)}
                        style={assignment.status === "Creating" || assignment.status === "Submitted" ? { pointerEvents: 'none', opacity: '0.6' } : {}}
                      >
                        <div className={styles['due-date']}>
                          <div>DUE</div>
                          <div style={{ color: getDueDateColor(assignment.dueDate) }}>
                            {formatISODate(assignment.dueDate)}
                          </div>
                        </div>
                        <div className={styles['card']}>
                          {assignment.status === "Creating" && <img src={gradingAssignmentImage} />}
                          <h4>{assignment.status === "Creating" ? "Creating" : assignment.title}</h4>
                        </div>
                        <div className={styles['chevron']}>
                          <FontAwesomeIcon icon={faChevronRight} color="blue" />
                        </div>
                      </div>
                    )))}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles['assignment-list']}>
                    {
                      pastAssignments.length === 0 ? (
                        <div className={styles['empty-assignments']}>
                          <h3>No past assignments to show</h3>
                          {/* Add an image or other elements here if you want */}
                        </div>
                      ) : (pastAssignments
                        .sort((a, b) => b.dueDate.toDate() - a.dueDate.toDate())
                        .map((assignment) => (
                          <div
                            key={assignment._id}
                            className={styles['assignment-card']}
                            onClick={assignment.status === "Submitted" ? null : () => handleAssignmentClick(assignment)}
                            style={assignment.status === "Submitted" ? { pointerEvents: 'none', opacity: '0.6' } : {}}
                          >
                            <div className={styles['due-date']}>
                              <div>DUE</div>
                              <div style={{ color: getDueDateColor(assignment.dueDate) }}>
                                {formatISODate(assignment.dueDate)}
                              </div>
                            </div>
                            <div className={styles['card']}>
                              {assignment.status === "Submitted" && <img src={gradingAssignmentImage} />}
                              <h4>{assignment.status === "Submitted" ? "Grading" : assignment.title}</h4>
                            </div>
                            <div className={styles['chevron']}>
                              <FontAwesomeIcon icon={faChevronRight} color="blue" />
                            </div>
                          </div>
                        )))}
                  </div>
                </>
              )}
            </>
          ) : (
            <AssignmentDetails assignment={selectedAssignment} />
          )}
        </>
      )}
    </div>
  );
}
export default AssignmentsPage;
