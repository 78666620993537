import React from 'react';
import CoursePage from '../../student/CoursePage';
import { useLocation } from 'react-router-dom';

function CoursePageRoute() {
  const location = useLocation();
  const { courseName, developerMode } = location.state || {};

  if (!courseName) {
    return <p>Invalid course selection. Please go back and select a course.</p>;
  }

  return (
    <CoursePage
      courseType={courseName}
      developerMode={developerMode}
    />
  );
}

export default CoursePageRoute;