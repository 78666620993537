import React from "react";
import styles from "./ProgressBarWithMilestones.module.css";

const ProgressBarWithMilestones = ({ checkpoints, progress, id, onCheckpointClick }) => {
  const reversedCheckpoints = checkpoints.slice().reverse();

  const handleClick = (event, id, checkpoint) => {
    event.preventDefault();
    if (onCheckpointClick) {
      onCheckpointClick(id, checkpoint);
    }
  };  

  return (
    <div className={styles["progress-bar-container"]}>
      <div
        className={styles["progress-bar"]}
        style={{
          height: progress === 0
            ? "2%"
            : `${((progress - 1) / (checkpoints.length - 1)) * 100}%`,
        }}
      />
      <div className={styles["milestones"]}>
        {reversedCheckpoints.map((checkpoint, index) => {
          const isCompleted = index >= checkpoints.length - progress;

          return (
            <div key={index} className={styles["milestone"]}>
              <div className={isCompleted ? styles["completed-checkpoint"] : styles["pending-checkpoint"]}>
                {isCompleted ? (
                    <a
                    href="#"
                    className={styles["checkpoint-text"]}
                    onClick={(event) => handleClick(event, id, checkpoint)}
                  >
                    {checkpoint.name}
                  </a>
                ) : (
                  <span className={styles["checkpoint-text"]}>
                    {checkpoint.name}
                  </span>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBarWithMilestones;
