import React, { useState, useEffect, useMemo } from 'react';
import LessonList from './LessonList';
import LessonInformation from './LessonInformation';
import { saveLesson, fetchUserLessons, fetchLessonById, getCurrentSubtopicIndex, updateSubtopicScore, getTotalProgressScore } from '../../services/lessonService';
import { authInstance, firestoreInstance } from '../../services/firebase';
import { collection, doc, onSnapshot } from 'firebase/firestore';
import ChatBox from './ChatBox';
import ProgressBarWithMilestones from './ProgressBarWithMilestones';
import styles from './CoursePage.module.css';
import AiCore from '../../aicore/AiCore'; // Make sure to provide the correct path to AiCore.js
import EvaluationEngine from '../../aicore/EvaluationEngine/EvaluationEngine';
import { createAssignment } from '../../aicore/CurriculumPlanner/AssignmentGenerator';
import { createQuiz } from '../../aicore/CurriculumPlanner/QuizGenerator';
import { createExam } from '../../aicore/CurriculumPlanner/ExamGenerator';
import eventBus from '../shared/eventBus';
import gradingBar from '../ui/assets/streamingAnimation.gif'


const aiCore = new AiCore();

function CoursePage({ courseType, developerMode }) {
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [unsubscribeFromLesson, setUnsubscribeFromLesson] = useState(null);
  const [unsubscribeFromSubtopicsScoreChange, setUnsubscribeFromSubtopicsScoreChange] = useState(null);
  const [unsubscribeFromResponseGenerated, setUnsubscribeFromResponseGenerated] = useState(null);
  const [fetchedLessonContents, setFetchedLessonContents] = useState({});
  const [selectedCheckpoints, setSelectedCheckpoints] = useState({});
  const [lessonProgresses, setLessonProgresses] = useState({});
  const [temporaryMessage, setTemporaryMessage] = useState(null);
  const [showToast, setShowToast] = useState(null);
  const [lessonContent, setLessonContent] = useState(null);
  const memoizedLessons = useMemo(() => lessons, [lessons]);
  const [streaming, setStreaming] = useState(false);
  const [streamedContent, setStreamedContent] = useState("");
  const [isChatDisabled, setIsChatDisabled] = useState(false);
  

  useEffect(() => {
    const fetchLessons = async () => {
      const userId = authInstance.currentUser.uid;
      const userLessons = await fetchUserLessons(userId, courseType);
      setLessons(userLessons);
    };

    fetchLessons();

    // Add the event listener with inline function
    const handleLessonTopicCompleted = (data) => {
      //console.log('Received data:', data);
      //if (lessonContent) {
        //setLessonContent(lessonContent + "\n[Modal: [New Subtopic] You've reached a new subtopic, Crazy,]");
      //}
    };

    const handleLessonInfoUpdated = async (data) => {
      //console.log('NEW LESSON INFO SAVED:', data);
      const dataUserId = data.userId;
      const dataSubject = data.subject;
      const lessonObjectId = data.lessonId;
      const updatedLesson = (await fetchUserLessons(dataUserId, courseType)).find(
        (lesson) => lesson._id === lessonObjectId
      );
      const currentProgress = await getTotalProgressScore(dataUserId, courseType, lessonObjectId);
      setLessonProgresses({
        ...lessonProgresses,
        [lessonObjectId]: currentProgress
      });
      ////console.log("BRIGAGER: ", updatedLesson.content);
      setStreamedContent("");
      setLessonContent(updatedLesson.content);
      setSelectedLesson(updatedLesson);
      setStreaming(false);
    };

    // Add the event listener with inline function
    const handleQuizTime = (data) => {
      //console.log('Received data:', data);
      setIsChatDisabled(true);
    };

    const handleQuizCompletion = async (data) => {
      //console.log('Received data:', data);
      const userId = data.userID;
      const quizObject = data.quiz;
      const gradedQuestions = data.gradedQuestions;

      if (selectedLesson) {
        try {
          const introResponseStream = await aiCore.startQuizFeedback(userId, quizObject, gradedQuestions, selectedLesson?.title, courseType);
          const reader = introResponseStream.getReader();
          const decoder = new TextDecoder("utf-8");
  
          let result;
          while (!(result = await reader.read()).done) {
            const chunk = decoder.decode(result.value || new Uint8Array, { stream: !result.done });
            handleResponseGenerated(selectedLesson?._id, chunk, true); // Pass `true` for the `streaming` parameter
          }
          handleResponseGenerated(selectedLesson?._id, '', false); // After all chunks have been processed
          // Reset streamed content for next stream
          //setStreamedContent("");
        } catch (error) {
          console.error('Error generating text:', error);
        }
      }
      setIsChatDisabled(false);
    };
    
    // Add the event listener
    eventBus.on('lessonTopicCompleted', handleLessonTopicCompleted);
    eventBus.on('lessonInfoUpdated', handleLessonInfoUpdated);
    eventBus.on('quizTime', handleQuizTime);
    eventBus.on('quizTimeDone', handleQuizCompletion);

    // Clean up the subscription when the component is unmounted
    return () => {
      if (unsubscribeFromLesson) {
        unsubscribeFromLesson();
      }
      if (unsubscribeFromSubtopicsScoreChange) {
        unsubscribeFromSubtopicsScoreChange();
      }
      eventBus.off('lessonTopicCompleted', handleLessonTopicCompleted);
      eventBus.off('lessonInfoUpdated', handleLessonInfoUpdated);
      eventBus.off('quizTime', handleQuizTime);
      eventBus.off('quizTimeDone', handleQuizCompletion);
      /*if (unsubscribeFromResponseGenerated) {
        unsubscribeFromResponseGenerated();
      }*/
    };
  }, [courseType, lessonProgresses, streaming]);
   ////////////////////////////////////////////////////////////////
   const handleModalButtonClick = () => {
    //console.log('Button clicked!');
    setLessonContent(lessonContent + "\n[Modal: [QUIZ] 2. Expressing Preferences and Allergies | Let's see how much you know!]");
  };
  ////////////////////////////////////////////////////////////////
  const handleAssignmentButtonClick = () => {
    //console.log('Button clicked!');
    const userId = authInstance.currentUser.uid;
    const subject = courseType;

    createAssignment(userId, subject, selectedLesson.title, selectedLesson._id);
  };
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  const handleQuizButtonClick = () => {
    //console.log('Button clicked!');
    const userId = authInstance.currentUser.uid;
    const subject = courseType;

    createQuiz(userId, subject, selectedLesson._id, selectedLesson.title);
  };
  ////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////
  const handleExamButtonClick = () => {
    //console.log('Button clicked!');
    const userId = authInstance.currentUser.uid;
    const subject = courseType;

    createExam(userId, subject);
  };
  ////////////////////////////////////////////////////////////////

  const handleCheckpointClick = (id, checkpoint) => {
    //console.log("CHECKPOINT FOR: " + id, checkpoint);
    // Update the state variable with the selected checkpoint data for the lesson
    setSelectedCheckpoints(prevCheckpoints => ({
      ...prevCheckpoints,
      [id]: null
    }));
  
    setTimeout(() => {
      setSelectedCheckpoints(prevCheckpoints => ({
        ...prevCheckpoints,
        [id]: checkpoint
      }));
    }, 0);
  };
  
  
  const handleLessonClick = async (lesson) => {

    // Unsubscribe from the previous lesson's updates (if any)
    if (unsubscribeFromLesson) {
      unsubscribeFromLesson();
    }
    if (unsubscribeFromSubtopicsScoreChange) {
      unsubscribeFromSubtopicsScoreChange();
    }
    setLessonContent(null);
    setSelectedLesson(lesson);
    const userId = authInstance.currentUser.uid;

    const currentProgress = await getTotalProgressScore(userId, courseType, lesson._id);
    //console.log("handleResponseGenerated() Current progress: ", currentProgress);
    setLessonProgresses({
      ...lessonProgresses,
      [lesson._id]: currentProgress
    });

    //console.log("Creating eval engine ", userId, courseType, lesson._id, lesson.title);
    const evalEngine = new EvaluationEngine(userId, courseType, lesson._id, lesson.title, aiCore, async (uid, subj, lessonId, responseText) => {
      //console.log("Callback MAN ", lessonId, responseText);
      if (responseText === "<T>:") {
        //This is to prevent the teacher from saying nothing in the callback
        //This would happen if the teacher replies for the student
        //const currentSubtopicIndex = await getCurrentSubtopicIndex(uid, subj, lessonId);
        //await updateSubtopicScore(uid, subj, lessonId, currentSubtopicIndex, 10);
        return;
      }
      // Get the current content of the selected lesson
      const updatedLesson = await fetchLessonById(uid, subj, lessonId);
      const currentContent = updatedLesson.content;
      const separator = '\n';
      const updatedContent = currentContent + separator + responseText;
      const userId = authInstance.currentUser.uid;
      await saveLesson(userId, courseType, {
        lessonId: updatedLesson._id,
        content: updatedContent,
        title: updatedLesson.title,
        description: updatedLesson.description
      });

      // Fetch the updated lesson and set it as selectedLesson
      /*const updatedLesson = (await fetchUserLessons(userId, courseType)).find(
        (lesson) => lesson._id === selectedLesson._id
      );
      setSelectedLesson(updatedLesson);*/
    });
    // Provide a callback function as an argument
    const unsubscribe1 = evalEngine.subscribe(evalEngine.onSubtopicsScoreChange.bind(evalEngine));
    setUnsubscribeFromSubtopicsScoreChange(() => unsubscribe1);

    // Fetch the updated lesson and set it as selectedLesson
    const updatedLesson = (await fetchUserLessons(userId, courseType)).find(
      (lesson) => lesson._id === lesson._id
    );
    setSelectedLesson(updatedLesson);

    // Subscribe to the new lesson's updates
    const unsubscribe = subscribeToLessonUpdates(lesson._id);
    setUnsubscribeFromLesson(() => unsubscribe);
    //console.log("LESSON CONTENT ", lesson.content);
    // Check if the lesson content is empty
    if (!fetchedLessonContents[lesson._id] && (lesson.content === 'Loading...' || !lesson.content || lesson.content.trim() === '')) {
      // Generate the introduction to the lesson using aiCore.startLesson()
      //setShowToast("Creating Lesson");
      try {
        const introResponseStream = await aiCore.startLesson(userId, courseType, lesson._id, lesson.title);
        const reader = introResponseStream.getReader();
        const decoder = new TextDecoder("utf-8");

        let result;
        while (!(result = await reader.read()).done) {
          const chunk = decoder.decode(result.value || new Uint8Array, { stream: !result.done });
          handleResponseGenerated(selectedLesson?._id, chunk, true); // Pass `true` for the `streaming` parameter
        }
        handleResponseGenerated(selectedLesson?._id, '', false); // After all chunks have been processed
        // Reset streamed content for next stream
        //setStreamedContent("");
      } catch (error) {
        console.error('Error generating text:', error);
      }
      // Append the introToLesson to the lesson content
      /*await saveLesson(userId, courseType, {
        lessonId: lesson._id,
        content: introToLesson,
        title: lesson.title,
        description: lesson.description,
        context: introToLesson
      });*/
      //setShowToast(null);
      // Set fetchedLessonContents to true for the selected lesson to prevent the startLesson from being called again
      setFetchedLessonContents({ ...fetchedLessonContents, [lesson._id]: true });
    }
  };

  const subscribeToLessonUpdates = (lessonId) => {
    if (!lessonId) {
      return;
    }

    const userId = authInstance.currentUser.uid;
    const lessonRef = doc(firestoreInstance, 'users', userId, courseType, lessonId);

    const unsubscribe = onSnapshot(lessonRef, async (doc) => {
      if (doc.exists()) {
        //console.log("Fetched lesson data OnSnapshot: ", doc.data());

        //Updating lesson progress
        /*const lessonData = doc.data();
        const subtopics = lessonData.subtopics;
        const totalScore = subtopics.reduce((sum, subtopic) => {
          return sum + subtopic.score;
        }, 0);
        const currentProgress = (totalScore + 130) / 100; 
        setLessonProgresses({
          ...lessonProgresses,
          [lessonId]: currentProgress
        });*/

        let newContentData = doc.data().content; 
        setLessonContent(newContentData);

        setSelectedLesson({ ...doc.data(), _id: doc.id });
        //setLessonContent(newContentData);
      }
    });

    return unsubscribe;
  };

  const handleResponseGenerated = async (lessonId, responseText, streaming) => {
    // Update the streamed content
    setStreaming(true);
    setStreamedContent((prev) => prev + responseText);

    if (selectedLesson) {
      ////console.log("handleResponseGenerated() ", responseText);
      // Get the current content of the selected lesson
      let currentContent = selectedLesson.content;
      if (currentContent === 'Loading...') {
        currentContent = '';
      }
      // Append the responseText to the current content with a separator
      const separator = '\n';
      const updatedContent = currentContent + separator + streamedContent;

      // If streaming is false, save the lesson and update the progress score
      if (!streaming) {
        //setStreaming(false);
        //console.log("STREAM DONE SAVING CONTENT", updatedContent);

        // Save the lesson for the current user (currently in AiCore)
        const userId = authInstance.currentUser.uid;
        /*setTimeout(async () => {
          await saveLesson(userId, courseType, {
            lessonId: selectedLesson._id,
            content: updatedContent,
            title: selectedLesson.title,
            description: selectedLesson.description
          });
        },5000);*/

        //Update progress bar (NOTE: courseType and subject are the same, will probably refactor later)
        const currentProgress = await getTotalProgressScore(userId, courseType, lessonId);
        //console.log("handleResponseGenerated() Current progress: ", currentProgress);
        setLessonProgresses({
          ...lessonProgresses,
          [lessonId]: currentProgress
        });
        // Reset streamed content for next stream
        //setStreamedContent("");
      }
    }
  };

  ////console.log("Selected lesson: ", selectedLesson);
  ////console.log("Lesson content: ", lessonContent);
  return (
    <div className={styles['course-page']}>
      <h1>{courseType}</h1>
      <div className={styles['content']}>
        <LessonList onLessonClick={handleLessonClick} lessons={lessons}/>
        <div className={styles['lesson-chat-container']}>
          <div className={styles['lesson-info-container']}>
            <LessonInformation
              lesson={selectedLesson}
              content={streaming ? lessonContent + '\n' + streamedContent : lessonContent}
              userId={authInstance.currentUser.uid}
              subject={courseType}
              lessonId={selectedLesson?._id}
              lessonTopic={selectedLesson?.title}
              showToast={showToast}
              setShowToast={setShowToast}
              selectedCheckpoint={selectedCheckpoints[selectedLesson?._id]}
            />
          </div>
          <ChatBox
            onResponseGenerated={(responseText, streaming) =>
              handleResponseGenerated(selectedLesson?._id, responseText, streaming)
            }
            userId={authInstance.currentUser.uid}
            subject={courseType}
            lessonId={selectedLesson?._id}
            lessonTopic={selectedLesson?.title}
            isDisabled={isChatDisabled}
          />
        </div>
        <div>
          {selectedLesson && (
            <ProgressBarWithMilestones
              checkpoints={selectedLesson.subtopics}
              progress={lessonProgresses[selectedLesson._id] || 0}
              id={selectedLesson._id}
              onCheckpointClick={handleCheckpointClick}
            />
          )}
        </div>
      </div>
      {developerMode && (
        <>
          <button onClick={handleAssignmentButtonClick}>
            Assignment
          </button>
          <button onClick={handleQuizButtonClick}>
            Quiz
          </button>
          <button onClick={handleExamButtonClick}>
            Exam
          </button>
          <button onClick={handleModalButtonClick}>
            Modal
          </button>
        </>
      )}
    </div>
  );
}

export default CoursePage;