import React, { useState, useEffect } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import Form from './common/Form';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { loginUser, createUser } from '../../../services/userService';

function LoginPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleAction = (id) => {
    const authentication = getAuth(); //Connects to firebase

    if (id === 1) {
      loginUser(authentication, email, password);
    }
    if (id === 2) {
      createUser(name, email, password, "student");
    }
  }

  return (
    <Router>
      <div className="App">
        <a href="https://edu-ai.io/"
          style={{
            position: 'absolute',
            top: '10px',
            right: '1em',
            textDecoration: 'none',
            color: '#fff',
            fontSize: '1.3rem', // Adjust as needed
            fontWeight: 'bold', // Makes the text bold
          }}>
          Home
        </a>
        <Routes>
          <Route
            path='/login'
            element={
              <Form
                title="Login"
                setEmail={setEmail}
                setPassword={setPassword}
                handleAction={() => handleAction(1)}
              />}
          />
          <Route
            path=''
            element={
              <Form
                title="Login"
                setEmail={setEmail}
                setPassword={setPassword}
                handleAction={() => handleAction(1)}
              />}
          />
        </Routes>
      </div>
    </Router>
  );

}

export default LoginPage;