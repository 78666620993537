import axios from 'axios';
//Can have gpt 4 generate responses and we can parse the responses to build ui, or maybe write to db first
//The db can have prompts recorded onto it for gpt4 to leverage upon every new session. Use plugins to generate diagrams, pictures, etc
//Once the material is generated, it will want to be saved for review

// Replace the following line with the URL of your backend server
const BACKEND_SERVER_URL = process.env.REACT_APP_BACKEND_SERVER_URL || 'https://api1.edu-ai.io';

export const generateText = async (messages, model = "gpt-4", temperature = 0.7) => {
  try {
    const response = await axios.post(`${BACKEND_SERVER_URL}/api/generate-text`, {
      messages: messages,
      model: model,
      temperature: temperature,
    });
    //console.log("AI RESPONSE: ", response.data.text);
    return response.data.text;
  } catch (error) {
    console.error('Error generating text:', error);
    return '';
  }
};

export const generateStream = async (messages, model = "gpt-4-0613", temperature = 0.6) => {
  try {
    const response = await fetch(`${BACKEND_SERVER_URL}/api/generate-stream`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        messages: messages,
        model: model,
        temperature: temperature,
      })
    });

    if (!response.ok) {
      throw new Error('Server returned an error: ' + response.statusText);
    }

    if (!response.body) {
      throw new Error('No response body');
    }

    // Return the readable stream directly
    return response.body;
  } catch (error) {
    console.error('Error generating text:', error);
    throw error;  // Propagate the error to the caller
  }
};

export const generateSummary = async (text, model = "gpt-4", temperature = 0.7) => {
    try {
      // Call the backend server's endpoint instead of OpenAI API directly
      const response = await axios.post(`${BACKEND_SERVER_URL}/api/generate-summary`, {
        text: text,
        model: model,
        temperature: temperature,
      });
      ////console.log("summary prompt: ", text);
      return response.data.summary;
    } catch (error) {
      console.error('Error generating summary:', error);
      return '';
    }
};