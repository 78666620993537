import { updateOrCreateContextObject } from '../../services/contextService';
import { fetchLessonById, saveLessonContext, saveLesson } from "../../services/lessonService";

class ContextStorage {
    constructor() {
    }

    parseToMessages(str) {
      const messages = [];
      const lines = str.split('\n');
      let currentRole = '';
      let currentContent = '';
    
      for (const line of lines) {
        if (line.startsWith('<T>:') || line.startsWith('<S>:')) {
          if (currentRole && currentContent) {
            messages.push({ role: currentRole, content: currentContent.trim() });
          }
          currentRole = line.startsWith('<T>:') ? 'assistant' : 'user';
          currentContent = line.slice(4); // This slices out the '<T>:' or '<S>:' part
        } else {
          currentContent += '\n' + line;
        }
      }
    
      if (currentRole && currentContent) {
        messages.push({ role: currentRole, content: currentContent.trim() });
      }
    
      return messages;
    }    

    async getRecentDialogueObjects(userId, subject, lessonId) {
      const currentLesson = await fetchLessonById(userId, subject, lessonId);
      ////console.log("ContextStorage getRecentDialogueObjects() before parse ", currentLesson.context);
      const recentDialogueObjects = this.parseToMessages(currentLesson.context);  //format to messageobjects
      //console.log("ContextStorage getRecentDialogueObjects() ", recentDialogueObjects);
      return recentDialogueObjects;
    }

    async getRecentDialogue(userId, subject, lessonId) {
      const currentLesson = await fetchLessonById(userId, subject, lessonId);
      const recentDialogue = currentLesson.context;  //format to messageobjects
      return recentDialogue;
    }

    //updates pinecone and firebase
    async addToPineContext(userId, latestStudentResp, latestTeacherResp, lessonTitle) {
      const interactions = [];
    
      if (latestStudentResp) {
        interactions.push({
          "role": "student",
          "content": latestStudentResp
        });
      }
    
      if (latestTeacherResp) {
        if (!latestTeacherResp.startsWith("<T>:")) {
          latestTeacherResp = "<T>:" + latestTeacherResp;
        }
        interactions.push({
          "role": "teacher",
          "content": latestTeacherResp
        });
      }
      //console.log("ContextStorage setNewContext() interactions: ", interactions);
      await updateOrCreateContextObject(userId, interactions, lessonTitle);
    }

    async setRecentDialogue(userId, subject, lessonId, latestStudentResp, latestTeacherResp) {
      let recentDialogue = await this.getRecentDialogue(userId, subject, lessonId) || '';
      ////console.log("PromptGenerator setRecentDialogue before stuff ", recentDialogue);
      if (latestStudentResp) {
        latestStudentResp = latestStudentResp.replace(/^<S>:\s*/, '').trim();
        if (latestStudentResp !== '') {
            if (!recentDialogue.endsWith("\n")) {
                recentDialogue += '\n';
            }
            recentDialogue += `<S>: ${latestStudentResp}\n`;
        }
      }
      if (latestTeacherResp) {
        latestTeacherResp = latestTeacherResp.replace(/^<T>:\s*/, '').trim();
        if (latestTeacherResp !== '') {
            if (!recentDialogue.endsWith("\n")) {
                recentDialogue += '\n';
            }
            recentDialogue += `<T>: ${latestTeacherResp}\n`;
        }
      }
  
      if (recentDialogue.endsWith("\n")) {
          recentDialogue = recentDialogue.slice(0, -1);
      }
      
      const maxTokenCount = 600;
      // Check if the dialogue exceeds the maximum token count allowed
      while (maxTokenCount && recentDialogue.split(' ').length > maxTokenCount) {
          // Remove the first line along with its <T> or <S> tag and the newline character after it
          const firstNewlineIndex = recentDialogue.indexOf('\n');
          if (firstNewlineIndex !== -1) {
              const nextTagIndex = recentDialogue.slice(firstNewlineIndex + 1).search(/<[TS]>:/);
              if (nextTagIndex !== -1) {
                  recentDialogue = recentDialogue.slice(firstNewlineIndex + 1 + nextTagIndex);
              } else {
                  break; // No more lines to remove
              }
          } else {
              break; // No more lines to remove
          }
      }
  
      //console.log("RecentDialogue ", recentDialogue);
      await saveLessonContext(userId, subject, lessonId, recentDialogue);
    }

    async setContent(userId, subject, lessonId, latestStudentResp, latestTeacherResp) {
      const curLesson = await fetchLessonById(userId, subject, lessonId);
      let content = curLesson.content || '';
      if (content === 'Loading...') {
        content = '';
      }
      ////console.log("PromptGenerator setRecentDialogue before stuff ", recentDialogue);
      if (latestStudentResp) {
        latestStudentResp = latestStudentResp.replace(/^<S>:\s*/, '').trim();
        if (latestStudentResp !== '') {
            if (!content.endsWith("\n")) {
                content += '\n';
            }
            content += `<S>: ${latestStudentResp}\n`;
        }
      }
      if (latestTeacherResp) {
        latestTeacherResp = latestTeacherResp.replace(/^<T>:\s*/, '').trim();
        if (latestTeacherResp !== '') {
            if (!content.endsWith("\n")) {
              content += '\n';
            }
            content += `<T>: ${latestTeacherResp}\n`;
        }
      }
  
      if (content.endsWith("\n")) {
        content = content.slice(0, -1);
      }
  
      ////console.log("Content ", content);
      await saveLesson(userId, subject, {
        lessonId: curLesson._id,
        content: content,
        title: curLesson.title,
        description: curLesson.description
      });
    }



    /*conversationToContextObjects(conversation, topic) {
        // Split the conversation into individual messages
        const messages = conversation.split(/\n\s*\n/);
      
        // Initialize an array to store the interactions
        const interactions = [];
      
        // Iterate through the messages and format them as interaction objects
        messages.forEach((message) => {
          // Check if the message is from the student or the teacher
          const isStudentMessage = message.startsWith("<S>:");
          const isTeacherMessage = message.startsWith("<T>:");
      
          // Remove the "<S>:" or "<T>:" prefix from the message (remove the last // if you uncomment)
          const strippedMessage = message.replace(/^(<S>:|<T>:)\s*///, "");
      
          // Add the message to the interactions array as an interaction object
          /*if (isStudentMessage) {
            interactions.push({ role: "student", content: strippedMessage });
          } else if (isTeacherMessage) {
            interactions.push({ role: "teacher", content: strippedMessage });
          }
        });
      
        // Create the context object
        const contextObject = {
          id: generateId(), // Replace this with a function that generates a unique ID
          topic: topic,
          lastInteractionTimestamp: Date.now(),
          interactions: interactions,
        };
      
        return contextObject;
    }*/
}

export default ContextStorage;