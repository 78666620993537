import React, { useState, useEffect, useContext } from 'react';
import UserContext from './UserContext';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { getSubscriptionStatus, updateSubscriptionStatus } from '../../../services/lessonService';
import './Billing.css'; 
import styles from '../../student/AiEdPlatform.module.css';
const CLIENT_SERVER_URL = process.env.CLIENT_SERVER_URL || 'http://localhost:3000';
const BACKEND_SERVER_URL = process.env.BACKEND_SERVER_URL || 'http://localhost:5000';

// Load Stripe.js
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
//console.log("Yeah? ", stripePromise);

function CheckoutForm({ clientSecret }) {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [success, setSuccess] = useState(false);
  const [localClientSecret, setLocalClientSecret] = useState(clientSecret);
  const stripe = useStripe();
  const elements = useElements();


  useEffect(() => {
    setLocalClientSecret(clientSecret);
  }, [clientSecret]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setProcessing(true);
    const userEmail = "nickOlas@gongs.com";

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        client_secret: localClientSecret,
        return_url: `${CLIENT_SERVER_URL}/student-platform`,
        payment_method: {
          card: elements.getElement(PaymentElement),
          billing_details: {
            email: userEmail,
          },
        },
      },
    });

    if (result.error) {
      setError(result.error.message);
      setProcessing(false);
    } else {
      // Handle successful payment
      setProcessing(false);
      setSuccess(true);
    }
  };

  if (success) {
    return <div>Subscription successful!</div>;
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="stripe-card-element-container">
        <PaymentElement />
      </div>
      {error && <div>{error}</div>}
      <button type="submit" disabled={!stripe || processing}>
        {processing ? 'Processing...' : 'Subscribe'}
      </button>
    </form>
  );
}

function Billing() {
  const [clientSecret, setClientSecret] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const userObject = useContext(UserContext);
  const userId = userObject.userId;
  //console.log("Billing() ", userId);

  useEffect(() => {
    async function fetchClientSecretAndSubscriptionStatus() {
      const response = await fetch(`${BACKEND_SERVER_URL}/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: 1000 }), // Replace with the desired amount
      });
      const data = await response.json();
      setClientSecret(data.clientSecret);

      // Fetch subscription status
      const subscriptionStatus = await getSubscriptionStatus(userId);
      setIsSubscribed(subscriptionStatus);
    }

    fetchClientSecretAndSubscriptionStatus();
  }, []);

  if (!clientSecret) {
    return <div>Loading...</div>;
  }

  return (
    <div className="course-selection">
      <div className="billing-content">
        <h3>Billing</h3>
        {isSubscribed ? (
          <div>You are currently subscribed!</div>
        ) : (
          <div className="billing-form-container">
            {clientSecret && (
              <Elements stripe={stripePromise} options={{ clientSecret: clientSecret }}>
                <CheckoutForm clientSecret={clientSecret} onPaymentSuccess={async () => {
                  setIsSubscribed(true);
                  await updateSubscriptionStatus(userId, true);
                }} />
              </Elements>
            )}
          </div>
        )}
      </div>
    </div>
  );  
}

export default Billing;