import { firestoreInstance, storage } from './firebase.js'; // Replace with actual path
import { doc, getDoc } from 'firebase/firestore'
import { getStorage, ref, getDownloadURL } from "firebase/storage";

// imageSearch.js
const apiKey = 'AIzaSyCQqje2ANK9EBolyl7N8US1HCBWJn3j8kc';
const cx = 'c4898026324e14716';

//GOOGLE CUSTOM SEARCH IMAGES
export async function searchImage(query) {
  const url = new URL('https://www.googleapis.com/customsearch/v1/siterestrict?');
  const params = {
    q: query,
    searchType: 'image',
    num: 1,
    start: 1,
    key: apiKey,
    cx: cx,
  };

  Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]));

  try {
    const response = await fetch(url);
    const data = await response.json();
    //console.log("image search results: ", data);
    if (data.items && data.items.length > 0) {
      //console.log("image url", data.items[0].image.thumbnailLink);
      //return data.items[0].image.thumbnailLink;

      const link = data.items[0].link;
      const thumbnailLink = data.items[0].image.thumbnailLink;

      // If the link is an x-raw link, return the thumbnail link, otherwise return the full link
      return link.startsWith("x-raw-image://") ? thumbnailLink : link;
    } else {
      return null;
    }
  } catch (error) {
    console.error('Error fetching image:', error);
    return null;
  }
}

/*export const fetchImageFromFirebaseStorage = async (docId) => {
  const docRef = doc(firestoreInstance, 'diagrams', docId);
  const docSnap = await getDoc(docRef);

  if (!docSnap.exists()) {
    //console.log(Error(`No document found with id: ${docId}`));
    return null;
  } else {
    return docSnap.data().url;
  }
};*/

export const fetchImageFromFirebaseStorage = async (docId) => {
  // Get a reference to the storage service, which is used to create references in your storage bucket
  //const storage = getStorage();

  // Create a storage reference from our storage service
  const imageRef = ref(storage, `diagrams/${docId}.png`);

  try {
      // Get the download URL from the reference
      const url = await getDownloadURL(imageRef);
      //console.log("URL OF IMAGE: ", url);
      return url;
  } catch (error) {
      console.error(`Failed to fetch image URL: ${error}`);
      return null;
  }
};


//UNSPLASH IMAGES
export const fetchImage = async (query) => {
  const accessKey = 'VQVHX2LpdjAkX-m5UjEIhg-0On5pMQxyf9RMBTNXBLY';
  const response = await fetch(`https://api.unsplash.com/search/photos?query=${query}&client_id=${accessKey}`);

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  const data = await response.json();
  //URL of the first image
  const imageUrl = data.results[0].urls.small; // or .full for high-resolution image
  return imageUrl;
}