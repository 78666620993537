import React, { useState } from 'react';
import styles from './ChatBox.module.css';
import AiCore from '../../aicore/AiCore'; // Make sure to provide the correct path to AiCore.js
import { TextareaAutosize } from '@mui/base';
import gradingBar from '../ui/assets/streamingAnimation.gif'

const aiCore = new AiCore();
//let evalEngine = null;

function ChatBox({ onResponseGenerated, userId, subject, lessonId, lessonTopic, isDisabled }) {
  const [message, setMessage] = useState('');
  const [streaming, setStreaming] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (e, msg) => {
    if (e) e.preventDefault();
    const studentInput = '<S>:' + (msg || message) + " (P.S don't forget to follow the lesson plan and speaking_style! Remember to format math or equations in KaTeX enclosed in dollar signs. But let me ask/answer questions if I have any.)";

    setMessage('');
    setErrorMessage(null);
    setStreaming(true);

    onResponseGenerated('\n' + studentInput + '\n', true);

    try {
      const responseStream = await aiCore.getTeacherResponse(userId, studentInput, subject, lessonId, lessonTopic);
      const reader = responseStream.getReader();
      const decoder = new TextDecoder("utf-8");

      let result;
      while (!(result = await reader.read()).done) {
        const chunk = decoder.decode(result.value || new Uint8Array, { stream: !result.done });
        onResponseGenerated(chunk, true);
      }
      onResponseGenerated('', false);

    } catch (error) {
      console.error('Error generating text:', error);
      setErrorMessage('Error generating text. Please try again.');
    } finally {
      setTimeout(() => {
        setStreaming(false);
      }, 8000);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    handleSubmit(null, "/" + suggestion);
  };

  return (
    <div className={`${styles['chat-box']} ${isDisabled ? styles['disabled'] : ''}`}>
      <div className={styles['suggestion-container']}>
        {!streaming && lessonId && !isDisabled && !errorMessage && (
          <>
            <button
              className={styles['suggestion-button']}
              onClick={() => handleSuggestionClick('continue')}
            >
              Continue
            </button>
            <button
              className={styles['suggestion-button']}
              onClick={() => handleSuggestionClick('practice')}
            >
              Practice
            </button>
          </>
        )}
        {errorMessage && ( // <-- Conditionally render the error message
          <div className={styles['error-message']}>
            {errorMessage}
          </div>
        )}
      </div>
      <form onSubmit={handleSubmit}>
        <TextareaAutosize
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit(e);
            }
          }}
          placeholder="Type your message..."
          disabled={isDisabled}
        />
        {streaming ? (
          <div style={{ position: 'relative', width: '7em', height: '2em' }}>
            <img
              src={gradingBar}
              alt="loading"
              style={{ position: 'absolute', maxWidth: '65%', top: '-0.70rem', right: '0.8rem'}}
            />
          </div>
        ) : (
          <button type="submit" disabled={streaming || isDisabled}>Send</button>
        )}
      </form>
    </div>
  );
}

export default ChatBox;